/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

#posts {
	position: relative;
}

.entry {
	position: relative;
	margin: 0 0 50px;
	padding: 0 0 50px;
	border-bottom: 2px solid #F5F5F5;
}

.entry-image {
	margin-bottom: 30px;
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	> a, .slide a, img {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
	}
	iframe {
		display: block;
	}
	img {
		border-radius: 3px;
	}
}

.entry-title h2 {
	margin: 0;
	font-size: 24px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	a {
		color: #333;
		&:hover {
			color: #00deff;
		}
	}
}

.entry-meta {
	margin: 10px -10px -15px 0;
	list-style: none;
}

.single-post .entry-meta {
	margin-bottom: 20px;
}

.entry-meta li {
	float: left;
	font-size: 13px;
	line-height: 14px;
	margin: 0 10px 15px 0;
	color: #999;
	font-family: 'Crete Round', serif;
	font-style: italic;
	&:before {
		content: '/';
		display: inline-block;
		margin-right: 10px;
		opacity: 0.5;
	}
	i {
		position: relative;
		top: 1px;
		font-size: 14px;
		margin-right: 3px;
	}
	a {
		color: #999;
		&:hover {
			color: #00deff;
		}
	}
	&:first-child:before {
		display: none;
	}
	ins {
		font-weight: 600;
		text-decoration: none;
	}
}

.entry-c {
	position: relative;
	overflow: hidden;
}

#posts .entry-content {
	margin-top: 30px;
}

.single-post .entry-content .entry-image {
	max-width: 350px;
}

.entry-content {
	position: relative;
}

.entry-link {
	display: block;
	background-color: #F5F5F5;
	padding: 30px 0;
	text-align: center;
	color: #444;
	font-family: $header_font;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 24px;
	font-weight: 700;
	border-radius: 3px;
}

body:not(.device-touch) .entry-link {
	-webkit-transition: background-color .3s ease-in-out;
	-o-transition: background-color .3s ease-in-out;
	transition: background-color .3s ease-in-out;
}

.entry-link {
	&:hover {
		color: #FFF;
		background-color: #00deff;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
	span {
		display: block;
		margin-top: 5px;
		font-family: 'Crete Round', serif;
		font-style: italic;
		font-weight: normal;
		text-transform: none;
		letter-spacing: 0;
		font-size: 14px;
		color: #AAA;
	}
	&:hover span {
		color: #EEE;
	}
}

.entry blockquote p {
	font-weight: 400;
	font-family: 'Crete Round', serif;
	font-style: italic;
}

/* Small Thumbs
-----------------------------------------------------------------*/

.small-thumbs .entry-image, .ievent .entry-image {
	float: left;
	width: 300px;
	margin: 0 30px 0 0;
}

.small-thumbs .entry-image img, .ievent .entry-image img {
	border-radius: 0;
}

.small-thumbs .entry-c .entry-image, .ievent .entry-c .entry-image {
	float: none;
	width: 100%;
	margin: 0 0 20px 0;
}

.small-thumbs .entry-c .entry-image img, .ievent .entry-c .entry-image img {
	border-radius: 5px;
}

.small-thumbs .entry-title h2, .ievent .entry-title h2 {
	font-size: 20px;
	font-weight: 600;
}

/* Small Thumbs - Right
-----------------------------------------------------------------*/

.small-thumbs.alt {
	.entry-image {
		float: right;
		margin: 0 0 0 30px;
	}
	.entry-c .entry-image {
		float: none;
		margin: 0 0 20px 0;
	}
}

/* Small Thumbs - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar .small-thumbs {
	.entry-image {
		width: 200px;
		margin: 0 25px 0 0;
	}
	&.alt .entry-image {
		margin: 0 0 0 25px;
	}
	.entry-c .entry-image {
		width: 100%;
		margin: 0 0 20px 0;
	}
	.entry-title h2 {
		font-size: 18px;
	}
}

/* Blog - Grid
-----------------------------------------------------------------*/

.post-grid {
	margin-right: -28px;
	.entry {
		float: left;
		width: 264px;
		margin-right: 28px;
	}
	.entry-image {
		height: 148px;
		img {
			height: 148px;
			border-radius: 0;
		}
	}
	.entry-title h2 {
		font-size: 17px;
		font-weight: 600;
	}
	.entry-link {
		font-size: 24px;
		span {
			font-size: 13px;
		}
	}
	&.grid-3 {
		margin-right: -30px;
		.entry {
			width: 360px;
			margin-right: 30px;
		}
		.entry-image {
			height: 202px;
			img {
				height: 202px;
			}
		}
		.entry-title h2 {
			font-size: 18px;
		}
	}
}

/* Blog - Grid - 3 Columns
-----------------------------------------------------------------*/

/* Blog - Grid - 3 Columns - Sidebar
-----------------------------------------------------------------*/

.postcontent .post-grid.grid-3 {
	margin-right: -25px;
	.entry {
		width: 270px;
		margin-right: 25px;
	}
	.entry-image {
		height: 152px;
		img {
			height: 152px;
		}
	}
	.entry-title h2 {
		font-size: 16px;
	}
}

/* Blog - Grid - 2 Columns
-----------------------------------------------------------------*/

.post-grid.grid-2 {
	margin-right: -40px;
	.entry {
		width: 550px;
		margin-right: 40px;
	}
	.entry-image {
		height: 309px;
		img {
			height: 309px;
		}
	}
	.entry-title h2 {
		font-size: 20px;
	}
}

/* Blog - Grid - 2 Columns - Sidebar
-----------------------------------------------------------------*/

.postcontent .post-grid.grid-2 {
	.entry {
		width: 410px;
	}
	.entry-image {
		height: 230px;
		img {
			height: 230px;
		}
	}
	.entry-title h2 {
		font-size: 18px;
	}
}

/* Blog - Grid - 2 Columns - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar .post-grid.grid-2 {
	.entry {
		width: 270px;
	}
	.entry-image {
		height: 152px;
		img {
			height: 152px;
		}
	}
	.entry-title h2 {
		font-size: 16px;
	}
}

/* Blog - Masonry
-----------------------------------------------------------------*/

.post-masonry .entry-image {
	height: auto !important;
	img {
		height: auto !important;
	}
}

/* Blog - Masonry Full
-----------------------------------------------------------------*/

.post-masonry-full {
	margin: -80px 0 -80px -1px !important;
	.entry {
		width: 24.9% !important;
		margin: 0 !important;
		padding: 40px 30px;
		border-bottom: 1px dashed;
		border-left: 1px dashed;
		border-color: #E5E5E5;
	}
	&.grid-3 .entry {
		width: 33.30% !important;
	}
}

/* Blog - Timeline
-----------------------------------------------------------------*/

.timeline-border {
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -71px;
	width: 0;
	border-left: 1px dashed #CCC;
	height: 100%;
}

.post-timeline {
	margin-right: -140px !important;
	.entry {
		width: 500px !important;
		margin-right: 140px !important;
	}
}

.entry.entry-date-section {
	width: 100% !important;
	margin: 50px -70px 80px;
	padding: 0;
	border: 0;
	text-align: center;
	span {
		display: inline-block;
		padding: 10px 15px;
		background-color: #FFF;
		border: 2px solid #EEE;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-family: $header_font;
	}
}

.post-timeline .entry-timeline {
	display: none;
	position: absolute;
	border: 2px solid #CCC;
	background-color: #FFF;
	padding-top: 0;
	text-indent: -9999px;
	top: 40px;
	left: auto;
	right: -76px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	font-size: 26px;
	font-weight: bold;
	color: #AAA;
	text-align: center;
	line-height: 1;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.entry.entry-date-section + .entry .entry-timeline {
	top: 70px;
}

.post-timeline {
	.entry-timeline div.timeline-divider {
		position: absolute;
		top: 4px;
		left: -58px;
		width: 50px;
		height: 0;
		border-top: 1px dashed #CCC;
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	.alt .entry-timeline {
		right: auto;
		left: -77px;
		div.timeline-divider {
			left: auto;
			right: -58px;
		}
	}
	.entry-timeline span {
		display: block;
		margin-top: 3px;
		font-size: 13px;
		font-weight: normal;
	}
	.entry:hover {
		.entry-timeline, .timeline-divider {
			border-color: #00deff;
			color: #00deff;
		}
	}
}

/* Blog - Timeline - Sidebar
-----------------------------------------------------------------*/

.postcontent {
	.timeline-border {
		left: 32px;
		margin-left: 0;
	}
	.post-timeline {
		padding-left: 100px;
		margin-right: 0 !important;
		.entry {
			width: 100% !important;
			margin-right: 140px !important;
		}
		.entry-timeline {
			display: block;
			border: 3px solid #CCC;
			background-color: #FFF;
			padding-top: 10px;
			text-indent: 0;
			top: 20px;
			left: -100px;
			right: auto;
			width: 64px;
			height: 64px;
			font-size: 24px;
			div.timeline-divider {
				top: 29px;
				left: 64px;
				width: 32px;
			}
		}
	}
}

/* Individual Post
-----------------------------------------------------------------*/

.ipost {
	.entry-image img {
		border-radius: 0;
	}
	.entry-title {
		h3 {
			margin: 0;
			font-size: 16px;
			font-weight: 600;
		}
		h4 {
			margin: 0;
			font-size: 16px;
			font-weight: 600;
			font-size: 15px;
		}
		h3 a, h4 a {
			color: #333;
		}
		h3 a:hover, h4 a:hover {
			color: #00deff;
		}
	}
	.entry-meta {
		margin-right: -10px;
		li {
			font-size: 13px;
			margin-right: 10px;
		}
	}
	.entry-content {
		margin-top: 20px;
		p {
			margin-bottom: 0;
		}
	}
}

.more-link {
	display: inline-block;
	border-bottom: 1px solid #00deff;
	padding: 0 2px;
	font-family: 'Crete Round', serif;
	font-style: italic;
	&:hover {
		border-bottom-color: #555;
	}
}

/* Related Posts
-----------------------------------------------------------------*/

.overlay-icon {
	position: absolute;
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;
	width: 48px;
	height: 48px;
	background-color: rgba(0, 0, 0, 0.2);
	text-align: center;
	i {
		line-height: 48px;
		font-size: 28px;
		color: #FFF;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
}

/* Small Post
-----------------------------------------------------------------*/

.spost {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px dashed #E5E5E5;
}

.mpost {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px dashed #E5E5E5;
	margin-top: 25px;
	padding-top: 25px;
}

.spost:first-child, .mpost:first-child {
	margin-top: 0;
	padding-top: 0;
	border-top: 0;
}

.spost .entry-image, .mpost .entry-image {
	float: left;
	margin: 0 15px 0 0;
	text-align: center;
}

.spost .entry-image {
	width: 48px;
	height: 48px;
	a, img, i {
		width: 48px;
		height: 48px;
	}
	a i {
		font-size: 28px;
		line-height: 48px;
		color: #666;
		background-color: #EEE;
	}
}

.mpost .entry-image a i {
	font-size: 28px;
	line-height: 48px;
	color: #666;
	background-color: #EEE;
}

.spost .entry-title h4, .mpost .entry-title h4 {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
}

.spost .entry-title h4 a, .mpost .entry-title h4 a {
	color: #333;
}

.spost .entry-title h4 a:hover, .mpost .entry-title h4 a:hover {
	color: #00deff;
}

.spost .entry-meta {
	margin: 8px -10px 0 0;
	li {
		font-size: 13px;
		margin: 0 10px 0 0;
	}
}

.mpost {
	.entry-meta li {
		font-size: 13px;
		margin: 0 10px 0 0;
	}
	.entry-image {
		margin-right: 20px;
		width: 170px;
		height: 128px;
		a, img, i {
			width: 170px;
			height: 128px;
		}
		a, img {
			border-radius: 2px;
		}
		a i {
			font-size: 42px;
			line-height: 128px;
		}
	}
	.entry-title h4 {
		margin: 0;
		font-size: 17px;
		font-weight: 600;
	}
	.entry-meta {
		margin-top: 5px;
		li i {
			margin-right: 2px;
		}
	}
	.entry-content {
		margin-top: 15px;
	}
}

/* Medium Post
-----------------------------------------------------------------*/

.post-navigation + .line {
	margin: 40px 0 50px;
}

/* ----------------------------------------------------------------
	Blog - Author
-----------------------------------------------------------------*/

.author-image {
	float: left;
	width: 84px;
	height: 84px;
	margin-right: 15px;
	img {
		width: 84px;
		height: 84px;
	}
}

/* ----------------------------------------------------------------
	Comments List
-----------------------------------------------------------------*/

#comments {
	position: relative;
	margin-top: 50px;
	padding-top: 50px;
	border-top: 1px solid #EEE;
}

.commentlist {
	list-style: none;
	padding-bottom: 50px;
	margin: 0 0 50px;
	border-bottom: 1px solid #EEE;
}

#reviews .commentlist {
	padding-bottom: 30px;
	margin: 0 0 20px;
}

.commentlist {
	ul {
		list-style: none;
	}
	li {
		margin: 30px 0 0 0;
		ul {
			margin: 30px 0 0 0;
			li {
				margin: 30px 0 0 0;
			}
		}
	}
	ul:first-child {
		margin-top: 0;
	}
	li {
		position: relative;
		margin: 30px 0 0 30px;
	}
}

#reviews .commentlist li {
	margin-top: 20px;
}

.comment-wrap {
	position: relative;
	border: 1px solid #E5E5E5;
	border-radius: 5px;
	padding: 20px 20px 20px 35px;
}

.commentlist ul .comment-wrap {
	margin-left: 25px;
	padding-left: 20px;
}

#reviews .comment-wrap {
	border: 0;
	padding: 10px 0 0 35px;
}

.commentlist > li:first-child, #reviews .commentlist > li:first-child {
	padding-top: 0;
	margin-top: 0;
}

.commentlist li {
	.children {
		margin-top: 0;
	}
	li .children {
		margin-left: 30px;
	}
	.comment-content {
		position: relative;
		overflow: hidden;
	}
}

.pingback {
	position: relative;
	overflow: hidden;
}

.commentlist li .comment-content p, .pingback p {
	margin: 20px 0 0 0;
}

.commentlist li {
	.comment-content {
		padding: 0 0 0 15px;
	}
	.comment-meta {
		float: left;
		margin-right: 0;
		line-height: 1;
	}
}

.comment-avatar {
	position: absolute;
	top: 15px;
	left: -35px;
	padding: 4px;
	background: #FFF;
	border: 1px solid #E5E5E5;
	border-radius: 50%;
	img {
		display: block;
		border-radius: 50%;
	}
}

.commentlist li .children .comment-avatar {
	left: -25px;
}

.comment-content .comment-author {
	margin-bottom: -10px;
	font-size: 16px;
	font-weight: bold;
	color: #555;
	a {
		border: none;
		color: #333;
		&:hover {
			color: #00deff;
		}
	}
	span {
		display: block;
		font-size: 12px;
		font-weight: normal;
		font-family: 'Crete Round', serif;
		font-style: italic;
		color: #AAA;
		a {
			font-size: 12px;
			font-weight: normal;
			font-family: 'Crete Round', serif;
			font-style: italic;
			color: #AAA;
			&:hover {
				color: #888;
			}
		}
	}
}

.comment-reply-link {
	display: block;
	position: absolute;
	top: 4px;
	left: auto;
	text-align: center;
	right: 0px;
	width: 14px;
	height: 14px;
	color: #CCC;
	font-size: 14px;
	line-height: 1;
}

.review-comment-ratings {
	display: block;
	position: absolute;
	top: 4px;
	left: auto;
	text-align: center;
	right: 0px;
	width: 14px;
	height: 14px;
	color: #CCC;
	font-size: 14px;
	line-height: 1;
	width: auto;
	color: #333;
}

.comment-reply-link:hover {
	color: #888;
}

/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/

#respond {
	margin-bottom: 0;
	form {
		margin-bottom: 0;
	}
}

.commentlist li {
	#respond {
		margin: 30px 0 0;
	}
	li #respond {
		margin-left: 30px;
	}
}

#respond {
	p {
		margin: 10px 0 0 0;
		&:first-child {
			margin-top: 0;
		}
	}
	label small {
		color: #999;
		font-weight: normal;
	}
	input[type="text"], textarea {
		margin-bottom: 0;
	}
	.col_one_third, .col_full {
		margin-bottom: 20px;
	}
}

.fb-comments, .fb_iframe_widget, .fb-comments > span, .fb_iframe_widget > span, .fb-comments > span > iframe, .fb_iframe_widget > span > iframe {
	display: block !important;
	width: 100% !important;
	margin: 0;
}

/* Post Elements
-----------------------------------------------------------------*/

img.alignleft {
	float: left;
	margin: 5px 20px 13px 0;
	max-width: 100%;
}

div {
	&.alignleft {
		float: left;
		margin: 5px 20px 13px 0;
		max-width: 100%;
		> img {
			display: block;
			float: none;
		}
	}
	&.alignnone > img, &.aligncenter > img, &.alignright > img {
		display: block;
		float: none;
	}
}

img {
	&.alignnone, &.aligncenter {
		display: block;
		margin: 10px 0;
		float: none;
	}
}

div {
	&.alignnone, &.aligncenter {
		display: block;
		margin: 10px 0;
		float: none;
	}
}

img.aligncenter {
	margin-left: auto;
	margin-right: auto;
	clear: both;
}

div.aligncenter {
	margin-left: auto;
	margin-right: auto;
	clear: both;
	img {
		margin-left: auto;
		margin-right: auto;
		clear: both;
	}
}

img.alignright, div.alignright {
	float: right;
	margin: 5px 0 13px 20px;
}

.wp-caption {
	text-align: center;
	margin: 10px 20px 13px 20px;
	font-family: $header_font;
	font-style: italic;
	img {
		display: block;
		margin: 0;
		a {
			display: block;
			margin: 0;
		}
	}
}

p.wp-caption-text {
	display: inline-block;
	margin: 10px 0 0 0;
	padding: 5px 10px;
	background-color: #EEE;
	border-radius: 50px;
}

.wp-smiley {
	max-height: 13px;
	margin: 0 !important;
}

/* Infinity Scroll - Message Style
-----------------------------------------------------------------*/

#infscr-loading, #portfolio-ajax-loader {
	position: fixed;
	z-index: 20;
	top: 50%;
	left: 50%;
	width: 48px;
	height: 48px;
	margin: -24px 0 0 -24px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 3px;
	line-height: 48px;
	font-size: 24px;
	color: #FFF;
	text-align: center;
}

#infscr-loading img {
	display: none;
	width: 24px;
	height: 24px;
	margin: 12px;
}

#portfolio-ajax-loader {
	img {
		display: none;
		width: 24px;
		height: 24px;
		margin: 12px;
		display: block;
	}
	display: none;
}


/* ----------------------------------------------------------------
	Events
-----------------------------------------------------------------*/

.events .entry, .ievent {
	padding: 20px;
	background-color: #F9F9F9;
	border-bottom: 3px solid #EEE;
}

.events .entry-image .entry-date, .ievent .entry-image .entry-date {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	width: 60px;
	height: 64px;
	text-align: center;
	padding-top: 7px;
	font-size: 26px;
	line-height: 1;
}

.events .entry-image .entry-date span, .ievent .entry-image .entry-date span {
	display: block;
	font-size: 15px;
	margin-top: 5px;
}

.events .entry-c, .ievent .entry-c {
	padding: 5px 0;
}

.single-event {
	.entry-image img {
		border-radius: 0;
	}
	.events-meta {
		font-size: 14px;
		.iconlist li {
			margin: 5px 0;
		}
	}
}

.parallax .iconlist li {
	margin: 5px 0;
}

.single-event .entry-overlay, .parallax .entry-overlay-meta {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.85);
	text-align: center;
	line-height: 80px;
	font-size: 22px;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	z-index: 5;
}

.single-event {
	.countdown {
		display: inline-block;
		position: relative;
		top: 7px;
	}
	.countdown-section {
		padding: 0 15px;
		border-left-color: rgba(255, 255, 255, 0.3);
		color: #CCC;
	}
	.countdown-amount {
		color: #FFF;
	}
}

.parallax {
	.entry-overlay {
		background: transparent;
		width: auto;
		text-align: right;
		bottom: 30px;
		left: auto;
		right: 30px;
	}
	.entry-overlay-meta {
		background: transparent;
		width: auto;
		text-align: right;
		bottom: 30px;
		left: auto;
		right: 30px;
		padding: 20px;
		font-size: 14px;
		text-align: left;
		right: 38px;
		bottom: 130px;
		width: 368px;
		height: auto;
		line-height: inherit;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 3px;
	}
	&.overlay-left {
		.entry-overlay {
			right: auto;
			left: 30px;
		}
		.entry-overlay-meta {
			right: auto;
			left: 30px;
			left: 38px;
		}
	}
	&.overlay-center {
		.entry-overlay {
			bottom: 100px;
			right: auto;
			left: 50%;
			margin-left: -192px;
		}
		.entry-overlay-meta {
			bottom: 100px;
			right: auto;
			left: 50%;
			margin-left: -192px;
			top: 100px;
			bottom: auto;
			margin-left: -184px;
		}
	}
	.entry-overlay-meta h2 {
		font-size: 20px;
		text-transform: uppercase;
		border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
		padding-bottom: 17px;
		a {
			color: #FFF;
			&:hover {
				color: #DDD;
			}
		}
	}
	.countdown {
		top: 0;
	}
	.countdown-section {
		border-left: 0;
		width: 80px;
		height: 80px;
		margin: 0 8px;
		font-size: 13px;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 3px;
		padding-top: 14px;
	}
	.countdown-amount {
		font-size: 28px;
		margin-bottom: 6px;
	}
}

/* Individual Events
-----------------------------------------------------------------*/

.ievent {
	position: relative;
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	.entry-image {
		width: 30%;
		margin-right: 20px;
	}
	.entry-content {
		margin-top: 20px;
	}
	.entry-title h2 {
		font-size: 18px;
	}
	.entry-meta {
		margin-right: -10px;
		li {
			font-size: 13px;
			margin-right: 10px;
			padding-left: 10px;
			&:first-child {
				padding-left: 0;
			}
		}
	}
}

/* ----------------------------------------------------------------
	Countdown
-----------------------------------------------------------------*/

.countdown {
	display: block;
}

.countdown-row {
	display: block;
	position: relative;
	text-align: center;
}

.countdown-section {
	display: inline-block;
	font-size: 11px;
	line-height: 1;
	text-align: center;
	width: 25%;
	border-left: 1px dotted #BBB;
	color: #888;
	text-transform: capitalize;
	&:first-child {
		border-left: 0;
	}
}

.countdown-amount {
	display: block;
	font-size: 20px;
	color: #333;
	margin-bottom: 5px;
}

.countdown-descr {
	display: block;
	width: 100%;
}

/* Countdown - Medium
-----------------------------------------------------------------*/

.countdown-medium {
	.countdown-section {
		font-size: 14px;
	}
	.countdown-amount {
		font-size: 30px;
		margin-bottom: 7px;
	}
}

/* Countdown - Large
-----------------------------------------------------------------*/

.countdown-large {
	.countdown-section {
		font-size: 18px;
	}
	.countdown-amount {
		font-size: 44px;
		font-weight: 600;
		margin-bottom: 8px;
	}
}

/* Countdown - Inline
-----------------------------------------------------------------*/

.countdown.countdown-inline {
	display: inline-block;
	.countdown-row {
		display: inline-block;
		text-align: center;
	}
	.countdown-section {
		display: inline-block;
		font-size: inherit;
		line-height: inherit;
		width: auto;
		border: none;
		color: inherit;
		margin-left: 7px;
		text-transform: lowercase;
		&:first-child {
			margin-left: 0;
		}
	}
	.countdown-amount {
		display: inline-block;
		font-size: inherit;
		color: inherit;
		font-weight: bold;
		margin: 0 3px 0 0;
	}
	.countdown-descr {
		display: inline-block;
		width: auto;
	}
}

/* Countdown - Coming Soon
-----------------------------------------------------------------*/

body:not(.device-xs):not(.device-xxs) .countdown.countdown-large.coming-soon {
	.countdown-section {
		border: none;
		padding: 15px;
	}
	.countdown-amount {
		height: 140px;
		line-height: 140px;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.2);
		margin-bottom: 15px !important;
	}
}
