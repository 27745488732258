main {
	@include transition(transform 0.55s);
	/* Force Hardware Acceleration in WebKit */
	@include transform(translateZ(0));
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	h1 {
		text-align: center;
		font-size: 2.4rem;
		font-weight: 300;
		color: #ffffff;
		margin: 2em auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@include media($mobile_mlg) {
			font-size: 3.6rem;
			margin: 4em auto;
		}
	} // h1

	&.nav-is-visible {
		@include transform(translateY(80px));

		@include media($mobile_mds) {
			@include transform(translateY(170px));
		}
	} // &.nav-is-visible

	// ::
	// PARTICLE HERO ---------------------------::
	// ____
	#section-home {
		.container {
			width: 100%;
			padding: 0;

			#particle-slider {
				display: none;

				@include media($desktop_xsm) {
					display: block;
					@include size(100%);
					margin: 0;
					padding: 0;
				}
			}

			.slides, & > .dg {
				display: none;
			}

			.dg {
				display: none;
			}

			.sliderlogo {
				display: block;
				@include vertical_align();
				@include size(200px);
				padding: 0;
				margin: 0;

				svg {
					fill: $aqua_cream;
				}

				@include media($mobile_mds) {
					@include size(300px);
				}

				@include media($desktop_xsm) {
					display: none;
				}
			}
		}
	} // #section-home

	// ::
	// ABOUT ---------------------------::
	// ____
	#section-abt {
		/* Intro Heading + Rotating Text
		-----------------------------------------------------------------*/
		.headliner {
			font-family: $manifold;

			h1 {
				font-weight: 400;
				color: $txt_white_lt;
				font-size: rem(38);
				line-height: rem(40);
				margin: 16px 0 8px 0;
				letter-spacing: 1px;

				@include media($mobile_mlg) {
					font-size: rem(48);
					line-height: rem(50);
					margin: 30px 0 28px 0;
				}
			}

			h2 {
				font-weight: 400;
				color: #858f97;
				font-size: rem(24);
				line-height: rem(28);
				margin-bottom: 30px;
				letter-spacing: 1px;

				@include media($mobile_mlg) {
					font-size: rem(34);
					line-height: rem(38);
					margin-bottom: 14px;
				}
			}

			.bb-headline {
				margin-bottom: 30px;

				span {
					font-weight: 400;
					color: $txt_white_lt;
					font-size: 40px;
					line-height: 56px;
					letter-spacing: 2px;

					@include media($desktop_sm) {
						font-size: 60px;
						line-height: 92px;
						letter-spacing: 3px;
					}

				}
			}
		} // .headliner

		.bb-words-wrapper {
			display: inline-block;
			position: relative;
			text-align: left;

			b {
				display: inline-block;
				position: absolute;
				white-space: nowrap;
				left: 0;
				top: 0;

				&.is-visible {
					position: relative;
				}
			}
		} // .bb-words-wrapper

		.no-js .bb-words-wrapper b {
			opacity: 0;

			&.is-visible {
				opacity: 1;
			}
		}

		.bb-headline.type {
			display: none;

			@include media($mobile_mlg) {
				display: block;
			}

			.bb-words-wrapper {
				vertical-align: top;
				overflow: hidden;

				&::after {
					/* vertical bar */
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					bottom: auto;
					@include transform(translateY(-50%));
					height: 90%;
					width: 1px;
					background-color: #aebcb9;
				}

				&.waiting::after {
					@include animation(bb-pulse 1s infinite);
				}

				&.selected {
					background-color: #aebcb9;

					&::after {
						visibility: hidden;
					}

					b {
						color: #0d0d0d;
					}
				}
			} // .bb-words-wrapper

			b {
				visibility: hidden;

				&.is-visible {
					visibility: visible;
				}
			}

			i {
				position: absolute;
				visibility: hidden;

				&.in {
					position: relative;
					visibility: visible;
				}
			}
		} // .bb-headline.type


		@include keyframes(bb-pulse) {
			0% {
				@include transform(translateY(-50%) scale(1));
				opacity: 1;
			}

			40% {
				@include transform(translateY(-50%) scale(0.9));
				opacity: 0;
			}

			100% {
				@include transform(translateY(-50%) scale(0));
				opacity: 0;
			}
		}


		.code {
			background: url('../images/code.png') center center no-repeat;
			background-size: 112px 104px;
			width: 112px;
			height: 104px;
			/*display: inline-block;*/
		}

		/* Profile
		-----------------------------------------------------------------*/

		.about-profile {

			.profile-pic,
			.profile-right {
				height: 400px;
				min-height: 400px;

				@include media($mobile_xlg) {
					height: 480px;
					min-height: 480px;
					width: 50%;
					display: inline-block;
					float: left;
				}
			}
			.profile-pic {
				background: url('../images/me.jpg') center center no-repeat #2e3135;
				background-size: cover;
			}

			.profile-right {
				display: none;

				@include media($mobile_mds) {
					display: block;
					background: #3b3e42;
					text-align: center;
					padding: 36px;
				}

				// Twitter Icon
				i {
					font-size: 30px;
					line-height: 66px;
					color: #2a2c30;
				}
			}
		}
	} // #section-abt

	// ::
	// SKILLS ---------------------------::
	// ____
	#section-skills {
		.skillblok {
			position: relative;
			display: block;
			font-size: rem(14);
			line-height: 20px;
			letter-spacing: 2px;
			margin: 0 4px 12px 0;
			@include transition(color 0.4s);
			z-index: 1;

			@include media($mobile_lg) {
				font-size: 1rem;
			}

			@include media($mobile_mlg) {
				background: $concrete_dk;
				padding: 10px 20px !important;
				display: inline-block;

				&:after {
					content: '';
					@include position(absolute, 0 null null 0);
					background: $aqua_white;
					@include size(0 100%);
					@include transition(all 0.45s $ease-in-out-quart);
					z-index: -1;
				}

				&:hover {
					color: $concrete_lt;

					&:after {
						width: 100%;
					}
				}
			} // @include media($mobile_mlg)
		}

		.lang-icon {
			width: 90px;
			height: 118px;
			margin-bottom: 30px;
		}

		.html-icon {
			background: url('../images/icons/html.png') no-repeat;
			background-size: 90px 118px;
		}

		.css-icon {
			background: url('../images/icons/css.png') no-repeat;
			background-size: 90px 118px;
		}

		.php-icon {
			background: url('../images/icons/php.png') no-repeat;
			background-size: 90px 118px;
		}

		.js-icon {
			background: url('../images/icons/js.png') no-repeat;
			background-size: 90px 118px;
		}

		.skill-intro {
			display: none;

			@include media($mobile_mlg) {
				display: block;
			}
		}

		.centerlist {
			display: none;

			@include media($mobile_mlg) {
				display: block;

				ul {
					/*text-align: center;*/
					list-style-type: circle;
					width: 80%;
					line-height: 21px;
					margin: auto;
				}

				li {
					margin-left: 20px;
					ul {
						list-style-type: none;
						margin-left: -2px;
						> li:before {
							content: "–";
							/* en dash here */
							position: absolute;
							margin-left: -1.1em;
						}
					}
				} // li
			} // @include media
		} // .centerlist

		.baselist {
			ul {
				list-style-type: circle;
				line-height: 21px;
			}
			li {
				margin-left: 20px;
				ul {
					list-style-type: none;
					margin-left: -2px;
					> li:before {
						content: "–";
						/* en dash here */
						position: absolute;
						margin-left: -1.1em;
					}
				}
			}
		}

		/* Code Window */
		.maincode {
			margin: 0 auto;
			margin-bottom: 110px;
			width: 200px;
			height: 200px;
			background-color: #3a3d40;
			border-radius: 7px;
			header {
				width: 100%;
				height: 18px;
				background-color: #E6E6E6;
				border-radius: 7px 7px 0 0;
				div {
					width: 7px;
					height: 7px;
					margin: 0;
					border-radius: 50%;
					float: left;
					margin-right: 5px;
					margin-top: 6px;
					&:nth-child(1) {
						margin-left: 4px;
					}
				}
			}
			.redbtn {
				background-color: #ff747b;
			}
			.yellowbtn {
				background-color: #f2c559;
			}
			.greenbtn {
				background-color: #99cd70;
			}
			.pink {
				background-color: #c18086;
			}
			.dark-grey {
				background-color: #626d75;
			}
			.grey {
				background-color: #737f8a;
			}
			.orange {
				background-color: #c29282;
			}
			.green {
				background-color: #9ead92;
			}
			.blue {
				background-color: #97adc1;
			}
			.purple {
				background-color: #a991a5;
			}
			.yellow {
				background-color: #d2c3a8;
			}
			.sidebar {
				width: 25px;
				height: 182px;
				background-color: $concrete_dk;
				border-radius: 0 0 0 7px;
				float: left;
			}
			.codelines {
				float: left;
				/*width: 175px;
				height: 182px;*/
				position: absolute;
				padding: 13px 0 0 38px;
			}
			.codeline {
				float: left;
				width: 68px;
				height: 6px;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				margin: 0 4px 6px 0;
			}
			.codelines .codeseparator {
				width: 100%;
			}
			.line1 {
				width: 120px;
			}
			.line4 {
				width: 100px;
			}
			.line5 {
				width: 85px;
			}
			.line6 {
				width: 50px;
			}
			.line7 {
				width: 105px;
			}
			.line8 {
				width: 125px;
			}
			.line9 {
				width: 133px;
			}
			.line10 {
				width: 45px;
			}
		}

		.cdln01, .cdln02, .cdln03, .cdln04, .cdln05, .cdln06, .cdln07, .cdln08, .cdln09, .cdln10, .cdln11, .cdln12, .cdln13, .cdln14, .cdln15 {
			opacity: 0;
		}

		.designicon {
			background: url('../assets/images/icons/design.svg') no-repeat;
			background-size: 189px 210px;
			width: 189px;
			height: 210px;
		}
	} // #section-skills

	// ::
	// WORK ---------------------------::
	// ____
	#section-work {
		.work-grid {
			position: relative;
			display: inline-block;
			width: 100%;
			z-index: 2;

			.portfolio-item {
				position: relative;
				float: left;
				width: 100%;
				margin: 0;

				@include media($mobile_mlg) {
					width: 50%;
				}

				@include media($desktop_sm) {
					width: calc(100% / 3);
				}

				@include media($desktop_xlg) {
					width: 25%;
				}

				.portfolio-image {
					position: relative;
					overflow: hidden;
					display: block;
					width: calc(100% + 1px); // Fix sub-pixel rendering
					max-height: 400px;

					a, img {
						display: block;
					}
				}
			}
		}
	} // #section-work

	// ::
	// TESTIMONIALS ---------------------------::
	// ____
	.testimonials-prlx {
		h5 {
			font-family: $manifold;
			font-size: rem(24);
			color: $aqua_cream;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-bottom: 30px;
		}

		.lineglitch {
			display: none;

			@include media($mobile_mds) {
				display: block;
			}
		}

		.testimonial-full {
			.testi-content {
				p {
					font-family: $ironbanana;
					color: #ffffff;
					font-size: rem(14);
				}
			} // .testi-content
			.testi-meta {
				letter-spacing: 2px;

				span {
					font-size: rem(14);
				}
			}
		} // .testimonial-full
	} // .testimonials-prlx

	// ::
	// CONTACT ---------------------------::
	// ____
	#section-contact {
		.social-sector {
			display: table;
			width: 264px;
		}

		.topmargin-lg {
			margin-top: 10px !important;

			@include media($mobile_mlg) {
				margin-top: 80px !important;
			}
		}

		a.resume {
			position: relative;
			display: inline-block;
			background: $concrete_dk;
			margin-top: 16px;
			padding: 10px 20px 10px 56px;
			color: $txt_white_dk;
			@include transition(color 0.4s);
			z-index: 1;

			&:after {
				content: '';
				@include position(absolute, 0 null null 0);
				background: $aqua_white;
				@include size(0 100%);
				@include transition(all 0.45s $ease-in-out-quart);
				z-index: -1;
			}

			i {
				position: absolute;
				left: 20px;
				color: lighten($concrete_lt, 10%);
				font-size: rem(22);
				margin-right: 10px;
				@include transition(color 0.4s);
			}

			&:hover {
				color: $concrete_lt;

				i {
					color: $faded_aqua;
				}

				&:after {
					width: 100%;
				}
			}
		} // a.resume

		.footer {
			.col_full {
				margin: 40px 0;

				@include media($desktop_xsm) {
					margin: 80px 0;
				}
			}
		}
	} // #section-contact
} // main
