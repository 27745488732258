/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

.large-header {
	position: relative;
	width: 100%;
	overflow: hidden;
	z-index: 1;
}

.bb-header {
	position: fixed;
	z-index: 1040;
	height: 80px;
	width: 100%;
	margin: 0 auto;
	-webkit-transition: -webkit-transform 0.5s;
	-moz-transition: -moz-transform 0.5s;
	transition: transform 0.5s;
	/* Force Hardware Acceleration in WebKit */
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	&:after {
		content: "";
		display: table;
		clear: both;
	}

	&.nav-is-visible {
		@include transform(translateY(80px));

		@include media($mobile_mds) {
			@include transform(translateY(170px));
		}
	}
}

.bb-3d-nav-trigger {
	position: relative;
	float: right;
	height: 45px;
	width: 45px;
	margin: 18px 28px 0 0;
	/* replace text with background image */
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	color: transparent;
	span {
		/* hamburger icon in CSS */
		position: absolute;
		width: 28px;
		height: 3px;
		background-color: #FFF;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		&::before, &::after {
			/* hamburger icon in CSS */
			position: absolute;
			width: 28px;
			height: 3px;
			background-color: #FFF;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		}
		/* this is the central line */
		top: 21px;
		left: 8px;
		-webkit-transition: background 0.2s 0.5s;
		-moz-transition: background 0.2s 0.5s;
		transition: background 0.2s 0.5s;
		&::before, &:after {
			/* these are the upper and lower lines */
			content: '';
			left: 0;
			-webkit-transition: -webkit-transform 0.2s 0.5s;
			-moz-transition: -moz-transform 0.2s 0.5s;
			transition: transform 0.2s 0.5s;
		}
		&::before {
			bottom: 8px;
		}
		&::after {
			top: 8px;
		}
	}
}

.nav-is-visible .bb-3d-nav-trigger span {
	/* hide line in the center */
	background-color: rgba(255, 255, 255, 0);
	&::before, &::after {
		/* keep visible other 2 lines */
		background-color: white;
	}
	&::before {
		-webkit-transform: translateY(8px) rotate(-45deg);
		-moz-transform: translateY(8px) rotate(-45deg);
		-ms-transform: translateY(8px) rotate(-45deg);
		-o-transform: translateY(8px) rotate(-45deg);
		transform: translateY(8px) rotate(-45deg);
	}
	&::after {
		-webkit-transform: translateY(-8px) rotate(45deg);
		-moz-transform: translateY(-8px) rotate(45deg);
		-ms-transform: translateY(-8px) rotate(45deg);
		-o-transform: translateY(-8px) rotate(45deg);
		transform: translateY(-8px) rotate(45deg);
	}
}

.bb-3d-nav-container {
	/* this is the 3D navigation container */
	position: fixed;
	top: 0;
	left: 0;
	height: 80px;
	width: 100%;
	background-color: #000000;
	visibility: hidden;
	/* enable a 3D-space for children elements */
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
	-moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
	transition: transform 0.5s 0s, visibility 0s 0.5s;

	&.nav-is-visible {
		visibility: visible;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
		-webkit-transition: -webkit-transform 0.5s 0s, visibility 0.5s 0s;
		-moz-transition: -moz-transform 0.5s 0s, visibility 0.5s 0s;
		transition: transform 0.5s 0s, visibility 0.5s 0s;
	}

	@include media($mobile_mds) {
		height: 170px;
	}
}

.bb-3d-nav {
	/* this is the 3D rotating navigation */
	position: relative;
	height: 100%;
	background-color: #3a3d40;
	/* Force Hardware Acceleration */
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-origin: center bottom;
	-moz-transform-origin: center bottom;
	-ms-transform-origin: center bottom;
	-o-transform-origin: center bottom;
	transform-origin: center bottom;
	-webkit-transform: rotateX(90deg);
	-moz-transform: rotateX(90deg);
	-ms-transform: rotateX(90deg);
	-o-transform: rotateX(90deg);
	transform: rotateX(90deg);
	-webkit-transition: -webkit-transform 0.5s;
	-moz-transition: -moz-transform 0.5s;
	transition: transform 0.5s;
	&::after {
		/* menu dark cover layer - to enhance perspective effect */
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #000000;
		opacity: 1;
		visibility: visible;
		-webkit-transition: opacity 0.5s 0s, visibility 0.5s 0s;
		-moz-transition: opacity 0.5s 0s, visibility 0.5s 0s;
		transition: opacity 0.5s 0s, visibility 0.5s 0s;
	}
	li {
		height: 100%;
		width: 20%;
		float: left;
		a {
			.nav-icon {
				@include size(26px);
				fill: #ffffff;
				position: absolute;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;

				path {
					-webkit-transition: fill .4s ease;
					-moz-transition: fill .4s ease;
					-o-transition: fill .4s ease;
					transition: fill .4s ease;
				}

				@include media($mobile_mds) {
					@include size(40px);
				}
			}
			span {
				display: none;

				@include media($mobile_mds) {
					position: absolute;
					left: 0;
					right: 0;
					display: block;
					margin-left: auto;
					margin-right: auto;
					margin-top: 64px;
					text-align: center;
				}
			}
		}
	}
	a {
		font-family: $header_font;
		text-transform: uppercase;
		letter-spacing: 2px;
		position: relative;
		display: block;
		height: 100%;
		padding: 24px 0 0;
		color: #61676e;
		font-size: rem(14);
		font-weight: 400;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* truncate text with ellipsis if too long */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-transition: background-color 0.4s, color 0.4s;
		-moz-transition: background-color 0.4s, color 0.4s;
		transition: background-color 0.4s, color 0.4s;

		@include media($mobile_mds) {
			padding: 40px 0 0;
		}

		@include media($mobile_mlg) {
			font-size: rem(18);
		}

		&:hover {
			background-color: #313437;
			color: #7c9da7;
		}
	}
}

.no-touch .bb-3d-nav a:hover {
	background-color: #313437;
	color: #7c9da7;
}

.bb-3d-nav .bb-selected {
	a {
		background-color: #222427;
		color: #54585c;
	}
	.nav-icon path {
		fill: $aqua_cream;
	}
}

.bb-3d-nav .bb-selected a:hover {
	background-color: #222427;
	color: #54585c;
}

.nav-is-visible .bb-3d-nav {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	&::after {
		/* menu cover layer - hide it when navigation is visible */
		opacity: 0;
		visibility: hidden;
		-webkit-transition: opacity 0.5s 0s, visibility 0s 0.5s;
		-moz-transition: opacity 0.5s 0s, visibility 0s 0.5s;
		transition: opacity 0.5s 0s, visibility 0s 0.5s;
	}
}

.bb-marker {
	/* line at the bottom of nav selected item */
	position: absolute;
	bottom: 0;
	left: 0;
	height: 3px;
	width: 20%;
	background-color: currentColor;
	-webkit-transform-origin: center bottom;
	-moz-transform-origin: center bottom;
	-ms-transform-origin: center bottom;
	-o-transform-origin: center bottom;
	transform-origin: center bottom;
	-webkit-transform: translateZ(0) rotateX(90deg);
	-moz-transform: translateZ(0) rotateX(90deg);
	-ms-transform: translateZ(0) rotateX(90deg);
	-o-transform: translateZ(0) rotateX(90deg);
	transform: translateZ(0) rotateX(90deg);
	-webkit-transition: -webkit-transform 0.5s, left 0.5s, color 0.5s, background-color 0.5s;
	-moz-transition: -moz-transform 0.5s, left 0.5s, color 0.5s, background-color 0.5s;
	transition: transform 0.5s, left 0.5s, color 0.5s, background-color 0.5s;
	&::before {
		/* triangle at the bottom of nav selected item */
		content: '';
		position: absolute;
		bottom: 3px;
		left: 50%;
		right: auto;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 0;
		width: 0;
		border: 10px solid transparent;
		border-bottom-color: inherit;
	}
}

.nav-is-visible .bb-marker {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
}

/* these are the colors of the markers - line + arrow */

.color-1, .color-2, .color-3, .color-4, .color-5 {
	color: $aqua_cream;
}

/* ----------------------------------------------------------------
	Logo
-----------------------------------------------------------------*/


#logo {
	position: relative;
	@include size(60px);
	float: left;
	margin: 10px 0 0 28px;

	@include media($mobile_mlg) {
		@include size(100px);
		margin: 28px 0 0 28px;
	}

	svg {
		fill: $aqua_cream;
		overflow: visible;

		path {
			opacity: 0;
		}
	}
}
