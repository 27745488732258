/* ----------------------------------------------------------------
	Testimonials
-----------------------------------------------------------------*/

/* Testimonials - Grid
-----------------------------------------------------------------*/

.testimonials-grid {
	li {
		width: 50%;
		padding: 25px;
	}
	&.grid-3 li {
		width: 33.33%;
	}
	li .testimonial {
		padding: 0;
		background-color: transparent !important;
		border: 0 !important;
		box-shadow: none !important;
	}
}

/* Testimonials - Item
-----------------------------------------------------------------*/

.testimonial {
	position: relative;
	padding: 20px;
	/*background-color: #FFF;*/
	/*border: 1px solid rgba(0,0,0,0.1);*/
	border-radius: 5px;
	/*box-shadow: 0 1px 1px rgba(0,0,0,0.1);*/
}

.testi-image {
	float: left;
	margin-right: 15px;
	display: block;
	width: 64px;
	height: 64px;
	a, img {
		display: block;
		width: 64px;
		height: 64px;
	}
	i {
		display: block;
		width: 64px;
		height: 64px;
		text-align: center;
		background-color: #EEE;
		border-radius: 50%;
		line-height: 64px;
		font-size: 28px;
		color: #888;
		text-shadow: 1px 1px 1px #FFF;
	}
	img {
		border-radius: 50%;
	}
}


.testi-content {
	position: relative;
	overflow: hidden;
	p {
		margin-bottom: 0;
		font-family: $manifold, sans-serif;
		color: #ffffff;
		font-size: rem(14);
		line-height: 1.8em;
		letter-spacing: 1px;
		font-style: normal;
		&:before, &:after {
			content: '"';
		}
	}
}

.testi-meta {
	font-family: $manifold, sans-serif;
	margin-top: 10px;
	font-size: 18px;
	color: #ffffff;
	text-transform: uppercase;
	&:before {
		content: '\2013';
	}
	span {
		font-family: $ironbanana;
		display: block;
		font-weight: normal;
		color: #ffffff;
		font-size: 17px;
		text-transform: none;
		padding-left: 10px;
	}
}

/* Twitter - Small Scroller
-----------------------------------------------------------------*/


.testimonial {
	&.twitter-scroll {
		@include vertical_align;
		height: 300px !important;
		// padding-top: 13%;

		.testi-content p {
			&:before, &:after {
				content: '';
			}
		}
		.testi-meta:before {
			content: '';
		}
	}
	&.text-scroll {
		// padding: 40px 10px 0 10px;
		padding: 20px 10px;
		margin-bottom: -50px !important;

		.flexslider, .slider-wrap {
			height: 100px !important;
		}

		.slide {
			p {
				@include vertical_align;
			}
		}
	}
}

.twitter-scroll {
	-webkit-font-smoothing: antialiased;
	p, .testi-meta span {
		-webkit-font-smoothing: antialiased;
	}
}

.text-scroll {
	-webkit-font-smoothing: antialiased;
	p {
		-webkit-font-smoothing: antialiased;
	}
}

#content {
	.twitter-scroll p {
		font-family: $manifold, sans-serif;
		color: #61676e;
		font-size: rem(20);
		line-height: rem(28);
		letter-spacing: 2px;
		font-style: normal;

		@include media($desktop_sm) {
			font-size: rem(24);
			line-height: 1.8em;
		}
	}

	.text-scroll p {
		font-family: $manifold, sans-serif;
		color: #61676e;
		font-size: rem(18);
		line-height: 1.3em;
		letter-spacing: 2px;
		font-style: normal;
		text-align: center;

		@include media($mobile_md) {
			font-size: rem(22);
			line-height: 1.6em;
		}
	}
}

.testimonial.twitter-scroll a {
	color: #7c9da7;
	-webkit-transition: color 0.5s;
	-moz-transition: color 0.5s;
	-o-transition: color 0.5s;
	transition: color 0.5s;
	&:hover {
		color: #60d9eb;
	}
}

.twitter-scroll .flex-control-nav, .text-scroll .flex-control-nav {
	display: none;
}

.testimonial.twitter-scroll .testi-meta span {
	padding-left: 0;

	a {
		font-size: 1rem;
		color: #7c9da7;
		@include transition(color 0.5s);

		@include media($desktop_sm) {
			font-size: rem(18);
		}

		&:hover {
			color: $aqua_cream;
		}
	}
}

/* Testimonials - Full Width
-----------------------------------------------------------------*/

.testimonial.testimonial-full {
	padding: 30px;

	&[data-animation="fade"] {

		padding: 30px;

		.flexslider {
			overflow: visible !important;
		}
	}
}

.testimonial-full {
	.testi-image {
		float: none;
		margin: 0 auto 20px;
		display: block;
		width: 72px;
		height: 72px;
		a, img {
			display: block;
			width: 72px;
			height: 72px;
		}
		i {
			display: block;
			width: 72px;
			height: 72px;
			line-height: 72px;
		}
	}
	.testi-content {
		text-align: center;
		font-size: 18px;
	}
	.testi-meta {
		margin-top: 15px;
		span {
			padding-left: 0;
		}
	}
}

/* Testimonial - Section Scroller
-----------------------------------------------------------------*/

.section > .testimonial {
	padding: 0;
	background-color: transparent !important;
	border: 0 !important;
	box-shadow: none !important;
	max-width: 800px;
	margin: 0 auto;
	&[data-animation="fade"] {
		padding: 0;
	}
	.testi-content {
		font-size: 22px;
	}
	.testi-meta {
		font-size: 14px;
		span {
			font-size: 14px;
		}
	}
}

/* Testimonial - Pagination
-----------------------------------------------------------------*/

.testimonial {
	.flex-control-nav {
		top: auto;
		bottom: 6px;
		right: 0;
		li {
			margin: 0 6px;
			width: 10px;
			height: 10px;
			a {
				width: 10px !important;
				height: 10px !important;
				border: none;
				background-color: #60d9eb;
				opacity: 0.5;
			}
			&:hover a {
				opacity: 0.75;
			}
			a.flex-active {
				opacity: 1;
			}
		}
	}
	&.testimonial-full .flex-control-nav {
		position: relative;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 6px;
		margin-top: 20px;
		li {
			display: inline-block;
			float: none;
		}
	}
}

/* ----------------------------------------------------------------
	Quotes & Blockquotes
-----------------------------------------------------------------*/

blockquote {
	p {
		margin-bottom: 15px;
	}
	&.pull-left {
		max-width: 300px;
		margin: 5px 20px 10px 0;
		padding-right: 0;
	}
	&.pull-right {
		max-width: 300px;
		margin: 5px 0 10px 20px;
		padding-left: 0;
	}
}

.quote {
	border: none !important;
	position: relative;
	p {
		position: relative;
	}
	&:before {
		font-family: 'font-icons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		content: "\e7ad";
		position: absolute;
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 42px;
		top: 0;
		left: 0;
		color: #EEE;
	}
	&.blockquote-reverse:before {
		content: "\e7ae";
		left: auto;
		right: 0;
	}
}
