@include font-face("Bash Tech", "../fonts/bashtech-webfont");
@include font-face("Aqua Grotesque", "../fonts/aqua-webfont");
@include font-face("TR909", "../fonts/tr-909-webfont");
@include font-face("Airship 27", "../fonts/Airship 27-Regular-webfont");
@include font-face("Manifold", "../fonts/ManifoldCF-Regular");
@include font-face("Iron Banana", "../fonts/native-regular");


html {
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5;
	color: #b2bcc4;
	font-family: $header_font;
}

a {
	text-decoration: none !important;
	color: $aqua_cream;

	&:hover,
	&:focus,
	&:active {
		outline: 0;
		color: inherit;
	}

	&:hover {
		color: #222;
	}
	img {
		border: none;
	}
}

img {
	max-width: 100%;
}

iframe {
	border: none !important;
}


h1, h2, h3, h4, h5, h6 {
	color: #222427;
	font-weight: 600;
	line-height: 1.5;
	margin: 0 0 30px 0;
	font-family: $header_font;
}

h5, h6 {
	margin-bottom: 20px;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 24px;

	&.concrete-htag {
		font-size: rem(28);
		color: #808891;
		letter-spacing: 2px;
		margin-bottom: 10px;

		@include media($mobile_xsm) {
			font-size: rem(42);
			margin-bottom: 20px;
		}
	}
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 14px;
}

h6 {
	font-size: 12px;
}

h4 {
	font-weight: 600;
}

h5, h6 {
	font-weight: bold;
}

h1 > span:not(.nocolor), h2 > span:not(.nocolor), h3 > span:not(.nocolor), h4 > span:not(.nocolor), h5 > span:not(.nocolor), h6 > span:not(.nocolor) {
	color: $aqua_cream;
}

p, pre, ul, ol, dl, dd, blockquote, address, table, fieldset, form {
	margin-bottom: 30px;
}

h1.bash {
	font-family: $bash_tech;
	font-size: 68px;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 30px;
	color: #edf8fb;
}

h2.tr909 {
	font-family: $tech_font;
	font-size: 46px;
	font-weight: 400;
	letter-spacing: 7px;
	color: #b6ecff;
}

h3.dark {
	text-transform: uppercase;
	color: #5a5f64;
	font-weight: 400;
}

h4.sglow {
	color: #7c9da7;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 2px;
	margin-bottom: -4px;
}

.page-section, .section, .page-section p {
	font-family: $ironbanana, monospace;
	font-size: 1rem;
	line-height: 1.8em;
	letter-spacing: 0.5px;
	color: #c4ced6;
}

small {
	font-family: $header_font;
}

.uppercase {
	text-transform: uppercase;
}

.tright {
	text-align: right !important;
}

.tleft {
	text-align: left !important;
}


/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/

#page-title {
	position: relative;
	padding: 50px 0;
	background-color: #F5F5F5;
	border-bottom: 1px solid #EEE;
	.container {
		position: relative;
	}
	h1 {
		padding: 0;
		margin: 0;
		line-height: 1;
		font-weight: 600;
		letter-spacing: 1px;
		color: #333;
		font-size: 28px;
		text-transform: uppercase;
	}
	span {
		display: block;
		margin-top: 10px;
		font-weight: 300;
		color: #777;
		font-size: 18px;
	}
}

.breadcrumb {
	position: absolute !important;
	width: auto !important;
	top: 50% !important;
	left: auto !important;
	right: 15px !important;
	margin: -10px 0 0 0 !important;
	background-color: transparent !important;
	padding: 0 !important;
	font-size: 12px;
}

body.stretched .container-fullwidth .breadcrumb {
	right: 60px !important;
}

#header.transparent-header + #page-title.page-title-parallax .breadcrumb {
	margin-top: 35px !important;
}

.breadcrumb {
	a {
		color: #555;
		&:hover {
			color: $aqua_cream;
		}
	}
	i {
		width: auto !important;
	}
}

/* Page Title
-----------------------------------------------------------------*/
#page-title {
	&.page-title-dark {
		background-color: #333;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important;
		border-bottom: none;
		h1 {
			color: rgba(255, 255, 255, 0.9) !important;
		}
		span {
			color: rgba(255, 255, 255, 0.7);
		}
		.breadcrumb {
			> .active {
				color: rgba(255, 255, 255, 0.6);
			}
			a {
				color: rgba(255, 255, 255, 0.8);
				&:hover {
					color: rgba(255, 255, 255, 0.95);
				}
			}
		}
	}
	&.page-title-right {
		text-align: right;
		.breadcrumb {
			left: 15px !important;
			right: auto !important;
		}
	}
	&.page-title-center {
		text-align: center;
		span {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
		}
		.breadcrumb {
			position: relative !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			margin: 20px 0 0 !important;
		}
	}
	&.page-title-pattern {
		background-image: url('../images/pattern.png');
		background-repeat: repeat;
		background-attachment: fixed;
	}
	&.page-title-parallax {
		padding: 100px 0;
		text-shadow: none;
		border-bottom: none;
		h1 {
			font-size: 40px;
			font-weight: 600;
			letter-spacing: 2px;
		}
		span {
			font-size: 22px;
		}
		.breadcrumb {
			font-size: 13px;
		}
		background-color: transparent;
		background-image: url('../images/parallax/parallax-bg.jpg');
		background-attachment: fixed;
		background-position: 50% 0;
		background-repeat: no-repeat;
	}
	&.page-title-mini {
		padding: 20px 0;
		h1 {
			font-weight: 600;
			font-size: 18px;
			color: #444;
		}
		span {
			display: none;
		}
	}
	&.page-title-nobg {
		background: transparent !important;
		border-bottom: 1px solid #F5F5F5;
	}
	&.page-title-video {
		background: none;
		position: relative;
		overflow: hidden;
		.container {
			z-index: 3;
		}
		.video-wrap {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			video {
				width: 100%;
			}
		}
	}
}

/* Block Titles
-----------------------------------------------------------------*/

.title-block {
	padding: 2px 0 3px 20px;
	border-left: 7px solid $aqua_cream;
	margin-bottom: 30px;
}

.title-block-right {
	padding: 2px 20px 3px 0;
	border-left: 0;
	border-right: 7px solid $aqua_cream;
	text-align: right;
}

.title-block {
	h1, h2, h3, h4 {
		margin-bottom: 0;
	}
	> span {
		display: block;
		margin-top: 4px;
		color: #555;
		font-weight: 300;
	}
	h1 + span {
		font-size: 22px;
	}
	h2 + span {
		font-size: 19px;
	}
	h3 + span {
		font-size: 17px;
	}
	h4 + span {
		font-size: 15px;
		margin-top: 3px;
	}
}

/* Heading Block - with Subtitle
-----------------------------------------------------------------*/

.heading-block {
	margin-bottom: 50px;
	h1, h2, h3, h4 {
		margin-bottom: 0;
		font-weight: 400;
		text-transform: uppercase;
		color: $aqua_cream;
		font-family: $condensed_font;
	}
}

.emphasis-title {
	h1, h2 {
		margin-bottom: 0;
		font-weight: 400;
		text-transform: uppercase;
		color: $aqua_cream;
		font-family: $condensed_font;
	}
	h1, h2 {
		font-weight: 400;
		letter-spacing: 0;
		text-transform: none;
	}
}

.heading-block {
	h2 {
		font-size: rem(72);
		line-height: rem(100);
		letter-spacing: 40px;

		&.sector-title {
			@include media($mobile_mlg) {
				margin-top: 30px;
			}
		}
	}
	h3 {
		font-size: 26px;
	}
	h4 {
		font-size: 20px;
		font-weight: 700;
	}
}

span.cordinates {
	font-size: rem(14);
	letter-spacing: 0;
	color: $concrete_lt !important;
}

.heading-block {
	> span:not(.before-heading) {
		display: block;
		font-weight: 300;
		color: #777;

		@include media($mobile_mlg) {
			margin-top: 10px;
		}
	}
	.before-heading {
		margin-bottom: 7px;
	}
	&.center > span, &.title-center > span {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
}

.center .heading-block > span {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

.heading-block {
	h1 + span {
		font-size: 24px;
	}
	h2 + span {
		font-size: 22px;
	}
	h3 + span, h4 + span {
		font-size: 20px;
	}
	~ p:not(.lead) {
		font-size: 15px;
	}
}

.center .heading-block:after {
	margin: 30px auto 0;
}

.heading-block {
	&.center:after, &.title-center:after {
		margin: 30px auto 0;
	}
	&.noborder:after, &.nobottomborder:after {
		display: none;
	}
	&.border-color:after {
		border-color: $aqua_cream;
	}
}

/* Emphasis Title
-----------------------------------------------------------------*/

.emphasis-title {
	margin: 0 0 50px;
	h1, h2 {
		font-size: 64px;
		letter-spacing: -2px;
	}
	h1 strong, h2 strong {
		font-weight: 700;
	}
}

/* Justify Border Title
-----------------------------------------------------------------*/

.fancy-title {
	position: relative;
	margin-bottom: 30px;
	h1, h2, h3, h4, h5, h6 {
		position: relative;
		display: inline-block;
		background-color: #FFF;
		padding-right: 15px;
		margin-bottom: 0;
	}
	h4, h5, h6 {
		padding-right: 10px;
	}
	&.title-double-border:before, &.title-border:before, &.title-border-color:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 0;
		border-top: 3px double #E5E5E5;
		left: auto;
		top: 46%;
		right: 0;
	}
	&.title-border:before {
		top: 49%;
		border-top: 1px solid #EEE;
	}
	&.title-border-color:before {
		top: 49%;
		border-top: 1px solid $aqua_cream;
		opacity: 0.6;
	}
}

/* Fancy Title - Center Align
-----------------------------------------------------------------*/

.title-center {
	text-align: center;
	h1, h2, h3 {
		padding: 0 15px;
	}
	h4, h5, h6 {
		padding: 0 10px;
	}
}

/* Fancy Title - Right Align
-----------------------------------------------------------------*/

.title-right {
	text-align: right;
	h1, h2, h3 {
		padding: 0 0 0 15px;
	}
	h4, h5, h6 {
		padding: 0 0 0 10px;
	}
}

/* Fancy Title - Bottom Short Border
-----------------------------------------------------------------*/

.fancy-title {
	&.title-bottom-border {
		h1, h2, h3, h4, h5, h6 {
			display: block;
			background: transparent;
			padding: 0 0 10px;
			border-bottom: 2px solid $aqua_cream;
		}
	}
	&.title-dotted-border {
		background: url('../images/icons/dotted.png') repeat-x center;
	}
}

/* Fancy Title - Bottom Short Border
-----------------------------------------------------------------*/

/* Sub Heading
-----------------------------------------------------------------*/

.before-heading {
	display: block;
	margin: 0 0 5px;
	font-size: 16px;
	font-family: 'Crete Round', serif;
	font-style: italic;
	font-weight: 400;
	color: #999;
}


/* ----------------------------------------------------------------
	Dropcaps & Highlights
-----------------------------------------------------------------*/


.dropcap {
	float: left;
	font-size: 42px;
	line-height: 1;
	margin: 0 5px 0 0;
	text-transform: uppercase;
}

.highlight {
	padding: 2px 5px;
	background-color: #444;
	color: #FFF;
	border-radius: 2px;
}
