/* ----------------------------------------------------------------
	Hamburger
-----------------------------------------------------------------*/

.hamburger {
	position: absolute;
	width: 60px;
	height: 60px;
	right: 0;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.device-sm .hamburger {
	display: none;
}

.hamburger {
	.patty {
		-webkit-transition-duration: 0.5s;
		transition-duration: 0.5s;
		position: absolute;
		height: 4px;
		width: 30px;
		top: 30px;
		background-color: #edf8fb;
		&:before {
			-webkit-transition-duration: 0.5s;
			transition-duration: 0.5s;
			position: absolute;
			width: 30px;
			height: 4px;
			background-color: #edf8fb;
			content: "";
			top: -10px;
		}
		&:after {
			-webkit-transition-duration: 0.5s;
			transition-duration: 0.5s;
			position: absolute;
			width: 30px;
			height: 4px;
			background-color: #edf8fb;
			content: "";
			top: 10px;
		}
	}
	&.openburger .patty {
		-webkit-transition-duration: 0.5s;
		transition-duration: 0.5s;
		background: transparent;
		&:before {
			-webkit-transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
			transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
		}
		&:after {
			-webkit-transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
			transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
		}
	}
	&:hover {
		cursor: pointer;
	}
}

/* ----------------------------------------------------------------
	Nav Overlay
-----------------------------------------------------------------*/
.overlaynav {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(34, 36, 39, 0.9);
	z-index: 300;
	nav {
		text-align: center;
		position: relative;
		top: 50%;
		height: 60%;
		font-size: 54px;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;
		display: inline-block;
		height: 100%;
		position: relative;
		li {
			display: block;
			height: 20%;
			height: calc(100% / 5);
			min-height: 54px;
			a {
				font-weight: 300;
				display: block;
				color: #edf8fb;
				text-transform: uppercase;
				-webkit-transition: color 0.3s ease-in;
				-moz-transition: color 0.3s ease-in;
				-o-transition: color 0.3s ease-in;
				-ms-transition: color 0.3s ease-in;
				transition: color 0.3s ease-in;
				&:hover, &:focus {
					color: #60d9eb;
				}
			}
		}
	}
}

.overlay-bash {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	-moz-transition: opacity 0.5s, visibility 0s 0.5s;
	-ms-transition: opacity 0.5s, visibility 0s 0.5s;
	-o-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
	&.open {
		opacity: 1;
		visibility: visible;
		-webkit-transition: opacity 0.5s;
		-moz-transition: opacity 0.5s;
		-ms-transition: opacity 0.5s;
		-o-transition: opacity 0.5s;
		transition: opacity 0.5s;
	}
	nav {
		-webkit-perspective: 1200px;
		perspective: 1200px;
		ul {
			opacity: 0.4;
			-webkit-transform: translateY(-25%) rotateX(35deg);
			transform: translateY(-25%) rotateX(35deg);
			-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
			transition: transform 0.5s, opacity 0.5s;
		}
	}
	&.open nav ul {
		opacity: 1;
		-webkit-transform: rotateX(0deg);
		-moz-transform: rotateX(0deg);
		-ms-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}
	&.close nav ul {
		padding-top: 7px;
		-webkit-transform: translateY(25%) rotateX(-35deg);
		-moz-transform: translateY(25%) rotateX(-35deg);
		-ms-transform: translateY(25%) rotateX(-35deg);
		transform: translateY(25%) rotateX(-35deg);
	}
}

@media screen and (max-height: 30.5em) {
	.overlay {
		nav {
			height: 70%;
			font-size: 34px;
		}
		ul li {
			min-height: 34px;
		}
	}
}


/* ----------------------------------------------------------------
	Side Navigation
-----------------------------------------------------------------*/

.sidenav {
	width: 100%;
	padding: 0;
	background-color: #FFF;
	list-style-type: none !important;
	border-radius: 4px;
	> {
		li {
			> a {
				display: block;
				margin: 0 0 -1px;
				padding: 11px 14px;
				font-size: 14px;
				border: 1px solid #E5E5E5;
				border-right-width: 2px;
				color: #666;
			}
			&:first-child > a {
				border-radius: 4px 4px 0 0;
			}
			&:last-child > a {
				border-radius: 0 0 4px 4px;
				border-bottom-width: 2px;
			}
		}
		.ui-tabs-active > a {
			position: relative;
			z-index: 2;
			border-color: rgba(0, 0, 0, 0.1) !important;
			color: #FFF !important;
			font-weight: 600;
			background-color: #00deff !important;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
			&:hover {
				position: relative;
				z-index: 2;
				border-color: rgba(0, 0, 0, 0.1) !important;
				color: #FFF !important;
				font-weight: 600;
				background-color: #00deff !important;
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
			}
		}
	}
	li i {
		position: relative;
		top: 1px;
		margin-right: 6px;
		font-size: 14px;
		text-align: center;
		width: 16px;
	}
	.icon-chevron-right {
		float: right;
		margin-top: 2px;
		margin-right: -6px;
		opacity: .4;
		font-size: 12px !important;
	}
	> li > a:hover {
		background-color: #F5F5F5;
		color: #222;
	}
	a:hover .icon-chevron-right {
		opacity: .5;
	}
	.ui-tabs-active {
		.icon-chevron-right, a:hover .icon-chevron-right {
			opacity: 1;
		}
	}
}
