/* ----------------------------------------------------------------
	Owl Carousel CSS
-----------------------------------------------------------------*/

.owl-carousel {
	.animated {
		-webkit-animation-duration: 1000ms;
		animation-duration: 1000ms;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		-webkit-animation-name: fadeOut;
		animation-name: fadeOut;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}


@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}


.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

.owl-carousel {
	display: none;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0px, 0, 0);
		cursor: move;
		cursor: -webkit-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab;
	}
	.owl-controls {
		.owl-dot {
			cursor: pointer;
			cursor: hand;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.owl-nav {
			.owl-next, .owl-prev {
				cursor: pointer;
				cursor: hand;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
		}
	}
	&.owl-loaded {
		display: block;
	}
	&.owl-loading {
		display: block;
		min-height: 100px;
		background: url('../images/ball-triangle.svg') no-repeat center center;
	}
	&.owl-hidden {
		opacity: 0;
	}
	.owl-refresh .owl-item {
		display: none;
	}
	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		img {
			display: block;
			width: 100%;
			-webkit-transform-style: preserve-3d;
		}
	}
}

#slider .owl-carousel .owl-item img {
	-webkit-backface-visibility: hidden;
}

.owl-carousel {
	&.owl-text-select-on .owl-item {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}
	.owl-grab {
		cursor: move;
		cursor: -webkit-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab;
	}
	&.owl-rtl {
		direction: rtl;
		.owl-item {
			float: right;
		}
	}
}

.no-js .owl-carousel {
	display: block;
}

.owl-carousel {
	.owl-item {
		.owl-lazy {
			opacity: 0;
			-webkit-transition: opacity 400ms ease;
			-o-transition: opacity 400ms ease;
			transition: opacity 400ms ease;
		}
		img {
			transform-style: preserve-3d;
		}
	}
	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #111;
	}
	.owl-video-play-icon {
		position: absolute;
		height: 64px;
		width: 64px;
		left: 50%;
		top: 50%;
		margin-left: -32px;
		margin-top: -32px;
		background: url("../images/icons/play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		-webkit-transition: scale 100ms ease;
		-o-transition: scale 100ms ease;
		transition: scale 100ms ease;
		&:hover {
			-webkit-transition: scale(1.3, 1.3);
			-o-transition: scale(1.3, 1.3);
			transition: scale(1.3, 1.3);
		}
	}
	.owl-video-playing {
		.owl-video-play-icon, .owl-video-tn {
			display: none;
		}
	}
	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		-moz-background-size: contain;
		-o-background-size: contain;
		background-size: contain;
		-webkit-transition: opacity 400ms ease;
		-o-transition: opacity 400ms ease;
		transition: opacity 400ms ease;
	}
	.owl-video-frame {
		position: relative;
		z-index: 1;
	}
}

/* Owl Carousel - Controls
-----------------------------------------------------------------*/

.owl-theme .owl-controls {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	.owl-nav [class*=owl-] {
		position: absolute;
		top: 50%;
		margin-top: -18px;
		left: -36px;
		zoom: 1;
		width: 36px;
		height: 36px;
		line-height: 32px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		color: #666;
		background-color: #FFF;
		font-size: 18px;
		border-radius: 50%;
		opacity: 0;
		-webkit-transition: all .3s ease;
		-o-transition: all .3s ease;
		transition: all .3s ease;
	}
	&.with-carousel-dots .owl-nav [class*=owl-] {
		margin-top: -39px;
	}
}

/* Owl Carousel - Controls - Arrows
-----------------------------------------------------------------*/

.owl-carousel-full .owl-controls {
	.owl-nav [class*=owl-] {
		margin-top: -30px;
		left: 0 !important;
		height: 60px;
		line-height: 60px;
		border: none;
		color: #EEE;
		background-color: rgba(0, 0, 0, 0.4);
		font-size: 28px;
		border-radius: 0 3px 3px 0;
	}
	&.with-carousel-dots .owl-nav [class*=owl-] {
		margin-top: -46px;
	}
}

.owl-theme .owl-controls .owl-nav .owl-next {
	left: auto;
	right: -36px;
}

.owl-carousel-full .owl-controls .owl-nav .owl-next {
	left: auto !important;
	right: 0 !important;
	border-radius: 3px 0 0 3px;
}

.owl-theme {
	&:hover .owl-controls .owl-nav {
		[class*=owl-] {
			opacity: 1;
			left: -18px;
		}
		.owl-next {
			left: auto;
			right: -18px;
		}
	}
	.owl-controls .owl-nav {
		[class*=owl-]:hover {
			background-color: #00deff !important;
			color: #FFF !important;
			text-decoration: none;
		}
		.disabled {
			display: none !important;
		}
	}
	.owl-dots .owl-dot {
		display: inline-block;
		zoom: 1;
		margin-top: 30px;
		*display: inline;
		span {
			display: block;
			width: 8px;
			height: 8px;
			margin: 0 4px;
			opacity: 0.5;
			border-radius: 50%;
			background-color: #00deff;
			-webkit-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;
		}
		&.active span, &:hover span {
			opacity: 1;
		}
	}
}

/* Owl Carousel - Controls - Dots
-----------------------------------------------------------------*/
