/* ----------------------------------------------------------------
		Responsive CSS
-----------------------------------------------------------------*/

@media (min-width: 2385px) {
	figure.effect-apollo p {
		position: absolute;
		// margin: 3em 3em 8em 3em;
	}
}

@media (min-width: 1750px) {
	figure.effect-apollo {
		max-height: 575px;
		width: auto;
		height: auto;
	}
}

@media (max-width: 1199px) {
	canvas {
		max-width: 100%;
		height: auto !important;
	}
	.promo {
		h3 {
			font-size: 20px;
		}
		> {
			span, .container > span {
				font-size: 15px;
			}
		}
	}
	.promo-uppercase {
		h3 {
			font-size: 19px;
		}
		> {
			span, .container > span {
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 1100px) {
	/* Make Header with a background */
	body.stretched #header.full-header .container {
		padding: 11px 30px 11px 30px;
		background: #1a1c1e;
	}
	.hamburger {
		top: 8px;
	}
	article.effect-apollo {
		h2 {
			font-size: 20px;
		}
		p {
			font-size: 14px !important;
		}
	}
}

@media (min-width: 768px) and (max-width: 1059px) {
	figure.effect-apollo {
		figcaption {
			padding: 1em;
		}
		h2 {
			line-height: 30px;
		}
		p {
			font-size: 12px;
			letter-spacing: 0;
			// margin: 1em;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#wrapper {
		width: 1000px;
	}
	#header.transparent-header.floating-header {
		.container {
			width: 1030px;
			padding: 0 30px;
		}
		&.sticky-header .container {
			width: 970px;
			padding: 0 15px;
		}
	}
	#primary-menu {
		&.sub-title ul > li > a {
			padding-left: 15px;
			padding-right: 15px;
		}
		ul li.mega-menu .mega-menu-content {
			width: 940px;
		}
	}
	.floating-header {
		&.sticky-header #primary-menu ul li.mega-menu .mega-menu-content {
			width: 940px;
		}
		#primary-menu ul li.mega-menu .mega-menu-content {
			width: 1030px;
		}
	}
	.ei-slider {
		height: 450px;
	}
	.postcontent {
		width: 690px;
		&.bothsidebar {
			width: 440px;
		}
	}
	.sidebar {
		width: 210px;
		.widget iframe {
			width: 210px !important;
		}
	}
	.team.team-list .team-image {
		width: 200px;
	}
	.testi-image {
		display: block;
		width: 36px;
		height: 36px;
		a, img {
			display: block;
			width: 36px;
			height: 36px;
		}
		i {
			display: block;
			width: 36px;
			height: 36px;
			line-height: 36px;
			font-size: 20px;
		}
	}
		/* ----------------------------------------------------------------
			Portfolio
		-----------------------------------------------------------------*/
	/* Portfolio - No Margin
	-----------------------------------------------------------------*/
	.portfolio-nomargin .portfolio-item {
		width: 234px;
		.portfolio-image {
			width: 234px;
			height: 176px;
			a, img {
				width: 234px;
				height: 176px;
			}
		}
	}
	/* Portfolio - Sidebar
	-----------------------------------------------------------------*/
	.postcontent {
		.portfolio-item {
			width: 163px;
			.portfolio-image {
				width: 163px;
				height: 123px;
				a, img {
					width: 163px;
					height: 123px;
				}
			}
		}
		.portfolio-nomargin .portfolio-item, .portfolio-5.portfolio-nomargin .portfolio-item {
			width: 171px;
		}
		.portfolio-nomargin .portfolio-item .portfolio-image {
			width: 171px;
			height: 129px;
			a, img {
				width: 171px;
				height: 129px;
			}
		}
		.portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image {
			width: 171px;
			height: 129px;
			a, img {
				width: 171px;
				height: 129px;
			}
		}
	}
	/* Portfolio - Sidebar - No Margin
	-----------------------------------------------------------------*/
	.bothsidebar .portfolio-item {
		width: 219px;
		.portfolio-image {
			width: 219px;
			height: 164px;
			a, img {
				width: 219px;
				height: 164px;
			}
		}
	}
	/* Portfolio - Items - 3 Columns
	-----------------------------------------------------------------*/
	.portfolio-3 {
		.portfolio-item {
			width: 303px;
			.portfolio-image {
				width: 303px;
				height: 227px;
				a, img {
					width: 303px;
					height: 227px;
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 312px;
			.portfolio-image {
				width: 312px;
				height: 234px;
				a, img {
					width: 312px;
					height: 234px;
				}
			}
		}
	}
	/* Portfolio - Items - 3 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Sidebar - Items - 3 Columns
	-----------------------------------------------------------------*/
	.postcontent .portfolio-3 {
		.portfolio-item {
			width: 219px;
			.portfolio-image {
				width: 219px;
				height: 164px;
				a, img {
					width: 219px;
					height: 164px;
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 229px;
			.portfolio-image {
				width: 229px;
				height: 172px;
				a, img {
					width: 229px;
					height: 172px;
				}
			}
		}
	}
	/* Portfolio - Sidebar - Items - 3 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 2 Columns
	-----------------------------------------------------------------*/
	.portfolio-2 {
		.portfolio-item {
			width: 460px;
			.portfolio-image {
				width: 460px;
				height: 343px;
				a, img {
					width: 460px;
					height: 343px;
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 469px;
			.portfolio-image {
				width: 469px;
				height: 352px;
				a, img {
					width: 469px;
					height: 352px;
				}
			}
		}
	}
	/* Portfolio - Items - 2 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Sidebar - Items - 2 Columns
	-----------------------------------------------------------------*/
	.postcontent .portfolio-2 {
		.portfolio-item {
			width: 335px;
			.portfolio-image {
				width: 335px;
				height: 251px;
				a, img {
					width: 335px;
					height: 251px;
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 344px;
			.portfolio-image {
				width: 344px;
				height: 258px;
				a, img {
					width: 344px;
					height: 258px;
				}
			}
		}
	}
	/* Portfolio - Sidebar - Items - 2 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Both Sidebars - Items - 2 Columns
	-----------------------------------------------------------------*/
	.bothsidebar {
		#portfolio.portfolio-3 {
			margin: 0 -20px -20px 0;
		}
		.portfolio-2 .portfolio-item, .portfolio-3 .portfolio-item {
			width: 210px;
			margin: 0 20px 20px 0;
		}
		.portfolio-2 .portfolio-item .portfolio-image {
			width: 210px;
			height: 158px;
			a, img {
				width: 210px;
				height: 158px;
			}
		}
		.portfolio-3 .portfolio-item .portfolio-image {
			width: 210px;
			height: 158px;
			a, img {
				width: 210px;
				height: 158px;
			}
		}
		.portfolio-2.portfolio-nomargin .portfolio-item, .portfolio-3.portfolio-nomargin .portfolio-item {
			width: 219px;
		}
		.portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image {
			width: 219px;
			height: 164px;
			a, img {
				width: 219px;
				height: 164px;
			}
		}
		.portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image {
			width: 219px;
			height: 164px;
			a, img {
				width: 219px;
				height: 164px;
			}
		}
	}
	/* Portfolio - Both Sidebars - Items - 2 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 5 Columns
	-----------------------------------------------------------------*/
	.portfolio-5 {
		.portfolio-item {
			width: 180px;
			.portfolio-image {
				width: 180px;
				height: 135px;
				a, img {
					width: 180px;
					height: 135px;
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 187px;
			.portfolio-image {
				width: 187px;
				height: 140px;
				a, img {
					width: 187px;
					height: 140px;
				}
			}
		}
	}
	/* Portfolio - Items - 5 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 6 Columns
	-----------------------------------------------------------------*/
	.portfolio-6 {
		.portfolio-item {
			width: 151px;
			.portfolio-image {
				width: 151px;
				height: 113px;
				a, img {
					width: 151px;
					height: 113px;
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 155px;
			.portfolio-image {
				width: 155px;
				height: 116px;
				a, img {
					width: 155px;
					height: 116px;
				}
			}
		}
	}
	/* Portfolio - Items - 6 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 1 Column
	-----------------------------------------------------------------*/
	.portfolio-1 {
		.portfolio-item .portfolio-image {
			width: 560px;
			height: 311px;
			a, img {
				width: 560px;
				height: 311px;
			}
		}
		.portfolio-desc {
			width: 340px;
		}
	}
	.bothsidebar #portfolio-shuffle {
		display: none;
	}
	.postcontent .portfolio-1 {
		.iconlist {
			display: none;
		}
		.portfolio-item .portfolio-image {
			width: 400px;
			height: 222px;
			a, img {
				width: 400px;
				height: 222px;
			}
		}
		.portfolio-desc {
			width: 250px;
			padding: 0;
		}
	}
	.bothsidebar {
		.portfolio-1 {
			.portfolio-item {
				width: 100% !important;
				.portfolio-image, .portfolio-desc {
					float: none;
					margin: 0 !important;
				}
				.portfolio-image {
					margin-bottom: 20px !important;
					width: 100%;
					height: auto;
					a, img {
						width: 100%;
						height: auto;
					}
				}
			}
			.portfolio-desc {
				width: 100%;
				padding: 0;
			}
		}
		.entry-title h2 {
			font-size: 19px;
			font-weight: 600;
		}
		.entry-meta li {
			margin-right: 10px;
		}
	}
	/* Blog - Small
	-----------------------------------------------------------------*/
	.postcontent .small-thumbs .entry-image {
		width: 200px;
	}
	.bothsidebar .small-thumbs .entry-image {
		width: 120px;
	}
	/* Blog - Grid
	-----------------------------------------------------------------*/
	.post-grid {
		.entry {
			width: 214px;
		}
		.entry-image {
			height: 120px;
			img {
				height: 120px;
			}
		}
		&:not(.post-masonry) .entry-image iframe {
			height: 120px;
		}
		&.grid-3 {
			.entry {
				width: 293px;
			}
			.entry-image {
				height: 164px;
				img {
					height: 164px;
				}
			}
			&:not(.post-masonry) .entry-image iframe {
				height: 164px;
			}
		}
	}
	/* Blog - Grid - 3 Columns
	-----------------------------------------------------------------*/
	/* Blog - Grid - 3 Columns - Sidebar
	-----------------------------------------------------------------*/
	.postcontent .post-grid.grid-3 {
		.entry {
			width: 213px;
		}
		.entry-image {
			height: 120px;
			img {
				height: 120px;
			}
		}
		&:not(.post-masonry) .entry-image iframe {
			height: 120px;
		}
	}
	/* Blog - Grid - 2 Columns
	-----------------------------------------------------------------*/
	.post-grid.grid-2 {
		.entry {
			width: 450px;
		}
		.entry-image {
			height: 253px;
			img {
				height: 253px;
			}
		}
		&:not(.post-masonry) .entry-image iframe {
			height: 253px;
		}
	}
	/* Blog - Grid - 2 Columns - Sidebar
	-----------------------------------------------------------------*/
	.postcontent .post-grid.grid-2 {
		.entry {
			width: 325px;
		}
		.entry-image {
			height: 182px;
			img {
				height: 182px;
			}
		}
		&:not(.post-masonry) .entry-image iframe {
			height: 182px;
		}
	}
	/* Blog - Grid - 2 Columns - Both Sidebar
	-----------------------------------------------------------------*/
	.bothsidebar .post-grid.grid-2 {
		.entry {
			width: 200px;
		}
		.entry-image {
			height: 113px;
			img {
				height: 113px;
			}
		}
		&:not(.post-masonry) .entry-image iframe {
			height: 113px;
		}
	}
	.post-timeline .entry {
		width: 400px !important;
		&.entry-date-section {
			width: 100% !important;
		}
	}
	/* Blog - Medium Post List
	-----------------------------------------------------------------*/
	.mpost .entry-image {
		width: 120px;
		height: 90px;
		a, img, i {
			width: 120px;
			height: 90px;
		}
		a i {
			line-height: 90px;
		}
	}
	.col_one_third .landing-wide-form {
		.heading-block {
			h2 {
				font-size: 32px;
			}
			span {
				font-size: 17px;
			}
		}
		padding: 36px 30px;
	}
	.product {
		width: 220px;
	}
	.product-image {
		height: 293px;
	}
	/* Shop - 3 Columns
	-----------------------------------------------------------------*/
	.product-3 {
		.product {
			width: 293px;
		}
		.product-image {
			height: 391px;
		}
	}
	/* Shop - 3 Columns - Sidebar
	-----------------------------------------------------------------*/
	.postcontent {
		.product-3 {
			.product {
				width: 213px;
			}
			.product-image {
				height: 284px;
			}
		}
		.product-2 {
			.product {
				width: 325px;
			}
			.product-image {
				height: 434px;
			}
		}
	}
	/* Shop - 2 Columns - Sidebar
	-----------------------------------------------------------------*/
	/* Shop - 2 Columns - Both Sidebar
	-----------------------------------------------------------------*/
	.bothsidebar {
		.product-2 {
			.product {
				width: 200px;
			}
			.product-image {
				height: 267px;
			}
		}
		.product-1 .product-image {
			width: 200px;
			height: 267px;
		}
	}
	/* Shop - 1 Column - Both Sidebar
	-----------------------------------------------------------------*/
	.product-title h3 {
		font-size: 18px;
	}
	.landing-video {
		width: 465px;
		height: 262px;
		margin: 19px 0 0 75px;
	}
	.panel .portfolio-meta li {
		padding-left: 22px;
		span {
			display: block;
			margin-left: -21px;
		}
	}
	.portfolio-single-image-full {
		&.portfolio-single-thumbs, .swiper-container {
			height: 400px;
		}
	}
	.bnews-slider {
		width: 817px;
	}
	.landing-form-overlay {
		bottom: -142px;
	}
}

@media (max-width: 991px) {
	#wrapper {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}
	.container.vertical-middle {
		width: 100% !important;
	}
	.full-screen:not(.force-full-screen) .container.vertical-middle {
		width: 750px !important;
	}
	.vertical-middle + .video-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.postcontent {
		width: 100%;
		margin-right: 0;
		float: none !important;
		margin-bottom: 40px !important;
		padding-bottom: 40px;
		border-bottom: 1px solid #EEE;
		&.bothsidebar {
			width: 100%;
			margin-right: 0;
			float: none !important;
			margin-bottom: 40px !important;
			padding-bottom: 40px;
			border-bottom: 1px solid #EEE;
		}
	}
	.sidebar {
		width: 100%;
		margin-right: 0;
		float: none !important;
		margin-bottom: 40px !important;
		padding-bottom: 40px;
		border-bottom: 1px solid #EEE;
		&.col_last {
			float: none;
			margin-bottom: 0 !important;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}
	#primary-menu {
		display: block;
		float: none;
		height: auto !important;
	}
	#primary-menu-trigger {
		opacity: 1;
		pointer-events: auto;
		top: 25px;
		margin-top: 0;
		left: 0;
		z-index: 1;
	}
	#primary-menu {
		.container #primary-menu-trigger {
			top: 5px;
			left: 0;
		}
		> {
			ul {
				display: none;
				float: none !important;
				border: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
				-webkit-transition: none;
				-o-transition: none;
				transition: none;
			}
			div > ul {
				display: none;
				float: none !important;
				border: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
				-webkit-transition: none;
				-o-transition: none;
				transition: none;
				margin-top: 60px !important;
			}
		}
	}
	body {
		&:not(.top-search-open) #primary-menu-trigger {
			opacity: 1;
			-webkit-transition: opacity .2s .2s ease, top .4s ease;
			-o-transition: opacity .2s .2s ease, top .4s ease;
			transition: opacity .2s .2s ease, top .4s ease;
		}
		&.top-search-open #primary-menu-trigger {
			opacity: 0;
		}
		&.side-push-panel #side-panel-trigger-close a {
			display: block;
			position: absolute;
			z-index: 12;
			top: 0;
			left: auto;
			right: 0;
			width: 40px;
			height: 40px;
			font-size: 18px;
			line-height: 40px;
			color: #444;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 0 0 0 2px;
		}
	}
	#primary-menu {
		ul {
			height: auto !important;
		}
		&.style-5 > ul {
			padding-right: 0;
			margin-right: 0;
			border-right: 0;
		}
		ul li {
			float: none;
			margin: 0 !important;
			text-align: left !important;
			border-top: 1px solid #EEE;
			&:first-child {
				border-top: 0;
			}
			a {
				height: 50px !important;
				line-height: 50px !important;
				padding: 0 5px !important;
				border: none !important;
				margin: 0 !important;
			}
		}
		&.style-2 > div > ul > li > a {
			height: 50px !important;
			line-height: 50px !important;
			padding: 0 5px !important;
			border: none !important;
			margin: 0 !important;
		}
		&.style-5 > ul > li > a > div {
			padding: 0;
			line-height: 50px;
		}
		&.sub-title {
			> ul > li, &.style-2 > div > ul > li {
				background: none !important;
				margin-left: 0;
			}
			ul li {
				a {
					text-transform: none;
					font-size: 14px;
				}
				span {
					display: none !important;
				}
			}
			> {
				ul > li {
					&:hover > a, &.current > a {
						background-color: transparent !important;
						color: #444;
						text-shadow: none;
					}
				}
				div > ul > li {
					&:hover > a, &.current > a {
						background-color: transparent !important;
						color: #444;
						text-shadow: none;
					}
				}
			}
		}
		&.style-3 > ul > li {
			&:hover > a, &.current > a {
				background-color: transparent !important;
				color: #444;
				text-shadow: none;
			}
		}
		ul li i, &.style-2 > div > ul > li i, &.sub-title ul li i, &.style-3 > ul > li > a i {
			line-height: 49px;
		}
		&.style-5 > ul > li > a i {
			line-height: 49px;
			display: inline-block;
			top: 1px;
			width: 14px;
			margin: 0 6px 0 0;
			font-size: 14px !important;
		}
		> {
			ul > li.sub-menu > a, .container > ul > li.sub-menu > a {
				background-image: url("../images/icons/submenu.png");
				background-position: right center;
				background-repeat: no-repeat;
			}
		}
		ul {
			ul {
				position: relative;
				width: auto;
				background-color: transparent !important;
				box-shadow: none;
				border: 0;
				border-top: 1px solid #EEE;
				z-index: 1;
				top: 0 !important;
				padding-left: 15px;
			}
			li .mega-menu-content {
				position: relative;
				width: auto;
				background-color: transparent !important;
				box-shadow: none;
				border: 0;
				border-top: 1px solid #EEE;
				z-index: 1;
				top: 0 !important;
				padding-left: 15px;
				padding-left: 0;
			}
			ul {
				ul {
					top: 0 !important;
					left: 0;
				}
				&.menu-pos-invert {
					left: 0;
					right: 0;
				}
			}
			li .mega-menu-content.menu-pos-invert {
				left: 0;
				right: 0;
			}
			ul {
				ul.menu-pos-invert {
					right: 0;
				}
				li {
					float: none;
					margin: 0;
					border-top: 1px solid #EEE;
				}
				a {
					height: 44px !important;
					line-height: 44px !important;
					padding-left: 5px !important;
					font-size: 13px !important;
					text-transform: none;
					font-weight: 400;
					i {
						line-height: 43px;
					}
				}
				li:hover > a {
					background-color: transparent !important;
					font-weight: 600;
					padding-left: 5px;
					color: #1ABC9C !important;
				}
			}
			li {
				.mega-menu-content, &.mega-menu-small .mega-menu-content, &.mega-menu .mega-menu-content {
					width: auto;
					margin: 0;
					top: 0;
				}
			}
		}
	}
		/*#primary-menu ul.windows-mobile-menu li.sub-menu a {
			position: relative;
			background-image: none !important;
		}

		#primary-menu ul.windows-mobile-menu li.sub-menu a div:after {
			content: '\e7a7';
			position: absolute;
			cursor: pointer;
			top: 15px;
			left: auto;
			right: 0;
			z-index: 1;
			width: 20px;
			height: 20px;
			line-height: 20px;
			text-align: center;
			border: 1px solid #DDD;
			border-radius: 50%;
		}*/
	.floating-header {
		&.sticky-header #primary-menu ul li.mega-menu .mega-menu-content, #primary-menu ul li.mega-menu .mega-menu-content {
			width: auto;
			margin: 0;
			top: 0;
		}
	}
	.container-fullwidth #primary-menu ul li.mega-menu .mega-menu-content, #header.full-header #primary-menu ul li.mega-menu .mega-menu-content {
		width: auto !important;
		margin: 0 !important;
	}
	#primary-menu ul {
		li {
			&.mega-menu .mega-menu-content.style-2 {
				padding: 0;
			}
			.mega-menu-content {
				ul {
					display: block;
					position: relative;
					top: 0;
					min-width: inherit;
					display: block !important;
					opacity: 1 !important;
					top: 0;
					left: 0;
					ul {
						border-top: 1px solid #EEE;
					}
				}
				> ul {
					float: none;
				}
				&.col-2 > ul, &.col-3 > ul, &.col-4 > ul, &.col-5 > ul {
					width: auto;
					margin: 0;
					border-left: 0;
					border-top: 1px solid #EEE;
				}
				> ul:first-child {
					border: 0;
				}
				ul {
					a {
						height: 40px;
						line-height: 40px;
					}
					width: 100%;
					border: 0;
					box-shadow: none;
					background-color: transparent;
				}
				&.style-2 > ul {
					padding: 0 0 0 15px;
					> li.mega-menu-title {
						margin: 0;
						> a {
							font-size: 13px;
							font-weight: 600;
							text-transform: none !important;
							margin: 0;
							padding: 0 0 0 5px;
							color: #444 !important;
							height: 44px !important;
							line-height: 44px !important;
						}
						&:hover > a {
							background-color: transparent;
							font-weight: 600;
							padding: 0 0 0 5px;
							color: #1ABC9C !important;
						}
						> a:hover {
							color: #1ABC9C !important;
						}
					}
				}
			}
		}
		ul i {
			line-height: inherit;
		}
		li .mega-menu-content.style-2 {
			li {
				border-top: 1px solid #EEE;
				&:first-child {
					border-top: 0;
				}
			}
			ul a {
				padding-left: 5px;
				height: 44px !important;
				line-height: 44px !important;
				&:hover {
					padding-left: 5px;
				}
			}
		}
	}
	#top-search {
		margin: 0 !important;
		a {
			position: absolute;
			top: 0;
			left: auto;
			right: 15px;
			margin: 40px 0;
			-webkit-transition: margin .4s ease;
			-o-transition: margin .4s ease;
			transition: margin .4s ease;
		}
	}
	#top-cart, #side-panel-trigger {
		position: absolute;
		top: 0;
		left: auto;
		right: 15px;
		margin: 40px 0;
		-webkit-transition: margin .4s ease;
		-o-transition: margin .4s ease;
		transition: margin .4s ease;
	}
	#primary-menu > div > {
		#top-search a, #top-cart, #side-panel-trigger {
			margin: 20px 0 !important;
		}
	}
	#header.sticky-style-3 #top-cart .top-cart-content {
		top: 40px;
	}
	#top-search {
		a {
			right: 43px;
		}
		form {
			height: 100px !important;
		}
	}
	#primary-menu > div > #top-search form {
		height: 60px !important;
	}
	#top-search form input {
		font-size: 24px;
	}
	#side-panel-trigger {
		right: 71px;
	}
	#page-menu .container {
		position: relative;
	}
	#page-submenu-trigger {
		opacity: 1;
		pointer-events: auto;
		color: #FFF;
	}
	#page-menu {
		&.pagemenu-active #page-submenu-trigger {
			background-color: rgba(0, 0, 0, 0.2);
		}
		nav {
			display: none;
			position: absolute;
			float: none;
			width: 200px;
			top: 43px;
			left: auto;
			right: 15px;
			height: auto;
			background-color: #1ABC9C;
			z-index: 11;
		}
		&.pagemenu-active nav {
			display: block;
		}
		nav {
			ul {
				height: auto;
				background-color: rgba(0, 0, 0, 0.2);
			}
			li {
				float: none;
				a {
					height: 40px;
					line-height: 40px;
					padding: 0 15px;
				}
			}
		}
	}
	/* Responsive Sticky Header
	-----------------------------------------------------------------*/
	.sticky-responsive-menu {
		#header {
			min-height: 100px;
			-webkit-transition: min-height .4s ease;
			-o-transition: min-height .4s ease;
			transition: min-height .4s ease;
			&.sticky-style-2, &.sticky-style-3 {
				min-height: 160px;
			}
		}
		&.device-touch {
			#header, #header-wrap {
				-webkit-transition: none !important;
				-o-transition: none !important;
				transition: none !important;
			}
			#logo {
				-webkit-transition: none !important;
				-o-transition: none !important;
				transition: none !important;
				img {
					-webkit-transition: none !important;
					-o-transition: none !important;
					transition: none !important;
				}
			}
			#top-search a, #primary-menu-trigger, #top-cart, #side-panel-trigger {
				-webkit-transition: none !important;
				-o-transition: none !important;
				transition: none !important;
			}
		}
	}
	#header.responsive-sticky-header:not(.sticky-style-2):not(.sticky-style-3) {
		min-height: 60px;
	}
	.responsive-sticky-header {
		#header-wrap {
			z-index: 199;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background-color: #FFF;
			border-bottom: 1px solid #EEE;
			-webkit-backface-visibility: hidden;
		}
		#logo {
			height: 60px;
			img {
				height: 60px;
			}
		}
		#primary-menu-trigger {
			top: 5px;
		}
		#primary-menu > {
			ul, .container > ul {
				max-height: 300px;
				overflow-y: scroll;
				margin-bottom: 20px;
			}
			ul, .container > ul {
				margin-bottom: 20px !important;
			}
		}
		#top-search a, #top-cart, #side-panel-trigger {
			margin: 20px 0;
		}
		#top-search form {
			height: 60px !important;
		}
		#top-cart .top-cart-content {
			top: 40px;
		}
	}
	.ei-slider {
		height: 350px;
	}
	.ei-title h3 {
		display: none;
	}
	/* Portfolio - Items
	-----------------------------------------------------------------*/
	#portfolio {
		&:not(.portfolio-1) {
			margin: 0 -12px -12px 0 !important;
			.portfolio-item {
				width: 232px;
				margin: 0 12px 12px 0;
				.portfolio-image {
					width: 232px;
					height: 175px;
					a, img {
						width: 232px;
						height: 175px;
					}
				}
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 239px;
			.portfolio-image {
				width: 239px;
				height: 178px;
				a, img {
					width: 239px;
					height: 178px;
				}
			}
		}
		&.portfolio-2 {
			.portfolio-item {
				width: 354px;
				.portfolio-image {
					width: 354px;
					height: 264px;
					a, img {
						width: 354px;
						height: 264px;
					}
				}
			}
			&.portfolio-nomargin .portfolio-item {
				width: 359px;
				.portfolio-image {
					width: 359px;
					height: 269px;
					a, img {
						width: 359px;
						height: 269px;
					}
				}
			}
		}
		&.portfolio-1 {
			.portfolio-item .portfolio-image {
				float: none !important;
				margin: 0 !important;
				margin-bottom: 10px !important;
				width: 100%;
				height: auto;
				a, img {
					width: 100%;
					height: auto;
				}
			}
			.portfolio-desc {
				width: 100%;
			}
			&.portfolio-fullwidth {
				margin: 0 0 -60px 0;
				.portfolio-item {
					margin-bottom: 60px;
					padding-bottom: 0;
					border-bottom: 0;
					overflow: hidden;
					.portfolio-image {
						float: none;
						margin: 0 0 20px 0 !important;
						width: 100%;
						height: auto;
						a {
							width: 100%;
							height: auto;
						}
						img {
							width: 100%;
							height: auto;
							-webkit-transition: none;
							-o-transition: none;
							transition: none;
						}
					}
					&:hover .portfolio-image img, &.alt:hover .portfolio-image img {
						-webkit-transform: translateX(0);
						-moz-transform: translateX(0);
						-ms-transform: translateX(0);
						-o-transform: translateX(0);
						transform: translateX(0);
					}
				}
				.portfolio-desc, .alt .portfolio-desc {
					position: relative;
					width: 100%;
					height: auto;
					padding: 0;
					background-color: transparent;
					left: 0;
					right: 0;
					-webkit-transition: none;
					-o-transition: none;
					transition: none;
				}
				.portfolio-overlay a {
					left: 50%;
				}
				.alt .portfolio-overlay a {
					left: auto;
					right: 50%;
				}
			}
		}
	}
	/* Portfolio - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 2 Columns
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 2 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 1 Column
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 1 Column - Full Width
	-----------------------------------------------------------------*/
	.device-touch #portfolio.portfolio-parallax {
		.portfolio-item .portfolio-desc, .portfolio-overlay {
			opacity: 1;
		}
	}
	#portfolio {
		&.portfolio-nomargin, &.portfolio-full {
			margin: 0 !important;
		}
	}
	.shop-quick-view-ajax, .portfolio-ajax-modal {
		width: 90% !important;
	}
	.small-thumbs .entry-title h2, .ievent .entry-title h2 {
		font-size: 18px;
	}
	.entry-meta li {
		font-size: 12px;
		margin: 0 10px 10px 0;
	}
	/* Blog - Grid
	-----------------------------------------------------------------*/
	.post-grid {
		margin-right: -20px;
		.entry {
			width: 226px;
			margin-right: 20px;
		}
		.entry-image {
			height: 127px;
			img {
				height: 127px;
			}
		}
		&:not(.post-masonry) .entry-image iframe {
			height: 127px;
		}
		&.grid-2, &.grid-3 {
			margin-right: -30px;
		}
		&.grid-2 .entry {
			width: 345px;
			margin-right: 30px;
		}
		&.grid-3 {
			.entry {
				width: 345px;
				margin-right: 30px;
			}
			.entry-image {
				height: 193px;
				img {
					height: 193px;
				}
			}
			&:not(.post-masonry) .entry-image iframe {
				height: 193px;
			}
		}
		&.grid-2 {
			.entry-image {
				height: 193px;
				img {
					height: 193px;
				}
			}
			&:not(.post-masonry) .entry-image iframe {
				height: 193px;
			}
		}
	}
	/* Blog - Grid - 3 Columns
	-----------------------------------------------------------------*/
	.post-masonry-full {
		.entry {
			width: 33.30% !important;
		}
		&.grid-3 .entry {
			width: 50% !important;
		}
	}
	.post-timeline .entry {
		width: 290px !important;
		&.entry-date-section {
			width: 100% !important;
		}
	}
	.product {
		width: 226px;
	}
	.product-image {
		height: 301px;
	}
	.product:not(.product-2):not(.product-3) .product-title h3 {
		font-size: 18px;
	}
	/* Shop - 2 Columns - Sidebar
	-----------------------------------------------------------------*/
	#shop {
		&.product-2, &.product-3 {
			margin: 0 -20px -20px 0;
		}
		&.product-2 .product, &.product-3 .product {
			width: 350px;
			margin: 0 20px 20px 0;
		}
		&.product-2 .product-image, &.product-3 .product-image {
			height: 467px;
		}
	}
	.pricing {
		[class^=col-md-] .pricing-box.best-price {
			margin-top: 0;
		}
		&.pricing-5 .pricing-box.best-price {
			margin-top: 20px;
		}
	}
	.device-sm .pricing.pricing-5 {
		.pricing-title h3, .best-price .pricing-title h3 {
			font-size: 14px;
		}
		.pricing-title span {
			font-size: 12px;
		}
		.pricing-price {
			font-size: 48px;
			span {
				font-size: 20px;
				&.price-tenure {
					font-size: 12px;
				}
			}
		}
		.pricing-box.best-price .pricing-title {
			padding: 15px 0;
		}
	}
	.pricing-box.pricing-extended {
		.pricing-desc {
			position: relative;
			float: none;
			width: auto;
			padding: 25px;
		}
		.pricing-action-area {
			position: relative;
			float: none;
			width: auto;
			padding: 25px;
			height: auto;
			border-left: 0;
			border-top: 1px solid rgba(0, 0, 0, 0.05);
			border-radius: 0 0 3px 3px;
		}
	}
	.testimonials-grid.grid-3 li {
		width: 50%;
	}
	.section > .testimonial {
		padding: 0 40px !important;
		max-width: none;
	}
	.promo {
		&:not(.promo-full) {
			padding: 30px 20px !important;
		}
		&.promo-dark h3 {
			line-height: 1.6;
		}
		a.button {
			position: relative !important;
			top: 0 !important;
			margin: 20px 0 0 !important;
			left: 0 !important;
			right: 0;
		}
	}
	#contact-form-overlay-mini {
		float: none;
		width: auto;
		max-width: 380px;
		margin: 0 auto;
	}
	.button.button-full {
		font-size: 22px;
		i {
			top: 3px !important;
		}
	}
	.bnews-title {
		float: none;
		display: inline-block;
	}
	.bnews-slider {
		float: none;
		margin: 10px 0 0;
		width: 100%;
	}
	.wedding-head {
		font-size: 60px;
		.first-name span, .last-name span {
			font-size: 40px;
		}
		.and {
			font-size: 144px;
		}
	}
	.error404 {
		font-size: 180px;
	}
	.landing-form-overlay {
		position: absolute;
		width: 100%;
		max-width: 400px;
		right: auto;
		left: 50%;
		margin-left: -200px;
		bottom: 0;
	}
}

@media (max-width: 921px) {
	article.effect-apollo figcaption p {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.swiper_wrapper:not(.full-screen):not(.canvas-slider-grid) {
		height: 400px !important;
		.swiper-slide {
			height: 400px !important;
		}
	}
	.sidebar-widgets-wrap {
		position: relative;
		margin: 0 -38px -40px 0;
		.widget {
			display: inline-block;
			vertical-align: top;
			float: none;
			width: 340px;
			margin: 0 37px 40px 0;
			padding-top: 0;
			border-top: 0;
		}
	}
	.landing-video {
		width: 352px;
		height: 199px;
		margin: 14px 0 0 60px;
	}
}

@media (max-width: 767px) {
	body {
		padding: 0 !important;
	}
	#wrapper {
		width: 100% !important;
	}
	.container-fullwidth {
		margin: 0 auto;
		padding: 0 15px !important;
	}
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 20px;
	}
	h4 {
		font-size: 16px;
	}
	/* #gotoTop, .one-page-arrow {
		display: none !important;
	} */
	#top-bar {
		height: auto;
		.col_half {
			width: 100%;
			margin-bottom: 0 !important;
		}
	}
	.top-links {
		float: none !important;
		> ul {
			> li {
				float: none !important;
				display: inline-block;
				margin-right: -4px;
			}
			text-align: center;
		}
		ul {
			ul {
				text-align: left;
			}
			div.top-link-section {
				text-align: left;
				left: auto;
				right: 0;
				padding: 20px 15px;
			}
		}
	}
	#top-social, #slider:not(.swiper_wrapper) .slider-caption, .nivo-caption, .flex-caption {
		display: none !important;
	}
	.slider-caption {
		p, .button {
			display: none !important;
		}
	}
	.camera_caption, .ei-title, .slider-arrow-top-sm, .slider-arrow-bottom-sm {
		display: none !important;
	}
	.slider-caption h2 {
		margin-bottom: 0 !important;
	}
	.flex-control-nav li {
		opacity: 1 !important;
	}
	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs {
		height: 60px;
		li {
			display: block;
			width: 80px !important;
			height: 60px !important;
			img {
				display: block;
				width: 80px !important;
				height: 60px !important;
			}
		}
	}
	.postcontent {
		width: 100%;
		margin-right: 0;
		float: none;
		margin-bottom: 50px !important;
		&.bothsidebar {
			width: 100%;
			margin-right: 0;
			float: none;
			margin-bottom: 50px !important;
		}
	}
	.sidebar, .col_full, .col_half, .col_one_third, .col_two_third, .col_three_fourth, .col_one_fourth, .col_one_fifth, .col_two_fifth, .col_three_fifth, .col_four_fifth, .col_one_sixth, .col_five_sixth {
		width: 100%;
		margin-right: 0;
		float: none;
		margin-bottom: 50px !important;
	}
	.sidebar.col_last {
		margin-bottom: 0 !important;
	}
	form {
		.col_full, .col_half, .col_one_third, .col_two_third, .col_three_fourth, .col_one_fourth, .col_one_fifth, .col_two_fifth, .col_three_fifth, .col_four_fifth, .col_one_sixth, .col_five_sixth {
			margin-bottom: 25px !important;
		}
	}
	#page-title {
		padding: 20px 0 25px;
		text-align: center;
		h1 {
			line-height: 1.5;
			font-size: 24px;
		}
		span {
			display: none;
		}
		.breadcrumb {
			position: relative !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			margin: 15px 0 0 !important;
		}
	}
	.section-icons {
		width: 45px;
		height: 45px;
		margin: 0;
	}
	#page-title #portfolio-navigation {
		position: relative;
		top: 0;
		left: 0;
		margin: 6px auto 0;
	}
	#portfolio-ajax-title {
		#portfolio-navigation {
			position: relative;
			top: 0;
			left: 0;
			margin: 6px auto 0;
		}
		text-align: center;
	}
	/* Portfolio - Filter
	-----------------------------------------------------------------*/
	#portfolio-filter {
		border: none;
		border-radius: 0;
		margin-bottom: 20px;
		li {
			width: 33.33%;
			margin-bottom: 20px;
			a {
				text-align: center;
				border-left: none;
				padding: 12px 18px !important;
				border-radius: 2px !important;
				&:hover {
					color: #1ABC9C;
				}
			}
			&.activeFilter a {
				color: #FFF;
				margin: 0;
			}
		}
	}
	#portfolio-shuffle {
		display: none;
	}
	/* Blog
	-----------------------------------------------------------------*/
	.small-thumbs .entry-image, .ievent .entry-image {
		float: none !important;
		width: 100% !important;
		margin: 0 0 20px !important;
	}
	.entry-title h2, .post-grid .entry-title h2 {
		font-size: 18px !important;
		font-weight: 600;
	}
	.entry-meta li {
		font-size: 11px;
		margin: 0 10px 10px 0;
	}
	#posts {
		.entry-meta li i {
			display: none;
		}
		&.post-grid .entry-meta li i, .entry-meta li:last-child i {
			display: inline-block;
		}
	}
	.post-grid {
		margin-right: 0 !important;
		.entry {
			float: none !important;
			width: 100% !important;
			margin-right: 0 !important;
			border-right: 0 !important;
		}
	}
	.post-masonry-full.grid-3 .entry {
		float: none !important;
		width: 100% !important;
		margin-right: 0 !important;
		border-right: 0 !important;
	}
	.post-timeline .entry.entry-date-section {
		margin-left: 0;
	}
	.timeline-border, .post-timeline .entry-timeline {
		display: none !important;
	}
	.postcontent .post-timeline {
		padding-left: 0;
	}
	.post-masonry-full .entry {
		padding: 40px !important;
	}
	.post-grid .entry-image {
		height: auto !important;
		img {
			height: auto !important;
		}
	}
	.product-1 {
		.product {
			width: 100%;
		}
		.product-image {
			float: none !important;
			width: 100% !important;
			height: auto !important;
			margin: 0 0 20px !important;
		}
		.product-title h3 {
			font-size: 19px;
		}
		p {
			display: none;
		}
	}
	.pricing-box.best-price {
		margin-top: 0;
	}
	.pricing.pricing-5 .pricing-box {
		float: none;
		width: 100%;
	}
	.pricing-box.pricing-extended .pricing-features li {
		width: auto;
		float: none;
	}
	table.cart .quantity {
		display: block;
		.qty, .plus, .minus {
			float: none;
			width: 100%;
		}
		.qty {
			border: 0;
			border-top: 1px solid #DDD;
			border-bottom: 1px solid #DDD;
		}
	}
	.process-steps li {
		float: none;
		width: auto !important;
		margin-top: 20px;
		&:first-child {
			margin-top: 0;
		}
		h5 {
			margin: 10px 0 0 0;
		}
		&:before, &:after {
			display: none;
		}
	}
	.testimonials-grid li {
		float: none;
		width: auto !important;
		padding-left: 0;
		padding-right: 0;
	}
	.team.team-list {
		.team-image {
			float: none;
			width: 100%;
			margin-right: 0;
		}
		.team-title {
			margin-top: 15px;
		}
	}
	.title-block {
		padding: 2px 0 3px 15px;
		border-left-width: 5px;
	}
	.title-block-right {
		padding: 2px 15px 3px 0;
		border-right-width: 5px;
	}
	.title-block {
		h1 + span {
			font-size: 18px;
		}
		h2 + span {
			font-size: 17px;
		}
		h3 + span {
			font-size: 15px;
		}
		h4 + span {
			font-size: 13px;
		}
	}
	.heading-block {
		h1 {
			font-size: 30px;
		}
		h2 {
			font-size: 47px;
			letter-spacing: 20px;
		}
		h3 {
			font-size: 22px;
		}
		h4 {
			font-size: 19px;
		}
		h1 + span {
			font-size: 20px;
		}
		h2 + span {
			font-size: 18px;
		}
		h3 + span, h4 + span {
			font-size: 17px;
		}
		~ p:not(.lead) {
			font-size: 15px;
		}
	}
	.emphasis-title {
		h1, h2 {
			font-size: 44px !important;
		}
	}
	.wedding-head {
		font-size: 48px;
		.first-name, .last-name, .and {
			display: block;
			margin: 0 0 15px;
			text-align: center;
		}
		.first-name span, .last-name span {
			font-size: 36px;
		}
		.and {
			font-size: 120px;
		}
	}
	.news-carousel .overlay {
		display: none;
	}
	.widget {
		display: table;
		margin: 0 auto;
	}
	.social-icon {
		margin-top: 50px;
		margin-bottom: 0;
		display: block;
	}
	/* Copyrights
	-----------------------------------------------------------------*/
	#copyrights {
		.col_half {
			text-align: center;
			&:last-child {
				display: none;
			}
		}
		.footer-logo {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.signature {
		background-size: 346px 64px;
		width: 346px;
		height: 64px;
	}
}

@media (min-width: 480px) and (max-width: 715px) {
	figure.effect-apollo {
		figcaption {
			padding: 1em !important;
		}
		h2 {
			line-height: 30px !important;
		}
		p {
			// font-size: 12px !important;
			letter-spacing: 0 !important;
			// margin: 1em !important;
		}
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.container, .container-fullwidth {
		width: 470px !important;
	}
	body.stretched #header.full-header .container, .container.vertical-middle {
		width: 100% !important;
	}
	.full-screen:not(.force-full-screen) .container.vertical-middle {
		width: 470px !important;
	}
	#top-search form input {
		padding-right: 60px;
	}
	.swiper_wrapper {
		&:not(.force-full-screen) {
			height: 300px !important;
			&:not(.canvas-slider-grid) .swiper-slide {
				height: 300px !important;
			}
		}
		.slider-caption h2 {
			font-size: 36px;
		}
	}
	.ei-slider {
		height: 250px;
	}
	#header.transparent-header.floating-header .container {
		width: 470px;
		padding: 0 15px;
	}
	/* Portfolio - Items
	-----------------------------------------------------------------*/
	#portfolio {
		&:not(.portfolio-1) {
			margin: 0 -6px -6px 0;
			.portfolio-item {
				margin: 0 6px 6px 0;
				width: 217px;
				.portfolio-image {
					width: 217px;
					height: 162px;
					a, img {
						width: 217px;
						height: 162px;
					}
				}
			}
		}
		&.portfolio-nomargin:not(.portfolio-1) .portfolio-item {
			width: 219px;
			.portfolio-image {
				width: 219px;
				height: 164px;
				a, img {
					width: 219px;
					height: 164px;
				}
			}
		}
		&:not(.portfolio-1) .portfolio-desc {
			padding: 15px 5px 10px;
			h3 {
				font-size: 16px;
			}
			span {
				font-size: 14px;
			}
		}
		&.portfolio-notitle:not(.portfolio-1) .portfolio-desc {
			height: 78px;
			padding: 15px 5px;
			bottom: -79px;
		}
		&.portfolio-full.portfolio-notitle:not(.portfolio-1) .portfolio-desc, &.portfolio-nomargin.portfolio-notitle:not(.portfolio-1) .portfolio-desc {
			bottom: -78px;
			border-bottom: 0;
		}
		&.portfolio-notitle:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay a {
			&.left-icon, &.right-icon {
				margin-top: -55px !important;
			}
		}
	}
	/* Portfolio - Items - 5 & 6 Columns
	-----------------------------------------------------------------*/
	/* Portfolio - Items - 5 & 6 Columns - No Margin
	-----------------------------------------------------------------*/
	/* Portfolio - Items - Descriptions
	-----------------------------------------------------------------*/
	/* Portfolio - Items - Overlay
	-----------------------------------------------------------------*/
	.portfolio-notitle .iportfolio:hover .portfolio-overlay a {
		&.left-icon, &.right-icon {
			margin-top: -55px !important;
		}
	}
	#portfolio:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay .portfolio-desc ~ a {
		margin: 20px 0 0 !important;
		&.right-icon {
			margin-left: 5px !important;
			margin-right: 0 !important;
		}
	}
	/* Portfolio - Items - 1 Column
	-----------------------------------------------------------------*/
	.portfolio-1 {
		.portfolio-item .portfolio-image {
			float: none !important;
			margin: 0 !important;
			margin-bottom: 10px !important;
			width: 100%;
			height: auto;
			a, img {
				width: 100%;
				height: auto;
			}
		}
		.portfolio-desc {
			width: 100%;
		}
	}
	/* Portfolio - Items - 1 Column - Full Width
	-----------------------------------------------------------------*/
	#portfolio.portfolio-1.portfolio-fullwidth {
		margin: 0 0 -60px 0;
	}
	.portfolio-1.portfolio-fullwidth {
		.portfolio-item {
			margin-bottom: 60px;
			padding-bottom: 0;
			border-bottom: 0;
			overflow: hidden;
			.portfolio-image {
				float: none;
				margin: 0 0 20px 0 !important;
				width: 100%;
				height: auto;
				a {
					width: 100%;
					height: auto;
				}
				img {
					width: 100%;
					height: auto;
					-webkit-transition: none;
					-o-transition: none;
					transition: none;
				}
			}
			&:hover .portfolio-image img, &.alt:hover .portfolio-image img {
				-webkit-transform: translateX(0);
				-moz-transform: translateX(0);
				-ms-transform: translateX(0);
				-o-transform: translateX(0);
				transform: translateX(0);
			}
		}
		.portfolio-desc, .alt .portfolio-desc {
			position: relative;
			width: 100%;
			height: auto;
			padding: 0;
			background-color: transparent;
			left: 0;
			right: 0;
			-webkit-transition: none;
			-o-transition: none;
			transition: none;
		}
		.portfolio-overlay a {
			left: 50%;
		}
		.alt .portfolio-overlay a {
			left: auto;
			right: 50%;
		}
	}
	#portfolio {
		&.portfolio-nomargin, &.portfolio-full {
			margin: 0 !important;
		}
	}
	.post-grid:not(.post-masonry) .entry-image iframe {
		height: 246px;
	}
	#shop:not(.product-1) {
		margin: 0 -12px -12px 0;
		.product {
			margin: 0 12px 12px 0;
			width: 214px;
		}
		.product-image {
			height: 285px;
		}
		.product-title h3 {
			font-size: 17px;
		}
	}
	.clients-grid {
		&:not(.grid-3):not(.grid-2) li, &.grid-4 li, &.grid-6 li {
			width: 33.33%;
		}
	}
	.button.button-full {
		font-size: 18px;
		i {
			top: 2px !important;
			margin-left: 3px !important;
		}
	}
	.error404, .error404-wrap .error404 {
		font-size: 160px;
	}
	.landing-video {
		position: relative;
		top: 12px;
		width: 333px;
		height: 187px;
		margin: 19px 0 0 53px;
	}
}

@media (max-width: 479px) {
	.container, .container-fullwidth {
		width: 95% !important;
	}
	body.stretched #header.full-header .container, .container.vertical-middle {
		width: 100% !important;
	}
	.full-screen:not(.force-full-screen) .container.vertical-middle {
		width: 310px !important;
	}
	#top-cart {
		display: none;
	}
	#top-search {
		a {
			right: 15px;
		}
		form input {
			padding-right: 30px;
		}
	}
	#side-panel-trigger {
		right: 43px;
	}
	.swiper_wrapper:not(.force-full-screen) {
		height: 180px !important;
		&:not(.canvas-slider-grid) .swiper-slide {
			height: 180px !important;
		}
	}
	.ei-slider {
		height: 180px !important;
	}
	.swiper_wrapper .slider-caption h2 {
		font-size: 28px;
	}
	.bb-caption p {
		font-size: 54px;
		margin-top: 60px;
	}
	.bash-slider-caps-text {
		font-size: 16px !important;
		line-height: 20px !important;
		display: block;
	}
	.bash-slider-emphasis-text {
		font-size: 18px !important;
		line-height: 18px !important;
		padding-top: 12px !important;
		padding-bottom: 12px !important;
	}
	.bb-banner .glitch {
		display: none;
	}
	h1.bash {
		font-size: 88px;
		margin: 0 0 50px -70px;
	}
	h2.tr909 {
		font-size: 60px;
		margin: 10px 0 0 -35px;
	}
	#header.transparent-header.floating-header .container {
		width: 310px;
		padding: 0 15px;
	}
	#portfolio-filter li {
		width: 50%;
	}
	#portfolio:not(.portfolio-1) {
		margin: 0 !important;
		.portfolio-item {
			width: 280px;
			.portfolio-image {
				width: 280px;
				height: 211px;
				a, img {
					width: 280px;
					height: 211px;
				}
			}
		}
	}
	.post-grid:not(.post-masonry) .entry-image iframe {
		height: 157px;
	}
	#shop:not(.product-1) {
		margin: 0;
		.product {
			margin: 0 0 20px 0;
			width: 100%;
		}
		.product-image {
			height: 373px;
		}
		.product-title h3 {
			font-size: 19px;
		}
	}
	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs {
		height: 45px;
		li {
			display: block;
			width: 60px !important;
			height: 45px !important;
			img {
				display: block;
				width: 60px !important;
				height: 45px !important;
			}
		}
	}
	.si-share span {
		display: none;
	}
	.parallax {
		.entry-overlay-meta {
			left: 50% !important;
			right: 0 !important;
			margin: 0 0 0 -140px !important;
			width: 280px !important;
		}
		.entry-overlay {
			left: 50% !important;
			right: 0 !important;
			margin: 0 0 0 -140px !important;
			width: 280px !important;
			width: 288px !important;
		}
		.countdown-section {
			width: 64px;
			height: 64px;
			margin: 0 8px 0 0;
			font-size: 11px;
			padding: 0;
			padding-top: 12px;
		}
		.countdown-amount {
			font-size: 20px;
		}
	}
	.countdown-large .countdown-section {
		display: block;
		width: auto;
		margin: 30px 0 0;
		border: 0;
		&:first-child {
			margin-top: 0;
		}
	}
	.testi-image {
		width: 36px;
		height: 36px;
		a, img {
			width: 36px;
			height: 36px;
		}
		i {
			width: 36px;
			height: 36px;
			line-height: 36px;
			font-size: 20px;
		}
	}
	.clients-grid {
		&:not(.grid-3):not(.grid-2) li, &.grid-4 li, &.grid-6 li {
			width: 50%;
		}
	}
	.button.button-full {
		font-size: 16px;
		i {
			top: 2px !important;
			margin-left: 3px !important;
		}
	}
	.emphasis-title {
		h1, h2 {
			font-size: 40px !important;
		}
	}
	.error404, .error404-wrap .error404 {
		font-size: 120px;
	}
	.landing-video {
		position: relative;
		top: 9px;
		width: 212px;
		height: 119px;
		margin: 19px 0 0 34px;
	}
	.landing-form-overlay {
		margin-left: -155px;
	}
	.signature {
		background-size: 277px 51px;
		width: 277px;
		height: 51px;
	}
}

@media (max-height: 340px) {
	.hamburger {
		display: none;
	}
	#header {
		position: relative;
	}
}

/* ----------------------------------------------------------------
		Retina CSS
-----------------------------------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
	#logo a {
		&.standard-logo {
			display: none;
		}
		&.retina-logo {
			display: block;
		}
	}
	.owl-carousel.owl-loading, .preloader, .preloader2, .form-process {
		background-image: url('../images/preloader@2x.gif');
		background-size: 24px 24px;
	}
	.dark {
		.owl-carousel.owl-loading, .preloader, .preloader2, .form-process {
			background-image: url('../images/preloader-dark@2x.gif');
		}
	}
	.widget_recent_comments li {
		background-image: url("../images/icons/widget-comment@2x.png");
		background-size: 15px 26px;
	}
	.widget_nav_menu li a, .widget_links li a, .widget_meta li a, .widget_archive li a, .widget_recent_entries li a, .widget_categories li a, .widget_pages li a, .widget_rss li a, .widget_recent_comments li a {
		background-image: url("../images/icons/widget-link@2x.png");
		background-size: 4px 20px;
	}
	.dark {
		.widget_recent_comments li {
			background-image: url("../images/icons/widget-comment-dark@2x.png");
		}
		.widget_nav_menu li a, .widget_links li a, .widget_meta li a, .widget_archive li a, .widget_recent_entries li a, .widget_categories li a, .widget_pages li a, .widget_rss li a, .widget_recent_comments li a {
			background-image: url("../images/icons/widget-link-dark@2x.png");
		}
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (max-width: 991px), only screen and (min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min-resolution: 192dpi) and (max-width: 991px), only screen and (min-resolution: 2dppx) and (max-width: 991px) {
	#logo a {
		&.standard-logo {
			display: none;
		}
		&.retina-logo {
			display: inline-block;
		}
	}
}
