/* ----------------------------------------------------------------
	Preloaders
-----------------------------------------------------------------*/

.preloader, .preloader2, .form-process {
	display: block;
	width: 100%;
	height: 100%;
	background: url("../images/ball-triangle.svg") center center no-repeat #1d1f21;
	background-size: 100px 100px;
}

.preloader2 {
	background-color: transparent;
}

.form-process {
	display: none;
	position: absolute;
	z-index: 12;
	background-color: #1d1f21;
}

/* Main */

.start_loader {
	background: #222427;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
}

.the_loader {
	left: 50%;
	top: 50%;
	margin-left: -112px;
	margin-top: -62px;
	position: absolute;
}

.loader-text {
	font-family: $header_font;
	text-transform: uppercase;
	text-align: center;
	font-size: 16px;
	color: #3a3d40;
}

.lt-top {
	letter-spacing: 5px;
	margin-bottom: 10px;
	-webkit-animation: lt-top-ani 1s ease-in-out;
	-moz-animation: lt-top-ani 1s ease-in-out;
	-o-animation: lt-top-ani 1s ease-in-out;
	animation: lt-top-ani 1s ease-in-out;
}

@-webkit-keyframes lt-top-ani {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


@-moz-keyframes lt-top-ani {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


@-o-keyframes lt-top-ani {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


@keyframes lt-top-ani {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


.lt-bottom {
	letter-spacing: 2px;
	margin-top: 10px;
	-webkit-animation: lt-bottom-ani 1s ease-in-out;
	-moz-animation: lt-bottom-ani 1s ease-in-out;
	-o-animation: lt-bottom-ani 1s ease-in-out;
	animation: lt-bottom-ani 1s ease-in-out;
}

@-webkit-keyframes lt-bottom-ani {
	0% {
		opacity: 0;
		margin-top: 40px;
	}

	100% {
		opacity: 1;
		margin-top: 10px;
	}
}


@-moz-keyframes lt-bottom-ani {
	0% {
		opacity: 0;
		margin-top: 40px;
	}

	100% {
		opacity: 1;
		margin-top: 10px;
	}
}


@-o-keyframes lt-bottom-ani {
	0% {
		opacity: 0;
		margin-top: 40px;
	}

	100% {
		opacity: 1;
		margin-top: 10px;
	}
}


@keyframes lt-bottom-ani {
	0% {
		opacity: 0;
		margin-top: 40px;
	}

	100% {
		opacity: 1;
		margin-top: 10px;
	}
}


.nfsloader {
	margin-bottom: 0;
	text-align: center;
	li {
		background: $aqua_cream;
		margin-left: 1px;
		width: 20px;
		height: 30px;
		display: inline-block;
		opacity: 0;
		border-radius: 2px;
		box-shadow: 0 0 5px $aqua_cream;
		-webkit-transform: skew(-25deg, 0deg) scale(0.1);
		-moz-transform: skew(-25deg, 0deg) scale(0.1);
		-ms-transform: skew(-25deg, 0deg) scale(0.1);
		-o-transform: skew(-25deg, 0deg) scale(0.1);
		transform: skew(-25deg, 0deg) scale(0.1);
		-webkit-animation: loader .8s ease-in-out infinite alternate;
		-moz-animation: loader .8s ease-in-out infinite alternate;
		-o-animation: loader .8s ease-in-out infinite alternate;
		-ms-animation: loader .8s ease-in-out infinite alternate;
		animation: loader .8s ease-in-out infinite alternate;
		&:nth-child(2) {
			-webkit-animation-delay: 0.2s;
			-moz-animation-delay: 0.2s;
			-ms-animation-delay: 0.2s;
			-o-animation-delay: 0.2s;
			animation-delay: 0.2s;
		}
		&:nth-child(3) {
			-webkit-animation-delay: 0.4s;
			-moz-animation-delay: 0.4s;
			-ms-animation-delay: 0.4s;
			-o-animation-delay: 0.4s;
			animation-delay: 0.4s;
		}
		&:nth-child(4) {
			-webkit-animation-delay: 0.6s;
			-moz-animation-delay: 0.6s;
			-ms-animation-delay: 0.6s;
			-o-animation-delay: 0.6s;
			animation-delay: 0.6s;
		}
		&:nth-child(5) {
			-webkit-animation-delay: 0.8s;
			-moz-animation-delay: 0.8s;
			-ms-animation-delay: 0.8s;
			-o-animation-delay: 0.8s;
			animation-delay: 0.8s;
		}
		&:nth-child(6) {
			-webkit-animation-delay: 1s;
			-moz-animation-delay: 1s;
			-ms-animation-delay: 1s;
			-o-animation-delay: 1s;
			animation-delay: 1s;
		}
		&:nth-child(7) {
			-webkit-animation-delay: 1.2s;
			-moz-animation-delay: 1.2s;
			-ms-animation-delay: 1.2s;
			-o-animation-delay: 1.2s;
			animation-delay: 1.2s;
		}
	}
}

@-webkit-keyframes loader {
	to {
		-webkit-transform: skew(-25deg, 0deg) scale(1);
		opacity: 1;
	}
}


@-moz-keyframes loader {
	to {
		-moz-transform: skew(-25deg, 0deg) scale(1);
		opacity: 1;
	}
}


@-o-keyframes loader {
	to {
		-o-transform: skew(-25deg, 0deg) scale(1);
		opacity: 1;
	}
}


@keyframes loader {
	to {
		transform: skew(-25deg, 0deg) scale(1);
		opacity: 1;
	}
}
