
/* FONTS
---------------------------------- */
$bash_tech: 'Bash Tech';
$header_font: 'Aqua Grotesque';
$manifold: 'Manifold';
$tech_font: 'TR909';
$condensed_font: "Airship 27";
$ironbanana: 'Iron Banana';

/* COLORS
---------------------------------- */

/* Primary Colors */
$aqua_cream: #60d9eb;
$aqua_glow: #b6ecff;
$bright_aqua: #00deff;
$faded_aqua: #29aecc;
$subtle_glow_lt: #7c9da7;
$subtle_glow_dk: #708c95;
$firefly: #ffe893;

// Shades
$white: #ffffff;
$aqua_white: #edf8fb;
$txt_white_lt: #c4ced6;
$txt_white_dk: #b2bcc4;
$txt_stone: #808891;
$concrete_lt: #3a3d40;
$concrete_dk: #313437;
$blackened_slate: #222427;

/* CORE STRUCTURE
---------------------------------- */
// Neat
$disable-warnings: true;

// _Padding
$main_padding: 20px;
$fat_padding: 35px;
$content_padding: 80px;
$main_pad: 2.1875%;
$fat_pad: 6.375%;

/* BASE SIZES
---------------------------------- */

// _Mobile
$facehugger: 0;
$xenomorph: 420px;
$optimus_prime: 576px;
$megatron: 660px;
$millenniumfalcon: 720px;
$voltron: 840px;

// _Desktop
$clover: 960px;
$gipsy_danger: 1000px;
$king_ghidorah: 1200px;
$kaiju: 1370px;
$godzilla: 1570px;

// _Astronomical
$betelgeuse: 1740px;
$canis_majoris: 1920px;
$cygni: 2300px;
$scuti: 2500px;


/* BOURBON NEAT BREAKPOINTS
---------------------------------- */
// _Mobile
/*
	:: 375px <    */
		$mobile_xsm: new-breakpoint(min-width $xenomorph - 45px); /*
	:: 420px <    */
		$mobile_sm: new-breakpoint(min-width $xenomorph); /*
	:: 450px <    */
		$mobile_mds: new-breakpoint(min-width $xenomorph + 30px); /*
	:: 576px <    */
		$mobile_md: new-breakpoint(min-width $optimus-prime); /*
	:: 660px <    */
		$mobile_mdlg: new-breakpoint(min-width $megatron); /*
	:: 720px <    */
		$mobile_lg: new-breakpoint(min-width $millenniumfalcon); /*
	:: 768px <    */
		$mobile_mlg: new-breakpoint(min-width $millenniumfalcon + 48px); /*
	:: 840px <    */
		$mobile_xlg: new-breakpoint(min-width $voltron); /*
// _Desktop
/*
	:: 960px <    */
		$desktop_xsm: new-breakpoint(min-width $clover); /*
	:: 1000px <    */
		$desktop_sm: new-breakpoint(min-width $gipsy-danger); /*
	:: 1100px <    */
		$desktop_mds: new-breakpoint(min-width $king-ghidorah - 100px); /*
	:: 1200px <    */
		$desktop_md: new-breakpoint(min-width $king-ghidorah); /*
	:: 1280px <    */
		$desktop_mdlg: new-breakpoint(min-width $king-ghidorah + 80px); /*
	:: 1370px <    */
		$desktop_lg: new-breakpoint(min-width $kaiju); /*
	:: 1570px <    */
		$desktop_xlg: new-breakpoint(min-width $godzilla); /*
// _Astronomical
/*
	:: 2500px <    */
		$pinwheel: new-breakpoint(min-width $scuti); /*
	:: 2300px <    */
		$blackhole: new-breakpoint(min-width $cygni); /*
	:: 1920px < + 960px >    */
		$ursa_major: new-breakpoint(min-width $canis_majoris max-height $clover); /*
	:: 1920px <    */
		$andromeda: new-breakpoint(min-width $canis_majoris); /*
	:: 1740px < + 1110px <    */
		$milky-way: new-breakpoint(min-width $betelgeuse min-height $gipsy_danger + 110px);


// _Short
/*
	:: 600px <    */
		$short_xsm: new-breakpoint(min-height $megatron - 60px); /*
	:: 690px <    */
		$short_sm: new-breakpoint(min-height $megatron + 30px); /*
	:: 820px <    */
		$short_md: new-breakpoint(min-height $voltron - 20px); /*
	:: 900px <    */
		$short_lg: new-breakpoint(min-height $voltron + 60px); /*
	:: 1020px <    */
		$short_xlg: new-breakpoint(min-height $gipsy_danger + 20px); /*
	:: 1110px <    */
		$short_xxlg: new-breakpoint(min-height $gipsy_danger + 110px);
