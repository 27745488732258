/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

/* ----------------------------------------------------------------
	Styled Icons
-----------------------------------------------------------------*/

.i-rounded, .i-plain, .i-circled, .i-bordered, .social-icon {
	display: block;
	float: left;
	margin: 4px 11px 7px 0;
	text-align: center !important;
	font-size: 28px;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	font-style: normal;
}

body:not(.device-touch) {
	.i-rounded, .i-plain, .i-circled, .i-bordered, .social-icon {
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
	}
}

.i-rounded, .i-circled {
	width: 52px !important;
	height: 52px !important;
	line-height: 52px !important;
	background-color: #444;
	border-radius: 3px;
}

.i-bordered {
	width: 52px !important;
	height: 52px !important;
	line-height: 52px !important;
	background-color: #444;
	border-radius: 3px;
	border: 1px solid #555;
	line-height: 50px !important;
	background-color: transparent !important;
	color: #444;
	text-shadow: none;
}

.i-plain {
	width: 36px !important;
	height: 36px !important;
	font-size: 28px;
	line-height: 36px !important;
	color: #444;
	text-shadow: 1px 1px 1px #FFF;
	&:hover {
	color: #777;
	}
}

.i-circled {
	border-radius: 50%;
}

.i-light {
	background-color: #F5F5F5;
	color: #444;
	text-shadow: 1px 1px 1px #FFF;
	box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
}

.i-alt {
	background-image: url('../images/icons/iconalt.svg');
	background-position: center center;
	background-size: 100% 100%;
}

.i-rounded:hover, .i-circled:hover {
	background-color: #00deff;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.i-bordered:hover {
	background-color: #444 !important;
	color: #FFF;
	border-color: #444;
}

/* Icon Size - Small
-----------------------------------------------------------------*/

.i-small {
	&.i-rounded, &.i-plain, &.i-circled, &.i-bordered {
	margin: 4px 11px 7px 0;
	font-size: 14px;
	}
	&.i-rounded, &.i-circled {
	width: 28px !important;
	height: 28px !important;
	line-height: 28px !important;
	}
	&.i-bordered {
	width: 28px !important;
	height: 28px !important;
	line-height: 28px !important;
	line-height: 26px !important;
	}
	&.i-plain {
	width: 18px !important;
	height: 18px !important;
	font-size: 14px;
	line-height: 18px !important;
	}
}

/* Icon Size - Small
-----------------------------------------------------------------*/

.i-medium {
	&.i-rounded, &.i-plain, &.i-circled, &.i-bordered {
	margin: 6px 13px 9px 0;
	font-size: 21px;
	}
	&.i-rounded, &.i-circled {
	width: 36px !important;
	height: 36px !important;
	line-height: 36px !important;
	}
	&.i-bordered {
	width: 36px !important;
	height: 36px !important;
	line-height: 36px !important;
	line-height: 34px !important;
	}
	&.i-plain {
	width: 28px !important;
	height: 28px !important;
	font-size: 20px;
	line-height: 28px !important;
	}
}

/* Icon Size - Large
-----------------------------------------------------------------*/

.i-large {
	&.i-rounded, &.i-plain, &.i-circled, &.i-bordered {
	margin: 4px 11px 7px 0;
	font-size: 42px;
	}
	&.i-rounded, &.i-circled {
	width: 64px !important;
	height: 64px !important;
	line-height: 64px !important;
	}
	&.i-bordered {
	width: 64px !important;
	height: 64px !important;
	line-height: 64px !important;
	line-height: 62px !important;
	}
	&.i-plain {
	width: 48px !important;
	height: 48px !important;
	font-size: 42px;
	line-height: 48px !important;
	}
}

/* Icon Size - Extra Large
-----------------------------------------------------------------*/

.i-xlarge {
	&.i-rounded, &.i-plain, &.i-circled, &.i-bordered {
	margin: 4px 11px 7px 0;
	font-size: 56px;
	}
	&.i-rounded, &.i-circled {
	width: 84px !important;
	height: 84px !important;
	line-height: 84px !important;
	}
	&.i-bordered {
	width: 84px !important;
	height: 84px !important;
	line-height: 84px !important;
	line-height: 82px !important;
	}
	&.i-plain {
	width: 64px !important;
	height: 64px !important;
	font-size: 56px;
	line-height: 64px !important;
	}
}

/* ----------------------------------------------------------------
	Icon Overlay
-----------------------------------------------------------------*/

.i-overlay {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	background: rgba(0, 0, 0, 0.2) url('../images/grid.png') repeat;
	img {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -24px 0 0 -24px;
	width: 48px !important;
	height: 48px !important;
	font-size: 48px;
	line-height: 1;
	color: #E5E5E5;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	}
	i {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -24px 0 0 -24px;
	width: 48px !important;
	height: 48px !important;
	font-size: 48px;
	line-height: 1;
	color: #E5E5E5;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	margin: -18px 0 0 -18px;
	width: 36px;
	height: 36px;
	line-height: 36px;
	font-size: 24px;
	}
}

.on-hover.i-overlay {
	opacity: 0;
}

body:not(.device-touch) .on-hover.i-overlay {
	-webkit-transition: opacity 0.3s linear;
	-o-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
}

.on-hover.i-overlay:hover {
	opacity: 1;
}

/* ----------------------------------------------------------------
	Social Icons
-----------------------------------------------------------------*/

.social-icon {
	margin: 0 5px 5px 0;
	width: 40px;
	height: 40px;
	font-size: 20px;
	line-height: 38px !important;
	color: #6b7277;
	text-shadow: none;
	border: 1px solid #AAA;
	border-radius: 3px;
	overflow: hidden;
}

/* Social Icons - Rounded
-----------------------------------------------------------------*/

.si-rounded {
	border-radius: 50%;
}

/* Social Icons - Borderless
-----------------------------------------------------------------*/

.si-borderless {
	border-color: transparent !important;
}

/* Social Icons - Dark
-----------------------------------------------------------------*/

.si-dark {
	background-color: #444;
	color: #FFF !important;
	border-color: transparent;
}

/* Social Icons - Light
-----------------------------------------------------------------*/

.si-light {
	background-color: rgba(0, 0, 0, 0.05);
	color: #666 !important;
	border-color: transparent;
}

/* Social Icons - Colored
-----------------------------------------------------------------*/

.si-colored {
	border-color: transparent !important;
	i {
	color: #FFF;
	}
}

/* Social Icons - Large
-----------------------------------------------------------------*/

.social-icon {
	&.si-large {
	margin: 0 10px 10px 0;
	width: 56px;
	height: 56px;
	font-size: 30px;
	line-height: 54px !important;
	}
	&.si-small {
	width: 32px;
	height: 32px;
	font-size: 14px;
	line-height: 30px !important;
	}
	i {
	display: block;
	position: relative;
	}
}

/* Social Icons - Small
-----------------------------------------------------------------*/

body:not(.device-touch) .social-icon i {
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.social-icon {
	i:last-child {
	color: #FFF !important;
	}
	&:hover i:first-child {
	margin-top: -38px;
	}
	&.si-large:hover i:first-child {
	margin-top: -54px;
	}
	&.si-small:hover i:first-child {
	margin-top: -30px;
	}
	&:hover {
	color: #555;
	border-color: transparent;
	}
}

.si-colored.social-icon {
	border-color: transparent;
}

/* Social Icons - Sticky
-----------------------------------------------------------------*/

.si-sticky {
	position: fixed;
	top: 50%;
	left: 5px;
	width: 36px;
	z-index: 100;
	&.si-sticky-right {
	left: auto;
	right: 8px;
	}
}

/* Social Icons - Sticky Right
-----------------------------------------------------------------*/

/* Social Icons - Share
-----------------------------------------------------------------*/

.si-share {
	position: relative;
	padding-left: 5px;
	border-top: 1px solid #EEE;
	border-bottom: 1px solid #EEE;
	span {
	line-height: 36px;
	font-weight: bold;
	font-size: 13px;
	}
	div {
	float: right;
	}
	.social-icon {
	margin: -1px 0;
	border-radius: 0;
	font-size: 15px;
	}
}

.si-facebook:hover, .si-colored.si-facebook {
	background-color: #3B5998 !important;
}

.si-delicious:hover, .si-colored.si-delicious {
	background-color: #205CC0 !important;
}

.si-paypal:hover, .si-colored.si-paypal {
	background-color: #00588B !important;
}

.si-flattr:hover, .si-colored.si-flattr {
	background-color: #F67C1A !important;
}

.si-android:hover, .si-colored.si-android {
	background-color: #A4C639 !important;
}

.si-smashmag:hover, .si-colored.si-smashmag {
	background-color: #E53B2C !important;
}

.si-gplus:hover, .si-colored.si-gplus {
	background-color: #DD4B39 !important;
}

.si-wikipedia:hover, .si-colored.si-wikipedia {
	background-color: #333 !important;
}

.si-stumbleupon:hover, .si-colored.si-stumbleupon {
	background-color: #F74425 !important;
}

.si-foursquare:hover, .si-colored.si-foursquare {
	background-color: #25A0CA !important;
}

.si-call:hover, .si-colored.si-call {
	background-color: #444 !important;
}

.si-ninetyninedesigns:hover, .si-colored.si-ninetyninedesigns {
	background-color: #F26739 !important;
}

.si-forrst:hover, .si-colored.si-forrst {
	background-color: #5B9A68 !important;
}

.si-digg:hover, .si-colored.si-digg {
	background-color: #191919 !important;
}

.si-spotify:hover, .si-colored.si-spotify {
	background-color: #81B71A !important;
}

.si-reddit:hover, .si-colored.si-reddit {
	background-color: #C6C6C6 !important;
}

.si-blogger:hover, .si-colored.si-blogger {
	background-color: #FC4F08 !important;
}

.si-cc:hover, .si-colored.si-cc {
	background-color: #688527 !important;
}

.si-dribbble:hover, .si-colored.si-dribbble {
	background-color: #EA4C89 !important;
}

.si-evernote:hover, .si-colored.si-evernote {
	background-color: #5BA525 !important;
}

.si-flickr:hover, .si-colored.si-flickr {
	background-color: #FF0084 !important;
}

.si-google:hover, .si-colored.si-google {
	background-color: #DD4B39 !important;
}

.si-instapaper:hover, .si-colored.si-instapaper {
	background-color: #333 !important;
}

.si-klout:hover, .si-colored.si-klout {
	background-color: #FF5F52 !important;
}

.si-linkedin:hover, .si-colored.si-linkedin {
	background-color: #0E76A8 !important;
}

.si-vk:hover, .si-colored.si-vk {
	background-color: #2B587A !important;
}

.si-rss:hover, .si-colored.si-rss {
	background-color: #EE802F !important;
}

.si-skype:hover, .si-colored.si-skype {
	background-color: #00AFF0 !important;
}

.si-twitter:hover, .si-colored.si-twitter {
	background-color: #00ACEE !important;
}

.si-youtube:hover, .si-colored.si-youtube {
	background-color: #C4302B !important;
}

.si-vimeo:hover, .si-colored.si-vimeo {
	background-color: #86C9EF !important;
}

.si-aim:hover, .si-colored.si-aim {
	background-color: #FCD20B !important;
}

.si-yahoo:hover, .si-colored.si-yahoo {
	background-color: #720E9E !important;
}

.si-email3:hover, .si-colored.si-email3 {
	background-color: #6567A5 !important;
}

.si-macstore:hover, .si-colored.si-macstore {
	background-color: #333333 !important;
}

.si-myspace:hover, .si-colored.si-myspace {
	background-color: #666666 !important;
}

.si-podcast:hover, .si-colored.si-podcast {
	background-color: #E4B21B !important;
}

.si-cloudapp:hover, .si-colored.si-cloudapp {
	background-color: #525557 !important;
}

.si-dropbox:hover, .si-colored.si-dropbox {
	background-color: #3D9AE8 !important;
}

.si-ebay:hover, .si-colored.si-ebay {
	background-color: #89C507 !important;
}

.si-github:hover, .si-colored.si-github {
	background-color: #171515 !important;
}

.si-googleplay:hover, .si-colored.si-googleplay {
	background-color: #DD4B39 !important;
}

.si-itunes:hover, .si-colored.si-itunes {
	background-color: #222 !important;
}

.si-plurk:hover, .si-colored.si-plurk {
	background-color: #CF5A00 !important;
}

.si-pinboard:hover, .si-colored.si-pinboard {
	background-color: #0000E6 !important;
}

.si-soundcloud:hover, .si-colored.si-soundcloud {
	background-color: #FF7700 !important;
}

.si-tumblr:hover, .si-colored.si-tumblr {
	background-color: #34526F !important;
}

.si-wordpress:hover, .si-colored.si-wordpress {
	background-color: #1E8CBE !important;
}

.si-yelp:hover, .si-colored.si-yelp {
	background-color: #C41200 !important;
}

.si-intensedebate:hover, .si-colored.si-intensedebate {
	background-color: #009EE4 !important;
}

.si-eventbrite:hover, .si-colored.si-eventbrite {
	background-color: #F16924 !important;
}

.si-scribd:hover, .si-colored.si-scribd {
	background-color: #666666 !important;
}

.si-stripe:hover, .si-colored.si-stripe {
	background-color: #008CDD !important;
}

.si-print:hover, .si-colored.si-print {
	background-color: #111 !important;
}

.si-dwolla:hover, .si-colored.si-dwolla {
	background-color: #FF5C03 !important;
}

.si-statusnet:hover, .si-colored.si-statusnet {
	background-color: #131A30 !important;
}

.si-acrobat:hover, .si-colored.si-acrobat {
	background-color: #D3222A !important;
}

.si-drupal:hover, .si-colored.si-drupal {
	background-color: #27537A !important;
}

.si-buffer:hover, .si-colored.si-buffer {
	background-color: #333333 !important;
}

.si-pocket:hover, .si-colored.si-pocket {
	background-color: #EE4056 !important;
}

.si-bitbucket:hover, .si-colored.si-bitbucket {
	background-color: #0E4984 !important;
}

.si-stackoverflow:hover, .si-colored.si-stackoverflow {
	background-color: #EF8236 !important;
}

.si-hackernews:hover, .si-colored.si-hackernews {
	background-color: #FF6600 !important;
}

.si-xing:hover, .si-colored.si-xing {
	background-color: #126567 !important;
}

.si-instagram:hover, .si-colored.si-instagram {
	background-color: #3F729B !important;
}

.si-quora:hover, .si-colored.si-quora {
	background-color: #A82400 !important;
}

.si-openid:hover, .si-colored.si-openid {
	background-color: #E16309 !important;
}

.si-steam:hover, .si-colored.si-steam {
	background-color: #111 !important;
}

.si-amazon:hover, .si-colored.si-amazon {
	background-color: #E47911 !important;
}

.si-disqus:hover, .si-colored.si-disqus {
	background-color: #E4E7EE !important;
}

.si-plancast:hover, .si-colored.si-plancast {
	background-color: #222 !important;
}

.si-appstore:hover, .si-colored.si-appstore {
	background-color: #000 !important;
}

.si-pinterest:hover, .si-colored.si-pinterest {
	background-color: #C8232C !important;
}

.si-fivehundredpx:hover, .si-colored.si-fivehundredpx {
	background-color: #111 !important;
}
