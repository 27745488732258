button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: none;
	border-radius: 3px;
	background: #e11f26;
	color: $white;
	@include font-size(0.75);
	line-height: 1;
	padding: .6em 1em .4em;

	&:hover {
		border-color: #e4e4e4;
	}

	&:active,
	&:focus {
		border-color: #e4e4e4;
	}
}

// Arrow Down
.one-page-arrow {
	position: absolute;
	z-index: 3;
	bottom: 20px;
	left: 50%;
	margin-left: -16px;
	width: 32px;
	height: 32px;
	font-size: 32px;
	text-align: center;
	color: $subtle_glow_lt;
	@include user-select(none);

	&:hover,
	&:focus,
	&:active {
		color: $subtle_glow_lt;
		outline: 0;
	}

	.icon-angle-down {
		@include animation(downarrow 1.5s infinite);
	}

	@include keyframes(downarrow) {
		0% {
			opacity: 0;
			@include transform(translateY(-80px));
		}
		90% {
			opacity: 1;
			@include transform(translateY(0));
		}
		100% {
			opacity: 0;
		}
	}
}

/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/

.button {
	display: inline-block;
	text-transform: uppercase;
	font-family: $header_font;
	position: relative;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
	margin: 5px;
	padding: 0 22px 0 22px;
	font-size: 14px;
	height: 40px;
	line-height: 42px;
	background-color: $aqua_cream;
	color: #FFF;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: none;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

body:not(.device-touch) .button {
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.button-dark {
	background-color: #444;
}

.button-light {
	background-color: #EEE;
	color: #333;
	text-shadow: none !important;
}

.button {
	&:hover {
		background-color: #444;
		color: #FFF;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
	&.button-dark:hover {
		background-color: $aqua_cream;
	}
	&.button-mini {
		padding: 0 14px;
		font-size: 11px;
		height: 28px;
		line-height: 28px;
	}
	&.button-small {
		padding: 0 17px;
		font-size: 12px;
		height: 34px;
		line-height: 34px;
	}
	&.button-large {
		padding: 4px 26px 0 26px;
		font-size: 16px;
		height: 46px;
		line-height: 44px;
	}
	&.button-xlarge {
		padding: 0 32px;
		font-size: 18px;
		letter-spacing: 2px;
		height: 52px;
		line-height: 52px;
	}
	&.button-desc {
		text-align: left;
		padding: 24px 34px;
		font-size: 22px;
		height: auto;
		line-height: 1;
		font-family: $header_font;
		span {
			display: block;
			margin-top: 10px;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 1px;
			font-family: 'Crete Round', serif;
			font-style: italic;
			text-transform: none;
		}
	}
	i {
		position: relative;
		top: 1px;
		line-height: 1;
		margin-right: 5px;
	}
	&.tright i {
		margin: 0 0 0 5px;
	}
}

.button-mini {
	i {
		margin-right: 3px;
	}
	&.tright i {
		margin: 0 0 0 3px;
	}
}

.button-small {
	i {
		margin-right: 4px;
	}
	&.tright i {
		margin: 0 0 0 4px;
	}
}

.button-xlarge {
	i {
		top: 2px;
		margin-right: 8px;
	}
	&.tright i {
		margin: 0 0 0 8px;
	}
}

.button {
	&.button-desc {
		i {
			top: 1px;
			font-size: 48px;
			width: 48px;
			text-align: center;
			margin-right: 12px;
		}
		&.tright i {
			margin: 0 0 0 12px;
		}
		div {
			display: inline-block;
		}
	}
	&.button-rounded {
		border-radius: 3px;
	}
}

/* Button 3edge
-----------------------------------------------------------------*/
/* General button style (reset) */

.bb-btn {
	border: none;
	font-family: $header_font !important;
	font-size: 24px;
	line-height: 24px !important;
	text-transform: uppercase !important;
	color: $aqua_cream;
	background: #282b2e;
	cursor: pointer;
	padding: 25px 80px;
	display: inline-block;
	margin: 15px 0;
	letter-spacing: 1px;
	font-weight: 400;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	&:active {
		color: $aqua_cream !important;
	}
	&:hover {
		background: #222427;
		color: #60d9eb;
	}
	.icon-line-fast-forward {
		position: absolute;
		right: 22px;
		top: 22px;
		z-index: 2;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	&:hover > .icon-line-fast-forward {
		right: 10px;
	}
}

.btn-3e {
	padding: 24px 120px 18px 60px;
	overflow: hidden;
	&:before {
		left: auto;
		right: 10px;
		z-index: 2;
	}
	&:after {
		width: 30%;
		height: 200%;
		background: rgba(80, 84, 89, 0.2);
		z-index: 1;
		right: 0;
		top: 0;
		margin: -5px 0 0 -5px;
		-webkit-transform-origin: 0 0;
		-webkit-transform: rotate(-20deg);
		-moz-transform-origin: 0 0;
		-moz-transform: rotate(-20deg);
		-ms-transform-origin: 0 0;
		-ms-transform: rotate(-20deg);
		transform-origin: 0 0;
		transform: rotate(-20deg);
	}
	&:hover:after {
		width: 40%;
	}
}

/* Buttons - Border
-----------------------------------------------------------------*/

.button {
	&.button-border {
		border: 2px solid #444;
		background-color: transparent;
		color: #333;
		line-height: 36px;
		font-weight: 600;
		text-shadow: none;
		&.button-mini {
			line-height: 24px;
		}
		&.button-small {
			line-height: 30px;
		}
		&.button-large {
			line-height: 42px;
		}
		&.button-xlarge {
			line-height: 48px;
		}
		&.button-desc {
			line-height: 1;
		}
		&:hover {
			background-color: #444;
			color: #FFF;
			border-color: transparent !important;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		}
		&.button-light {
			border-color: #EEE;
			color: #EEE;
			&:hover {
				background-color: #EEE;
				color: #333;
				text-shadow: none;
			}
		}
	}
	&.button-3d {
		border-radius: 3px;
		border-bottom: 3px solid rgba(0, 0, 0, 0.15);
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
		&:hover {
			background-color: $aqua_cream !important;
			opacity: 0.9;
		}
		&.button-light:hover {
			text-shadow: none;
			color: #333;
		}
	}
	&.button-reveal {
		&.button-light:hover {
			text-shadow: none;
			color: #333;
		}
		padding: 0 28px;
		overflow: hidden;
		i {
			display: block;
			position: absolute;
			top: 0;
			left: -32px;
			width: 32px;
			height: 40px;
			line-height: 40px;
			margin: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.1);
		}
		&.button-border i {
			top: -2px;
		}
		&.tright i {
			left: auto;
			right: -32px;
		}
		&.button-3d i, &.button-rounded i {
			border-radius: 3px 0 0 3px;
		}
		&.button-3d.tright i, &.button-rounded.tright i {
			border-radius: 0 3px 3px 0;
		}
		span {
			display: inline-block;
			position: relative;
			left: 0;
		}
	}
}

/* Buttons - Border
-----------------------------------------------------------------*/

/* Buttons - Icon Reveal
-----------------------------------------------------------------*/

body:not(.device-touch) .button.button-reveal {
	i, span {
		-webkit-transition: left 0.3s ease, right 0.3s ease;
		-o-transition: left 0.3s ease, right 0.3s ease;
		transition: left 0.3s ease, right 0.3s ease;
	}
}

.button {
	&.button-reveal {
		&.button-mini {
			padding: 0 17px;
			i {
				left: -22px;
				width: 22px;
				height: 28px;
				line-height: 28px;
			}
			&.tright i {
				left: auto;
				right: -22px;
			}
		}
		&.button-small {
			padding: 0 22px;
			i {
				left: -26px;
				width: 26px;
				height: 34px;
				line-height: 34px;
			}
			&.tright i {
				left: auto;
				right: -26px;
			}
		}
		&.button-large {
			padding: 0 32px;
			i {
				left: -38px;
				width: 38px;
				height: 46px;
				line-height: 46px;
			}
			&.tright i {
				left: auto;
				right: -38px;
			}
		}
		&.button-xlarge {
			padding: 0 40px;
			i {
				left: -44px;
				width: 44px;
				height: 52px;
				line-height: 52px;
			}
			&.tright i {
				left: auto;
				right: -44px;
			}
		}
		&:hover i {
			left: 0;
		}
		&.tright:hover i {
			left: auto;
			right: 0;
		}
		&:hover span {
			left: 16px;
		}
		&.button-mini:hover span {
			left: 11px;
		}
		&.button-small:hover span {
			left: 13px;
		}
		&.button-large:hover span {
			left: 19px;
		}
		&.button-xlarge:hover span {
			left: 22px;
		}
		&.tright:hover span {
			left: -16px;
		}
		&.button-mini.tright:hover span {
			left: -11px;
		}
		&.button-small.tright:hover span {
			left: -13px;
		}
		&.button-large.tright:hover span {
			left: -19px;
		}
		&.button-xlarge.tright:hover span {
			left: -22px;
		}
	}
	&.button-full {
		display: block !important;
		white-space: normal;
		margin: 0;
		height: auto;
		line-height: 1.6;
		padding: 30px 0;
		font-size: 30px;
		font-weight: 300;
		text-transform: none;
		border-radius: 0;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
		&.button-light {
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		}
		strong {
			font-weight: 600;
			border-bottom: 2px solid #EEE;
		}
	}
}

/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/

body:not(.device-touch) .button.button-full strong {
	-webkit-transition: border .2s ease-in-out;
	-o-transition: border .2s ease-in-out;
	transition: border .2s ease-in-out;
}

.button.button-full.button-light {
	strong {
		border-bottom-color: #333;
	}
	&:hover strong {
		border-bottom-color: #EEE;
	}
}

/* Buttons - Colors
-----------------------------------------------------------------*/

.button-red {
	background-color: #C02942;
}

.button-3d.button-red:hover, .button-reveal.button-red:hover {
	background-color: #C02942 !important;
}

.button-teal {
	background-color: #53777A;
}

.button-3d.button-teal:hover, .button-reveal.button-teal:hover {
	background-color: #53777A !important;
}

.button-yellow {
	background-color: #ECD078;
}

.button-3d.button-yellow:hover, .button-reveal.button-yellow:hover {
	background-color: #ECD078 !important;
}

.button-green {
	background-color: #59BA41;
}

.button-3d.button-green:hover, .button-reveal.button-green:hover {
	background-color: #59BA41 !important;
}

.button-brown {
	background-color: #774F38;
}

.button-3d.button-brown:hover, .button-reveal.button-brown:hover {
	background-color: #774F38 !important;
}

.button-aqua {
	background-color: #40C0CB;
}

.button-3d.button-aqua:hover, .button-reveal.button-aqua:hover {
	background-color: #40C0CB !important;
}

.button-lime {
	background-color: #AEE239;
}

.button-3d.button-lime:hover, .button-reveal.button-lime:hover {
	background-color: #AEE239 !important;
}

.button-purple {
	background-color: #5D4157;
}

.button-3d.button-purple:hover, .button-reveal.button-purple:hover {
	background-color: #5D4157 !important;
}

.button-leaf {
	background-color: #A8CABA;
}

.button-3d.button-leaf:hover, .button-reveal.button-leaf:hover {
	background-color: #A8CABA !important;
}

.button-pink {
	background-color: #F89FA1;
}

.button-3d.button-pink:hover, .button-reveal.button-pink:hover {
	background-color: #F89FA1 !important;
}

.button-dirtygreen {
	background-color: #1693A5;
}

.button-3d.button-dirtygreen:hover, .button-reveal.button-dirtygreen:hover {
	background-color: #1693A5 !important;
}

.button-blue {
	background-color: #1265A8;
}

.button-3d.button-blue:hover, .button-reveal.button-blue:hover {
	background-color: #1265A8 !important;
}

.button-amber {
	background-color: #EB9C4D;
}

.button-3d.button-amber:hover, .button-reveal.button-amber:hover {
	background-color: #EB9C4D !important;
}

.button-black {
	background-color: #111;
}

.button-3d.button-black:hover, .button-reveal.button-black:hover {
	background-color: #111 !important;
}

.button-white {
	background-color: #F9F9F9;
}

.button-3d.button-white:hover, .button-reveal.button-white:hover {
	background-color: #F9F9F9 !important;
}

/* Buttons - No Hover
-----------------------------------------------------------------*/

.button.button-nohover:hover {
	opacity: inherit !important;
	background-color: inherit !important;
	color: inherit !important;
	border-color: inherit !important;
}


/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/


#gotoTop {
	display: none;
	z-index: 299;
	position: fixed;
	width: 40px;
	height: 40px;
	background-color: rgba(#000000,0.3);
	font-size: 20px;
	line-height: 36px;
	text-align: center;
	color: $white;
	top: auto;
	left: auto;
	right: 30px;
	bottom: 50px;
	cursor: pointer;
	border-radius: 2px;

	&:hover {
		background-color: $aqua_cream;
		color: $blackened_slate;
	}
}

body:not(.device-touch) #gotoTop {
	@include transition(all 0.3s);
}

.stretched #gotoTop { bottom: 30px; }

/* Button Naira
-----------------------------------------------------------------*/

.button--naira {
	float: left;
	width: auto;
	display: block;
	border: 2px solid #6c737a;
	border-radius: 5px;
	background: none;
	color: inherit;
	position: relative;
	z-index: 1;
	font-family: $manifold !important;
	color: #6c737a;
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	padding: 24px 24px 18px 24px !important;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
	&::before {
		content: '';
		position: absolute;
		left: -50%;
		width: 200%;
		height: 200%;
		background: #6c737a;
		top: -50%;
		z-index: -1;
		-webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
		transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
	}
	&.button--inverted::before {
		background: #7986cb;
	}
}

.button--naira-up::before {
	-webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
	transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
}

.button--naira {
	> span {
		display: block;
	}
	.button__icon {
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		color: #60d9eb;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

.button--naira-up .button__icon {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}

.button--naira {
	.button__icon {
		padding: 20px;
		font-size: 22px;
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	> span {
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}
	&:hover::before {
		-webkit-animation: anim-naira-1 0.3s forwards ease-in;
		animation: anim-naira-1 0.3s forwards ease-in;
	}
}

.button--naira-up:hover::before {
	-webkit-animation: anim-naira-2 0.3s forwards ease-in;
	animation: anim-naira-2 0.3s forwards ease-in;
}

@-webkit-keyframes anim-naira-1 {
	50% {
		-webkit-transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
		transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
		transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
	}
}


@keyframes anim-naira-1 {
	50% {
		-webkit-transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
		transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
		transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
	}
}


@-webkit-keyframes anim-naira-2 {
	50% {
		-webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
		transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
		transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
	}
}


@keyframes anim-naira-2 {
	50% {
		-webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
		transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
		transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
	}
}


.button--naira {
	&:hover {
		background-color: #6c737a;
		-webkit-transition: background-color 0s 0.3s;
		transition: background-color 0s 0.3s;
	}
	&.button--inverted:hover {
		background-color: #6c737a;
	}
	&:hover {
		.button__icon {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		> span {
			opacity: 0;
			-webkit-transform: translate3d(0, 100%, 0);
			transform: translate3d(0, 100%, 0);
		}
	}
}

.button--naira-up:hover > span {
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}


/* Gradient Button
-----------------------------------------------------------------*/
.gradbtn {
	position: relative;
	box-sizing: border-box;
	box-shadow: none;
	font: 28px $manifold;
	padding: 16px 30px 18px;
	letter-spacing: 5px;
	text-transform: uppercase;
	border: solid 2px $txt_stone;
	color: $txt_stone;
	@include transition(all 0.45s);

	@include media($mobile_xsm) {
		font: 36px $manifold;
		padding: 16px 50px 18px;
	}

	&:before {
		content: '';
		@include vertical_align(absolute);
		left: -20px;
		@include size(0 2px);
		background: $concrete_lt;
		@include transition(all 0.45s $ease-in-out-quart);
	}

	&:hover,
	&:focus {
		color: $aqua_cream;
		border-color: $concrete_lt;

		&:before {
			background: $aqua_cream;
			width: calc(100% + 40px);
		}
	}
}
