/* ----------------------------------------------------------------
	Feet
-----------------------------------------------------------------*/

#footer {
	position: relative;
	background-color: #1d1f21;
	.footer-widgets-wrap {
		position: relative;
		padding: 80px 0;
	}
}

.footer-widgets-wrap {
	.col_full, .col_half, .col_one_third, .col_two_third, .col_three_fourth, .col_one_fourth, .col_one_fifth, .col_two_fifth, .col_three_fifth, .col_four_fifth, .col_one_sixth, .col_five_sixth {
		margin-bottom: 0;
	}
}

#copyrights {
	padding: 40px 0 14px 0;
	background-color: #1d1f21;
	color: #6f7479;
	font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
	font-size: 14px;
	line-height: 1.8;
	text-align: center;
}

p.copyright {
	padding-top: 14px;
	color: #6f7479;
}

#copyrights i.footer-icon {
	position: relative;
	top: 1px;
	font-size: 14px;
	width: 14px;
	text-align: center;
	margin-right: 3px;
}

.copyright-links a {
	display: inline-block;
	margin: 0 3px;
	color: #6f7479;
	&:hover {
		color: #7c9da7;
	}
}

.copyrights-menu {
	margin-bottom: 10px;
	a {
		font-size: 14px;
		margin: 0 10px;
		border-bottom: 0 !important;
	}
}

#copyrights {
	.col_half {
		margin-bottom: 0 !important;
	}
	a:first-child {
		margin-left: 0;
	}
	.tright a:last-child {
		margin-right: 0;
	}
}

.footer-logo {
	display: block;
	margin-bottom: 30px;
}

.signature {
	background: url('../images/signature.png') center center no-repeat;
	background-size: 576px 106px;
	width: 576px;
	height: 106px;
	display: block;
}
