input, select, textarea {
	text-shadow: none;
	outline: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	border: 1px solid #222628;
	border-radius: 3px;

	&:focus {
		color: #222628;
	}
}

select {
	border: 1px solid #222628;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
	padding: 3px;
}

textarea {
	padding-left: 3px;
	width: 100%;
}

/* ----------------------------------------------------------------
	Forms
-----------------------------------------------------------------*/

.input-holder {
	position: relative;
	i {
		position: absolute;
		font-size: 28px;
		line-height: 28px;
		top: 16px;
		left: 16px;
		color: #3a3d40;
		-webkit-transition: color ease-in-out 0.3s;
		-moz-transition: color ease-in-out 0.3s;
		-o-transition: color ease-in-out 0.3s;
		transition: color ease-in-out 0.3s;
	}
}

.formdivide {
	position: absolute;
	top: 10px;
	left: 58px;
	width: 2px;
	height: 40px;
	background: #3a3d40;
	-webkit-transition: background ease-in-out 0.3s;
	-moz-transition: background ease-in-out 0.3s;
	-o-transition: background ease-in-out 0.3s;
	transition: background ease-in-out 0.3s;
}

.sm-form-control {
	display: block;
	width: 100%;
	height: 38px;
	padding: 12px 12px 12px 72px;
	font-size: 15px;
	color: #61666b;
	letter-spacing: 1.3px;
	background: none;
	border: 2px solid #3a3d40;
	border-radius: 4px !important;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-webkit-transition: border-color ease-in-out 0.3s, color ease-in-out 0.3s;
	-moz-transition: border-color ease-in-out 0.3s, color ease-in-out 0.3s;
	-o-transition: border-color ease-in-out 0.3s, color ease-in-out 0.3s;
	transition: border-color ease-in-out 0.3s, color ease-in-out 0.3s;
}

.input-holder .sm-form-control:focus + {
	i {
		color: #00deff !important;
	}
	.formdivide {
		background: #626a73 !important;
	}
}

.sm-form-control {
	&:focus {
		border-color: #626a73;
		color: #858f9a;
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	&::-moz-placeholder {
		color: #4f5458;
		opacity: 1;
	}
	&:-ms-input-placeholder, &::-webkit-input-placeholder {
		color: #4f5458;
	}
	&[disabled], &[readonly] {
		cursor: not-allowed;
		background-color: #eeeeee;
		opacity: 1;
	}
}

fieldset[disabled] .sm-form-control {
	cursor: not-allowed;
	background-color: #eeeeee;
	opacity: 1;
}

textarea {
	resize: none !important;
}

.sm-form-control {
	height: auto;
}

.form-control {
	border-radius: 3px;
	&:active, &:focus {
		border-color: #999;
		box-shadow: none;
	}
}

label {
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	font-family: $header_font;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #555;
	margin-bottom: 10px;
	cursor: pointer;
	&.label-muted {
		color: #999;
		font-weight: normal;
		margin-right: 5px;
	}
}

form {
	.col_full, .col_half, .col_one_third, .col_two_third, .col_three_fourth, .col_one_fourth, .col_one_fifth, .col_two_fifth, .col_three_fifth, .col_four_fifth, .col_one_sixth, .col_five_sixth {
		margin-bottom: 25px;
		z-index: 10;
	}
}

/* ----------------------------------------------------------------
	Contact Form
-----------------------------------------------------------------*/

#contact-form-overlay {
	position: relative;
	max-width: 800px;
	font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
	background-color: #1d1f21 !important;
	padding: 40px;
	margin: 0 auto;
	z-index: 10;
	border-radius: 4px;
}

#contact-form-overlay-mini {
	position: relative;
	max-width: 800px;
	font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
	background-color: #1d1f21 !important;
	padding: 40px;
	margin: 0 auto;
	z-index: 10;
	border-radius: 4px;
	float: right;
	width: 380px;
	margin: 0;
}

label.error {
	margin-top: 5px;
	color: #E74B4C;
	font-weight: 400;
	display: none !important;
}

.form-control.error, .sm-form-control.error {
	border-color: #E74B4C;
}
