html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
	display: block;
}

body {
	line-height: 1;
	overflow: hidden;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote {
	&:before, &:after {
		content: '';
		content: none;
	}
}

q {
	&:before, &:after {
		content: '';
		content: none;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ---- */

dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow: hidden;
}

::selection {
	background: #b2bcc4;
	color: #ffe893;
	text-shadow: none;
}

::-moz-selection {
	background: #b2bcc4;
	/* Firefox */
	color: #ffe893;
	text-shadow: none;
}

::-webkit-selection {
	background: #b2bcc4;
	/* Safari */
	color: #ffe893;
	text-shadow: none;
}

:active, :focus {
	outline: none !important;
}

.fright {
	float: right !important;
}

.fleft {
	float: left !important;
}

.fnone {
	float: none !important;
}

.ohidden {
	position: relative;
	overflow: hidden !important;
}

#wrapper {
	position: relative;
	float: none;
	width: 1220px;
	margin: 0 auto;
	background-color: #222427;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.line, .double-line {
	clear: both;
	position: relative;
	width: 100%;
	margin: 60px 0;
	// border-top: 1px solid #EEE;
}

.line.line-sm {
	margin: 30px 0;
}

span.middot {
	display: inline-block;
	margin: 0 5px;
}

.double-line {
	border-top: 3px double #E5E5E5;
}

.emptydiv {
	display: block !important;
	position: relative !important;
}

.allmargin {
	margin: 50px !important;
}

.leftmargin {
	margin-left: 50px !important;
}

.rightmargin {
	margin-right: 50px !important;
}

.topmargin {
	margin-top: 50px !important;
}

.bottommargin {
	margin-bottom: 50px !important;
}

.clear-bottommargin {
	margin-bottom: -50px !important;
}

.allmargin-sm {
	margin: 30px !important;
}

.leftmargin-sm {
	margin-left: 30px !important;
}

.rightmargin-sm {
	margin-right: 30px !important;
}

.topmargin-sm {
	margin-top: 30px !important;
}

.bottommargin-sm {
	margin-bottom: 0 !important;

	@include media($mobile_mlg) {
		margin-bottom: 30px !important;
	}
}

.bottommargin-xsm {
	margin-bottom: 20px;
}

.clear-bottommargin-sm {
	margin-bottom: -30px !important;
}

.allmargin-lg {
	margin: 80px !important;
}

.leftmargin-lg {
	margin-left: 80px !important;
}

.rightmargin-lg {
	margin-right: 80px !important;
}

.topmargin-lg {
	margin-top: 80px !important;
}

.bottommargin-lg {
	margin-bottom: 80px !important;
}

.clear-bottommargin-lg {
	margin-bottom: -80px !important;
}

.nomargin {
	margin: 0 !important;
}

.noleftmargin {
	margin-left: 0 !important;
}

.norightmargin {
	margin-right: 0 !important;
}

.notopmargin {
	margin-top: 0 !important;
}

.nobottommargin {
	margin-bottom: 0 !important;
}

.header-stick {
	margin-top: -50px !important;
}

.content-wrap .header-stick {
	margin-top: -80px !important;
}

.footer-stick {
	margin-bottom: -50px !important;
}

.content-wrap .footer-stick {
	margin-bottom: -80px !important;
}

.noborder {
	border: none !important;
}

.noleftborder {
	border-left: none !important;
}

.norightborder {
	border-right: none !important;
}

.notopborder {
	border-top: none !important;
}

.nobottomborder {
	border-bottom: none !important;
}

.noradius {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	-o-border-radius: 0 !important;
	border-radius: 0 !important;
}

.col-padding {
	padding: 60px;
}

.nopadding {
	padding: 0 !important;
}

.noleftpadding {
	padding-left: 0 !important;
}

.norightpadding {
	padding-right: 0 !important;
}

.notoppadding {
	padding-top: 0 !important;
}

.nobottompadding {
	padding-bottom: 0 !important;
}

.noabsolute {
	position: relative !important;
}

.noshadow {
	box-shadow: none !important;
}

.hidden {
	display: none !important;
}

.nothidden {
	display: block !important;
}

.inline-block {
	float: none !important;
	display: inline-block !important;
}

.center {
	text-align: center !important;
}

.divcenter {
	position: relative !important;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.bgcolor {
	background-color: $aqua_cream !important;
	#header-wrap {
		background-color: $aqua_cream !important;
	}
}

.color {
	color: $aqua_cream !important;
}

.border-color {
	border-color: $aqua_cream !important;
}

.nobg {
	background: none !important;
}

.nobgcolor {
	background-color: transparent !important;
}

.bgicon {
	display: block;
	position: absolute;
	bottom: -60px;
	right: -50px;
	font-size: 210px;
	color: rgba(0, 0, 0, 0.1);
}

.bganimate {
	-webkit-animation: BgAnimated 30s infinite linear;
	-moz-animation: BgAnimated 30s infinite linear;
	-ms-animation: BgAnimated 30s infinite linear;
	-o-animation: BgAnimated 30s infinite linear;
	animation: BgAnimated 30s infinite linear;
}

@-webkit-keyframes BgAnimated {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 400px;
	}
}


@-moz-keyframes BgAnimated {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 400px;
	}
}


@-ms-keyframes BgAnimated {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 400px;
	}
}


@-o-keyframes BgAnimated {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 400px;
	}
}


@keyframes BgAnimated {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 400px;
	}
}


.input-block-level {
	display: block;
	width: 100% !important;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.vertical-middle {
	height: auto !important;
}

.magnific-max-width .mfp-content {
	max-width: 800px;
}

p {
	&.darkness {
		color: #101214;
		font-size: 12px !important;
		letter-spacing: 0;
		margin: 0 0 12px 0;
	}
	&.narrow {
		margin: 8px 0;
	}
}

a.base-a {
	color: #b2bcc4;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $aqua_cream;
	}
}

.glitch {
	width: 32px;
	height: 6px;
}

.sglitch {
	width: 6px;
	height: 32px;
}

.g-aqua {
	background: $aqua_cream;
}

.g-aquaglow {
	background: #b6ecff;
}

.g-aquacream {
	background: $aqua_cream;
}

.g-concrete {
	background: #3a3d40;
}

.g-dconcrete {
	background: #222427;
}


.g-darkness {
	background: #101214;
}

.g-light {
	background: #edf8fb;
}

.g-bottom {
	margin-bottom: 32px;
}

.g-bottom-small {
	margin-bottom: 18px;
}

.g-top {
	margin-top: 12px;
}

.g-top-med {
	margin-top: 25px;
}

.g-top-lg {
	margin-top: 75px;
}

.g-top-big {
	margin-top: 85px;
}

.lineglitch {
	width: 104px;
	height: 2px;
	display: block;
	/*position: absolute;*/
}

/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/

.section {
	position: relative;
	margin: 60px 0;
	padding: 60px 0;
	background-color: #222427;
	overflow: hidden;
}

.section-icons {
	width: 74px;
	height: 74px;
	margin: 0 0 14px 0;
}

.dot-grid {
	background-image: url('../images/dotgrid.png');
}

.sec-light {
	background: #292c2f;
}

.parallax {
	background-color: transparent;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
	overflow: hidden;
}

.mobile-parallax {
	background-size: cover !important;
	background-attachment: scroll !important;
	background-position: center center;
}

.about-prlx {
	.background {
		position: absolute;
		top: -15%;
		@include size(100% 120%);
		background-image: url('../images/parallax/about.jpg');
		background-color: #222427;
		background-size: inherit;

		@include media($desktop_mds) {
			background-size: cover;
		}
	}
}

.skills-prlx {
	.background {
		position: absolute;
		top: -15%;
		@include size(100% 120%);
		background-image: url('../images/parallax/skills.jpg');
		background-color: #222427;
		background-size: inherit;

		@include media($desktop_mds) {
			background-size: cover;
		}
	}
}

.work-prlx {
	.background {
		position: absolute;
		top: -15%;
		@include size(100% 120%);
		background-image: url('../images/parallax/polygrid.svg');
		background-color: #1e2022;
		background-size: cover;
	}
}

.testimonials-prlx  {
	.background {
		position: absolute;
		top: -15%;
		@include size(100% 120%);
		background-image: url('../images/parallax/beach.jpg');
		background-color: #222427;
		background-size: inherit;

		@include media($desktop_mds) {
			background-size: cover;
		}
	}
}

.contact-prlx {
	.background {
		position: absolute;
		top: -15%;
		@include size(100% 110%);
		background: url('../images/parallax/contact.jpg') center bottom no-repeat #1d1f21;
		background-size: cover;
	}
}

body.device-touch {
	.contact-prlx {
		.background {
			top: 0;
		}
	}
}

.section .container {
	z-index: 2;
	+ .video-wrap {
		z-index: 1;
	}
}

.revealer-image {
	position: relative;
	bottom: -100px;
	transition: bottom .3s ease-in-out;
	-webkit-transition: bottom .3s ease-in-out;
	-o-transition: bottom .3s ease-in-out;
}

.section:hover .revealer-image {
	bottom: -50px;
}

/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/

#content {
	position: relative;
	overflow: hidden;
	background-color: #FFF;

	p {
		margin-bottom: 30px;
		font-size: rem(14);

		@include media($mobile_lg) {
			font-size: 1rem;
		}
	}
}

.content-wrap {
	position: relative;
	padding: 80px 0;
}

#content .container {
	position: relative;
}


/* ----------------------------------------------------------------
	Promo Boxes
-----------------------------------------------------------------*/

.promo {
	position: relative;
	padding: 30px 200px 30px 0;
	h3 {
		font-weight: bold;
		margin-bottom: 0;
	}
	> {
		span, .container > span {
			display: block;
			color: #444;
			font-weight: 300;
			font-size: 16px;
			margin-top: 6px;
		}
	}
	a.button {
		position: absolute;
		top: 50%;
		margin: 0;
		margin-top: -18px;
		left: auto;
		right: 0;
		&.button-mini {
			margin-top: -12px;
		}
		&.button-small {
			margin-top: -15px;
		}
		&.button-large {
			margin-top: -22px;
		}
		&.button-xlarge {
			margin-top: -25px;
		}
		&.button-desc {
			margin-top: -40px;
		}
	}
}

/* Promo Uppercase
-----------------------------------------------------------------*/

.promo-uppercase {
	text-transform: uppercase;
	h3 {
		font-size: 22px;
	}
	> {
		span, .container > span {
			font-size: 15px;
		}
	}
}

/* Promo - with Border
-----------------------------------------------------------------*/

.promo {
	&.promo-border {
		border: 1px solid #E5E5E5;
		border-radius: 3px;
		padding-left: 30px;
		a.button {
			right: 30px;
		}
	}
	&.promo-light {
		background-color: #F5F5F5;
		border-radius: 3px;
		padding-left: 30px;
		a.button {
			right: 30px;
		}
	}
	&.promo-dark {
		border: 0 !important;
		background-color: #333;
		padding: 30px 200px 30px 30px;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		h3 {
			color: #FFF;
		}
		> {
			h3 span, .container > h3 span {
				padding: 2px 8px;
				border-radius: 3px;
				background-color: rgba(0, 0, 0, 0.15);
				color: #FFF;
				font-weight: 400;
			}
			span, .container > span {
				color: #CCC;
				margin-top: 8px;
			}
		}
		a.button {
			right: 30px;
		}
	}
	&.promo-flat {
		background-color: $aqua_cream;
		> {
			span, .container > span {
				color: rgba(255, 255, 255, 0.9);
			}
		}
		a.button {
			&:not(.button-border) {
				background-color: rgba(0, 0, 0, 0.2);
				color: #FFF;
			}
			&:hover {
				background-color: #FFF;
				border-color: #FFF;
				color: #444;
				text-shadow: none;
			}
		}
	}
}

/* Promo - Light
-----------------------------------------------------------------*/

/* Promo - Dark
-----------------------------------------------------------------*/

/* Promo - Flat
-----------------------------------------------------------------*/

/* Promo - 100% Full Width
-----------------------------------------------------------------*/

.promo-full {
	border-radius: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	padding: 40px 0 !important;
	a.button {
		right: 15px !important;
	}
	&.promo-right a.button {
		right: auto !important;
		left: 15px !important;
	}
}

/* Promo - Parallax
-----------------------------------------------------------------*/

.promo {
	&.parallax {
		padding: 80px 0 !important;
	}
	&.promo-right {
		text-align: right;
		padding: 30px 0 30px 200px;
		a.button {
			left: 0;
			right: auto;
		}
		&.promo-border:not(.promo-mini) {
			padding-right: 30px;
		}
	}
	&.promo-dark.promo-right:not(.promo-mini) {
		padding-right: 30px;
	}
	&.promo-right.promo-border a.button, &.promo-dark.promo-right a.button {
		left: 30px;
	}
	&.promo-center {
		text-align: center;
		padding: 30px 20px;
		a.button {
			position: relative;
			top: 0;
			margin: 20px 0 0;
			left: 0 !important;
			right: 0;
		}
	}
	&.promo-mini {
		text-align: left;
		padding: 20px 25px;
		h3 {
			font-size: 20px;
			line-height: 1.5;
		}
		&.promo-uppercase h3 {
			font-size: 18px;
		}
		&.promo-dark h3 {
			line-height: 1.7;
		}
		a.button {
			position: relative;
			top: 0;
			margin: 20px 0 0;
			left: 0 !important;
			right: 0;
		}
		&.promo-center {
			text-align: center;
		}
		&.promo-right {
			text-align: right;
		}
	}
}

/* Promo - Right Align
-----------------------------------------------------------------*/

/* Promo - with Border & Right Align
-----------------------------------------------------------------*/

/* Promo - Center Align
-----------------------------------------------------------------*/

/* Promo - Mini
-----------------------------------------------------------------*/

/* Promo - Mini - Center Align
-----------------------------------------------------------------*/

/* Promo - Mini - Right Align
-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Featured Boxes
-----------------------------------------------------------------*/

.feature-box {
	position: relative;
	margin-top: 20px;
	padding: 0 0 0 80px;
	&:first-child {
		margin-top: 0;
	}
	.fbox-icon {
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		top: 0;
		left: 0;
		a, i, img {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			color: #FFF;
		}
		i, img {
			border-radius: 50%;
			background-color: $aqua_cream;
		}
		i {
			font-style: normal;
			font-size: 28px;
			text-align: center;
			line-height: 64px;
		}
	}
	h3 {
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: 0;
		color: #333;
	}
	p {
		margin: 8px 0 0 0;
		color: #999;
	}
	.before-heading {
		font-size: 14px;
	}
	&.fbox-large {
		padding: 0 0 0 115px;
		.fbox-icon {
			width: 96px;
			height: 96px;
			i {
				font-size: 42px;
				line-height: 96px;
			}
		}
	}
	&.fbox-outline {
		.fbox-icon {
			border: 1px solid $aqua_cream;
			border-radius: 50%;
			padding: 3px;
			i {
				line-height: 56px;
			}
		}
		&.fbox-large .fbox-icon {
			padding: 4px;
			i {
				line-height: 86px;
			}
		}
	}
	&.fbox-rounded {
		.fbox-icon {
			border-radius: 3px !important;
			i, img {
				border-radius: 3px !important;
			}
		}
		&.fbox-large .fbox-icon {
			border-radius: 4px !important;
			i, img {
				border-radius: 4px !important;
			}
		}
	}
	&.fbox-light {
		&.fbox-outline .fbox-icon {
			border-color: #E5E5E5;
		}
		.fbox-icon {
			i, img {
				border: 1px solid #E5E5E5;
				background-color: #F5F5F5;
				color: #444;
			}
			i {
				line-height: 62px;
			}
		}
		&.fbox-outline .fbox-icon i {
			line-height: 54px;
		}
		&.fbox-large .fbox-icon i {
			line-height: 96px;
		}
		&.fbox-outline.fbox-large .fbox-icon i {
			line-height: 84px;
		}
	}
	&.fbox-dark {
		&.fbox-outline .fbox-icon {
			border-color: #444;
		}
		.fbox-icon {
			i, img {
				background-color: #333;
			}
		}
	}
	&.fbox-border {
		.fbox-icon {
			border: 1px solid $aqua_cream;
			border-radius: 50%;
			i, img {
				border: none;
				background-color: transparent !important;
				color: $aqua_cream;
			}
			padding: 0;
			i {
				line-height: 62px !important;
			}
		}
		&.fbox-large .fbox-icon i {
			line-height: 94px !important;
		}
		&.fbox-light .fbox-icon {
			border-color: #E5E5E5;
			i, img {
				color: #888;
			}
		}
		&.fbox-dark .fbox-icon {
			border-color: #333;
			i, img {
				color: #444;
			}
		}
	}
	&.fbox-plain {
		.fbox-icon {
			border: none !important;
			height: auto !important;
			i, img {
				border: none !important;
				background-color: transparent !important;
				color: $aqua_cream;
				border-radius: 0;
			}
			i {
				font-size: 48px;
				line-height: 1 !important;
			}
			img {
				height: 64px;
			}
		}
		&.fbox-image .fbox-icon {
			width: auto;
			img {
				width: auto;
				display: inline-block;
			}
		}
		&.fbox-small {
			padding-left: 42px;
			.fbox-icon {
				width: 28px;
			}
			h3 {
				font-size: 15px;
				line-height: 26px;
				margin-bottom: 10px;
			}
			p {
				margin-left: -42px;
			}
			.fbox-icon {
				i {
					font-size: 28px;
				}
				img {
					height: 28px;
				}
			}
		}
		&.fbox-large .fbox-icon {
			i {
				font-size: 72px;
			}
			img {
				height: 96px;
			}
		}
		&.fbox-light .fbox-icon {
			i, img {
				color: #888;
			}
		}
		&.fbox-dark .fbox-icon {
			i, img {
				color: #444;
			}
		}
	}
	&.fbox-center {
		padding: 0;
		text-align: center;
		.fbox-icon {
			position: relative;
			width: 96px;
			height: 96px;
			margin: 0 auto 30px;
			i {
				font-size: 42px;
				line-height: 96px;
			}
		}
		&.fbox-outline .fbox-icon i {
			line-height: 88px;
		}
		p {
			font-size: 14px;
			margin-top: 15px;
		}
		&.fbox-large {
			.fbox-icon {
				width: 128px;
				height: 128px;
				i {
					font-size: 70px;
					line-height: 128px;
				}
			}
			&.fbox-outline .fbox-icon i {
				line-height: 118px;
			}
		}
		&.fbox-light {
			.fbox-icon i {
				line-height: 94px;
			}
			&.fbox-outline {
				.fbox-icon i {
					line-height: 86px;
				}
				&.fbox-large .fbox-icon i {
					line-height: 114px;
				}
			}
		}
		&.fbox-border .fbox-icon i {
			line-height: 94px !important;
		}
		&.fbox-large.fbox-border .fbox-icon i {
			line-height: 126px !important;
		}
		&.fbox-plain .fbox-icon {
			i {
				font-size: 84px;
			}
			img {
				height: 96px;
			}
		}
		&.fbox-large.fbox-plain .fbox-icon {
			i {
				font-size: 112px;
			}
			img {
				height: 128px;
			}
		}
		&.fbox-small {
			.fbox-icon {
				width: 64px;
				height: 64px;
				i {
					font-size: 28px;
					line-height: 64px;
				}
			}
			&.fbox-outline .fbox-icon i {
				line-height: 58px;
			}
		}
		&.fbox-light.fbox-outline.fbox-small .fbox-icon i {
			line-height: 54px;
		}
		&.fbox-small {
			&.fbox-border .fbox-icon i {
				line-height: 62px !important;
			}
			&.fbox-plain .fbox-icon {
				i {
					font-size: 56px;
				}
				img {
					height: 64px;
				}
			}
			p {
				font-size: 14px;
				margin-top: 10px;
			}
		}
		&.fbox-plain.fbox-small p {
			margin-left: 0;
		}
	}
	&.fbox-right {
		padding: 0 80px 0 0;
		text-align: right;
		&.fbox-large {
			padding-right: 115px;
		}
		.fbox-icon {
			left: auto;
			right: 0;
		}
		&.fbox-plain.fbox-small {
			padding: 0 42px 0 0;
			p {
				margin: 0 -42px 0 0;
			}
		}
	}
	h3 {
		+ .fbox-icon {
			margin-top: 20px !important;
		}
		span.subtitle {
			display: block;
			margin-top: 5px;
			color: #444;
			font-weight: 300;
			text-transform: none;
		}
	}
	&.fbox-center {
		&:not(.fbox-bg) {
			h3:after {
				content: '';
				display: block;
				margin: 20px auto;
				width: 30px;
				border-top: 2px solid #555;
				-webkit-transition: width .3s ease;
				-o-transition: width .3s ease;
				transition: width .3s ease;
			}
			&:hover h3:after {
				width: 50px;
			}
		}
		&.noborder:not(.fbox-bg) h3:after, &.nobottomborder:not(.fbox-bg) h3:after {
			display: none;
		}
		&.fbox-italic p {
			font-style: italic;
			font-family: $header_font;
		}
	}
	&.fbox-bg.fbox-center {
		margin-top: 68px;
		padding: 68px 30px 30px;
		background-color: #FFF;
		border: 1px solid #E5E5E5;
		border-radius: 5px;
		&:first-child {
			margin-top: 48px;
		}
		&.fbox-large {
			margin-top: 84px;
			padding-top: 84px;
			&:first-child {
				margin-top: 64px;
			}
		}
		.fbox-icon {
			position: absolute;
			top: -48px;
			left: 50%;
			margin: 0 0 0 -48px;
		}
		&.fbox-large .fbox-icon {
			top: -64px;
			left: 50%;
			margin-left: -64px;
		}
		&.fbox-plain .fbox-icon, &.fbox-border .fbox-icon, &.fbox-outline .fbox-icon {
			background-color: #FFF;
		}
	}
}

/* Feature Box - Icon Large
-----------------------------------------------------------------*/

/* Feature Box - Icon Outline
-----------------------------------------------------------------*/

/* Feature Box - Icon Outline Large
-----------------------------------------------------------------*/

/* Feature Box - Icon Rounded
-----------------------------------------------------------------*/

/* Feature Box - Icon Rounded & Large
-----------------------------------------------------------------*/

/* Feature Box - Light Background
-----------------------------------------------------------------*/

/* Feature Box - Dark Background
-----------------------------------------------------------------*/

/* Feature Box - Border
-----------------------------------------------------------------*/

/* Feature Box - Border - Light
-----------------------------------------------------------------*/

/* Feature Box - Border - Dark
-----------------------------------------------------------------*/

/* Feature Box - Plain
-----------------------------------------------------------------*/

/* Feature Box - Center
-----------------------------------------------------------------*/

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/

/* Feature Box - Center & Large Icons
-----------------------------------------------------------------*/

/* Feature Box - Right
-----------------------------------------------------------------*/

/* Feature Box - Subtitle
-----------------------------------------------------------------*/

/* Feature Box - Background
-----------------------------------------------------------------*/

/* Animated Icon Hovers
-----------------------------------------------------------------*/

.fbox-effect .fbox-icon i {
	z-index: 1;
	&:after {
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		content: '';
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}
}

.fbox-rounded.fbox-effect .fbox-icon i:after {
	border-radius: 3px;
}

.fbox-effect {
	.fbox-icon i {
		-webkit-transition: background-color 0.3s, color 0.3s;
		-o-transition: background-color 0.3s, color 0.3s;
		transition: background-color 0.3s, color 0.3s;
		&:after {
			top: -3px;
			left: -3px;
			padding: 3px;
			box-shadow: 0 0 0 2px #333;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			-webkit-transform: scale(0.8);
			-moz-transition: -moz-transform 0.3s, opacity 0.3s;
			-moz-transform: scale(0.8);
			-ms-transform: scale(0.8);
			transition: transform 0.3s, opacity 0.3s;
			transform: scale(0.8);
			opacity: 0;
		}
		&:hover {
			background-color: #333;
			color: #FFF;
		}
	}
	&:hover .fbox-icon i {
		background-color: #333;
		color: #FFF;
	}
	&.fbox-dark {
		.fbox-icon i {
			&:after {
				box-shadow: 0 0 0 2px $aqua_cream;
			}
			&:hover {
				background-color: $aqua_cream;
			}
		}
		&:hover .fbox-icon i {
			background-color: $aqua_cream;
		}
	}
	.fbox-icon i:hover:after, &:hover .fbox-icon i:after {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

/* Icon Effects - Bordered
-----------------------------------------------------------------*/

.fbox-border {
	&.fbox-effect .fbox-icon i {
		-webkit-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
		-o-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
		transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
		&:after {
			top: -2px;
			left: -2px;
			padding: 2px;
			z-index: -1;
			box-shadow: none;
			background-image: url('../images/icons/iconalt.svg');
			background-position: center center;
			background-size: 100% 100%;
			-webkit-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
			-o-transition: -moz-transform 0.5s, opacity 0.5s, background-color 0.5s;
			transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
		}
	}
	&.fbox-rounded.fbox-effect .fbox-icon i:after {
		border-radius: 3px;
	}
	&.fbox-effect {
		.fbox-icon i:hover, &:hover .fbox-icon i {
			color: #FFF;
			box-shadow: 0 0 0 1px #333;
		}
		.fbox-icon i:after {
			-webkit-transform: scale(1.3);
			-moz-transform: scale(1.3);
			-ms-transform: scale(1.3);
			transform: scale(1.3);
			opacity: 0;
			background-color: #333;
		}
		&.fbox-dark {
			.fbox-icon i:hover, &:hover .fbox-icon i {
				box-shadow: 0 0 0 1px $aqua_cream;
			}
			.fbox-icon i:after {
				background-color: $aqua_cream;
			}
		}
		.fbox-icon i:hover:after, &:hover .fbox-icon i:after {
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}
}

/* Media Featured Box
-----------------------------------------------------------------*/

.feature-box.media-box {
	padding: 0;
	.fbox-media {
		margin: 0 0 25px;
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		a, img {
			position: relative;
			display: block;
			width: 100%;
			height: auto;
		}
		iframe {
			display: block;
		}
	}
	p {
		margin-top: 17px;
	}
	&.fbox-bg {
		.fbox-media {
			margin: 0;
		}
		.fbox-desc {
			padding: 25px;
			background-color: #FFF;
			border: 1px solid #E5E5E5;
			border-top: 0;
			border-radius: 0 0 5px 5px;
		}
		.fbox-media img {
			border-radius: 5px 5px 0 0;
		}
	}
}


/* ----------------------------------------------------------------
	Bootstrap Specific
-----------------------------------------------------------------*/

.table {
	.success {
		color: #3c763d;
	}
	.info {
		color: #31708f;
	}
	.warning {
		color: #8a6d3b;
	}
	.danger {
		color: #a94442;
	}
}

.btn-link {
	color: $aqua_cream;
	&:hover, &:focus {
		color: #222;
	}
}

.boot-tabs {
	margin-bottom: 20px !important;
}

.carousel-control {
	.icon-chevron-left, .icon-chevron-right {
		position: absolute;
		top: 47.5%;
		z-index: 5;
		display: inline-block;
	}
	.icon-chevron-left {
		left: 50%;
	}
	.icon-chevron-right {
		right: 50%;
	}
}

.responsive-utilities th small {
	display: block;
	font-weight: 400;
	color: #999;
}

.show-grid [class^=col-] {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #EEE;
	border: 1px solid #DDD;
}

.img-circle {
	border-radius: 50% !important;
}

.pagination > .active > {
	a, span, a:hover, span:hover, a:focus, span:focus {
		color: #FFF !important;
		background-color: $aqua_cream !important;
		border-color: $aqua_cream !important;
	}
}

label {
	&.radio, &.checkbox {
		padding-left: 20px;
	}
}

.input-group-lg > .input-group-addon {
	line-height: 1;
}

/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/

.stretched #wrapper {
	width: 100%;
	min-height: 100%;
	margin: 0;
	box-shadow: none;
}

/* ----------------------------------------------------------------
	Page Transitions
-----------------------------------------------------------------*/

body:not(.no-transition) #wrapper, .animsition-overlay {
	position: relative;
	opacity: 0;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	text-align: center;
	background-color: #FFF;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	> div {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -9px;
		margin-left: 13px;
		width: 18px;
		height: 18px;
		background-color: #DDD;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
		animation: bouncedelay 1.4s infinite ease-in-out;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	.css3-spinner-bounce1 {
		margin-left: -31px;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	.css3-spinner-bounce2 {
		margin-left: -9px;
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
	> {
		.css3-spinner-flipper {
			width: 32px;
			height: 32px;
			margin-top: -16px;
			margin-left: -16px;
			border-radius: 0;
			-webkit-animation: rotateplane 1.2s infinite ease-in-out;
			animation: rotateplane 1.2s infinite ease-in-out;
		}
		.css3-spinner-double-bounce1 {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			margin-left: -20px;
			border-radius: 50%;
			opacity: 0.6;
			-webkit-animation: cssspinnerbounce 2.0s infinite ease-in-out;
			animation: cssspinnerbounce 2.0s infinite ease-in-out;
		}
		.css3-spinner-double-bounce2 {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			margin-left: -20px;
			border-radius: 50%;
			opacity: 0.6;
			-webkit-animation: cssspinnerbounce 2.0s infinite ease-in-out;
			animation: cssspinnerbounce 2.0s infinite ease-in-out;
			-webkit-animation-delay: -1.0s;
			animation-delay: -1.0s;
		}
		.css3-spinner-rect1, .css3-spinner-rect2, .css3-spinner-rect3, .css3-spinner-rect4, .css3-spinner-rect5 {
			height: 30px;
			width: 6px;
			margin-top: -15px;
			margin-left: -21px;
			border-radius: 0;
			-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
			animation: stretchdelay 1.2s infinite ease-in-out;
		}
		.css3-spinner-rect2 {
			margin-left: -12px;
			-webkit-animation-delay: -1.1s;
			animation-delay: -1.1s;
		}
		.css3-spinner-rect3 {
			margin-left: -3px;
			-webkit-animation-delay: -1.0s;
			animation-delay: -1.0s;
		}
		.css3-spinner-rect4 {
			margin-left: 6px;
			-webkit-animation-delay: -0.9s;
			animation-delay: -0.9s;
		}
		.css3-spinner-rect5 {
			margin-left: 15px;
			-webkit-animation-delay: -0.8s;
			animation-delay: -0.8s;
		}
		.css3-spinner-cube1 {
			width: 16px;
			height: 16px;
			border-radius: 0;
			margin-top: -20px;
			margin-left: -20px;
			-webkit-animation: cubemove 1.8s infinite ease-in-out;
			animation: cubemove 1.8s infinite ease-in-out;
		}
		.css3-spinner-cube2 {
			width: 16px;
			height: 16px;
			border-radius: 0;
			margin-top: -20px;
			margin-left: -20px;
			-webkit-animation: cubemove 1.8s infinite ease-in-out;
			animation: cubemove 1.8s infinite ease-in-out;
			-webkit-animation-delay: -0.9s;
			animation-delay: -0.9s;
		}
		.css3-spinner-scaler {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			margin-left: -20px;
			-webkit-animation: scaleout 1.0s infinite ease-in-out;
			animation: scaleout 1.0s infinite ease-in-out;
		}
	}
}

@-webkit-keyframes bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
	}
}


@keyframes bouncedelay {
	0%, 80%, 100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}


@-webkit-keyframes rotateplane {
	0% {
		-webkit-transform: perspective(120px);
	}

	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
	}

	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
}


@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}

	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}


@-webkit-keyframes cssspinnerbounce {
	0%, 100% {
		-webkit-transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
	}
}


@keyframes cssspinnerbounce {
	0%, 100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}


@-webkit-keyframes stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
	}

	20% {
		-webkit-transform: scaleY(1);
	}
}


@keyframes stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}


@-webkit-keyframes cubemove {
	25% {
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}

	50% {
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}

	75% {
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}

	100% {
		-webkit-transform: rotate(-360deg);
	}
}


@keyframes cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}

	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}

	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}

	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}

	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
}


@-webkit-keyframes scaleout {
	0% {
		-webkit-transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		opacity: 0;
	}
}


@keyframes scaleout {
	0% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	100% {
		transform: scale(1);
		-webkit-transform: scale(1);
		opacity: 0;
	}
}
