/* ----------------------------------------------------------------
	Toggle
-----------------------------------------------------------------*/

.toggle {
	display: block;
	position: relative;
	margin: 0 0 20px 0;
	.togglet, .toggleta {
		display: block;
		position: relative;
		line-height: 24px;
		padding: 0 0 0 24px;
		margin: 0;
		font-size: 16px;
		font-weight: 600;
		color: #444;
		cursor: pointer;
	}
	.togglet i {
		position: absolute;
		top: 0;
		left: 0;
		width: 16px;
		text-align: center;
		font-size: 16px;
		line-height: 24px;
	}
	.toggleta {
		font-weight: bold;
	}
	.togglet i.toggle-open {
		display: none;
	}
	.toggleta i {
		&.toggle-closed {
			display: none;
		}
		&.toggle-open {
			display: block;
		}
	}
	.togglec {
		display: block;
		position: relative;
		padding: 10px 0 0 24px;
	}
	&.toggle-bg {
		.togglet, .toggleta {
			background-color: #EEE;
			line-height: 44px;
			padding: 0 0 0 36px;
			border-radius: 2px;
		}
		.togglet i {
			left: 14px;
			line-height: 44px;
		}
		.togglec {
			padding: 12px 0 0 36px;
		}
	}
	&.toggle-border {
		border: 1px solid #CCC;
		border-radius: 4px;
		.togglet, .toggleta {
			line-height: 44px;
			padding: 0 15px 0 36px;
		}
		.togglet i {
			left: 14px;
			line-height: 44px;
		}
		.togglec {
			padding: 0 15px 15px 36px;
		}
	}
}

/* Toggle - with Title Background
-----------------------------------------------------------------*/

/* Toggle - Bordered
-----------------------------------------------------------------*/

/* Toggle - FAQs
-----------------------------------------------------------------*/

.faqs .toggle {
	border-bottom: 1px solid #EEE;
	padding-bottom: 12px;
	margin-bottom: 12px;
	.togglet, .toggleta {
		font-size: 15px;
	}
	.togglet i {
		top: 1px;
		font-size: 14px;
	}
	.togglec {
		padding-bottom: 10px;
	}
}

/* ----------------------------------------------------------------
	Accordions
-----------------------------------------------------------------*/

.accordion {
	margin-bottom: 20px;
}

.acctitle {
	display: block;
	position: relative;
	line-height: 24px;
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	color: #444;
	cursor: pointer;
	border-top: 1px dotted #DDD;
	padding: 10px 0 10px 20px;
}

.acctitlec {
	cursor: auto;
}

.acctitle {
	&:first-child {
		border-top: none;
	}
	i {
		position: absolute;
		top: 0;
		left: 0;
		width: 14px;
		text-align: center;
		font-size: 14px;
		line-height: 44px;
		&.acc-open {
			display: none;
		}
	}
}

.acctitlec i {
	&.acc-closed {
		display: none;
	}
	&.acc-open {
		display: block;
	}
}

.acc_content {
	position: relative;
	padding: 0 0 15px 20px;
}

/* Accordion - with Title Background
-----------------------------------------------------------------*/

.accordion {
	&.accordion-bg {
		.acctitle, .acctitlec {
			background-color: #EEE;
			line-height: 44px;
			padding: 0 0 0 36px;
			margin-bottom: 5px;
			border-top: 0;
		}
		.acctitle i {
			left: 14px;
			line-height: 44px;
		}
		.acc_content {
			padding: 10px 0 15px 36px;
		}
	}
	&.accordion-border {
		border: 1px solid #DDD;
		border-radius: 4px;
		.acctitle, .acctitlec {
			border-color: #CCC;
			line-height: 44px;
			padding: 0 15px 0 36px;
		}
		.acctitle i {
			left: 14px;
			line-height: 44px;
		}
		.acc_content {
			padding: 0 15px 15px 36px;
		}
	}
	&.accordion-lg {
		.acctitle {
			font-size: 23px;
			line-height: 44px;
			padding-left: 30px;
			font-weight: 400;
			i {
				width: 20px;
				font-size: 20px;
				line-height: 64px;
			}
		}
		.acc_content {
			padding: 10px 0 30px 30px;
		}
	}
}

/* Accordion - Bordered
-----------------------------------------------------------------*/

/* Accordion - Large
-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Tabs
-----------------------------------------------------------------*/

.tabs {
	position: relative;
	margin: 0 0 30px 0;
}

ul.tab-nav {
	margin: 0;
	border-bottom: 1px solid #DDD;
	list-style: none;
	li {
		float: left;
		border: 1px solid #DDD;
		border-bottom: 0;
		border-left: 0;
		height: 41px;
		&:first-child {
			margin-left: 15px;
			border-left: 1px solid #DDD;
		}
		a {
			display: block;
			padding: 0 15px;
			color: #444;
			height: 40px;
			line-height: 40px;
			background-color: #F2F2F2;
			font-size: 14px;
			font-weight: bold;
		}
		&.ui-tabs-active a {
			position: relative;
			top: 1px;
			background-color: #FFF;
		}
	}
	&.tab-nav2 {
		border-bottom: 0;
		li {
			border: 0;
			margin-left: 10px;
			&:first-child {
				margin-left: 0;
				border-left: 0;
			}
			a {
				background-color: #F5F5F5;
				border-radius: 2px;
				box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
			}
			&.ui-state-active a {
				top: 0;
				background-color: #00deff;
				color: #FFF;
			}
		}
	}
}

/* Tabs - Navigation Style 2
-----------------------------------------------------------------*/

/* Tabs - Content Area
-----------------------------------------------------------------*/

.tab-container {
	position: relative;
	padding: 20px 0 0;
}

ul.tab-nav li a i {
	position: relative;
	top: 1px;
	font-size: 14px;
	margin-right: 3px;
}

/* Tabs - Bordered
-----------------------------------------------------------------*/

.tabs-bordered {
	ul.tab-nav li:first-child {
		margin-left: 0;
	}
	.tab-container {
		border: 1px solid #DDD;
		border-top: 0;
		padding: 20px;
	}
}

/* Tabs - Side Tabs
-----------------------------------------------------------------*/

.side-tabs {
	ul.tab-nav {
		float: left;
		width: 200px;
		padding: 20px 0;
		border-bottom: 0;
		border-right: 1px solid #DDD;
		li {
			float: none;
			border: 1px solid #DDD;
			border-right: 0;
			border-top: 0;
			height: auto;
			&:first-child {
				margin-left: 0;
				border-top: 1px solid #DDD;
				border-left: 1px solid #DDD;
			}
			a {
				height: auto;
				line-height: 44px;
			}
			&.ui-tabs-active a {
				top: 0;
				right: -1px;
			}
		}
	}
	.tab-container {
		overflow: hidden;
		padding: 15px 0 0 20px;
	}
	ul.tab-nav.tab-nav2 {
		border: 0;
		li {
			border: 0;
			margin: 6px 0 0;
			&:first-child {
				margin-top: 0;
			}
			a {
				box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
			}
			&.ui-state-active a {
				right: 0;
			}
		}
	}
}

/* Tabs - Side Tabs & Nav Style 2
-----------------------------------------------------------------*/

/* Tabs - Side Tabs & Bordered
-----------------------------------------------------------------*/

.tabs-bordered.side-tabs {
	ul.tab-nav {
		position: relative;
		padding: 0;
		z-index: 2;
		margin-right: -1px;
		li {
			a {
				height: auto;
				line-height: 44px;
			}
			&.ui-tabs-active a {
				right: -1px;
				border-right: 1px solid #FFF;
			}
		}
	}
	.tab-container {
		overflow: hidden;
		padding: 20px;
		border-top: 1px solid #DDD;
	}
}
