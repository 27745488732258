/* ----------------------------------------------------------------
	Overlays
-----------------------------------------------------------------*/

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.overlay-wrap {
		opacity: 0;
		position: relative;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}
}

.text-overlay {
	opacity: 0;
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
}

body:not(.device-touch) {
	.overlay .overlay-wrap, .text-overlay {
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
}

.overlay {
	.overlay-wrap i {
		position: absolute;
		opacity: 0.8;
		font-size: 24px;
		line-height: 1;
		width: 24px;
		height: 24px;
		text-align: center;
		top: 50%;
		margin-top: -12px;
		left: 50%;
		margin-left: -12px;
		color: #FFF;
	}
	&:hover {
		.overlay-wrap, .text-overlay {
			opacity: 1;
		}
	}
}

.text-overlay {
	position: absolute;
	opacity: 0.85;
	top: auto;
	bottom: 0;
	height: auto;
	padding: 40px 15px 15px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
	/* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.85)));
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
	/* IE6-9 */
}

.text-overlay-title {
	h2, h3, h4 {
		margin: 0;
		color: #F5F5F5;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 17px;
		font-weight: 600;
	}
	h2, h3, h4 {
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}

.text-overlay-meta {
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: #CCC;
	font-size: 13px;
	span i {
		position: relative;
		top: 1px;
		font-size: 14px;
	}
}
