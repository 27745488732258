/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/

/* Portfolio - Filter
-----------------------------------------------------------------*/

#portfolio-filter {
	position: relative;
	margin: 0 0 40px 0;
	list-style: none;
	border: 1px solid rgba(0, 0, 0, 0.07);
	float: left;
	border-radius: 4px;
	li {
		float: left;
		position: relative;
		a {
			display: block;
			position: relative;
			padding: 12px 18px;
			font-size: 13px;
			line-height: 15px;
			color: #666;
			border-left: 1px solid rgba(0, 0, 0, 0.07);
		}
		&:first-child a {
			border-left: none;
			border-radius: 4px 0 0 4px;
		}
		&:last-child a {
			border-radius: 0 4px 4px 0;
		}
		a:hover {
			color: #00deff;
		}
		&.activeFilter a {
			color: #FFF !important;
			background-color: #00deff;
			margin: -1px 0;
			padding: 13px 18px;
			font-weight: bold;
		}
	}
}

.bothsidebar #portfolio-filter li {
	a {
		padding: 12px 14px;
	}
	&.activeFilter a {
		padding: 13px 18px;
	}
}

/* Portfolio - Shuffle Icon
-----------------------------------------------------------------*/

#portfolio-shuffle {
	float: right;
	width: 41px;
	height: 41px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	font-size: 14px;
	text-align: center;
	line-height: 41px;
	color: #333;
	border-radius: 4px;
	cursor: pointer;
	-webkit-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear;
	&:hover {
		background-color: #00deff;
		color: #FFF;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
}

/* Portfolio - Items
-----------------------------------------------------------------*/

#portfolio {
	position: relative;
	margin: 0 -12px -12px 0;
}

body:not(.device-touch) #portfolio {
	-webkit-transition: height .4s linear;
	-o-transition: height .4s linear;
	transition: height .4s linear;
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-notitle .portfolio-image img {
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

/* Portfolio - Item Title
-----------------------------------------------------------------*/

.portfolio-desc {
	z-index: 3;
	padding: 15px 5px 10px;
	h3 {
		margin: 0;
		padding: 0;
		font-size: 19px;
		a {
			color: #222;
			&:hover {
				color: #00deff;
			}
		}
	}
	span {
		display: block;
		margin-top: 3px;
		color: #888;
		a {
			color: #888;
			&:hover {
				color: #000;
			}
		}
	}
}

.portfolio-notitle .portfolio-desc {
	position: absolute;
	display: block !important;
	width: 100%;
	height: 78px;
	padding: 15px 5px;
	top: auto;
	bottom: -79px;
	left: 0;
	background-color: #FFF;
	border-bottom: 1px solid #EEE;
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-notitle .portfolio-desc {
	-webkit-transition: bottom .2s ease-in-out;
	-o-transition: bottom .2s ease-in-out;
	transition: bottom .2s ease-in-out;
}

.portfolio-full.portfolio-notitle .portfolio-desc, .portfolio-nomargin.portfolio-notitle .portfolio-desc {
	bottom: -78px;
	border-bottom: 0;
}

body.device-touch {
	&.device-xs .portfolio-notitle .portfolio-desc, &.device-xxs .portfolio-notitle .portfolio-desc {
		display: none !important;
	}
}

/* Portfolio - No Margin
-----------------------------------------------------------------*/

#portfolio {
	&.portfolio-nomargin, &.portfolio-full {
		margin: 0 !important;
	}
}

.portfolio-nomargin {
	.portfolio-item {
		width: 284px;
		margin: 0 !important;
		.portfolio-image {
			width: 284px;
			height: 513px;
			a, img {
				width: 284px;
				height: 513px;
			}
		}
	}
	.portfolio-desc {
		padding: 15px 10px 15px;
	}
}

/* Portfolio - 100% Full Width
-----------------------------------------------------------------*/

#portfolio.portfolio-full {
	margin: 0 !important;
	width: 100.4%;
}

.portfolio-full {
	.portfolio-item {
		width: 25%;
		margin: 0 !important;
		overflow: hidden !important;
	}
	.portfolio-item-sizer {
		width: 25%;
	}
	.portfolio-item .portfolio-image {
		width: 100% !important;
		height: auto !important;
		> a, .slide a, img {
			width: 100% !important;
			height: auto !important;
		}
	}
	.portfolio-desc {
		padding: 15px;
	}
}

/* Portfolio - Sidebar
-----------------------------------------------------------------*/

.postcontent {
	#portfolio {
		margin: 0 -12px -12px 0;
	}
	.portfolio-item {
		width: 206px;
		margin: 0 12px 12px 0;
		.portfolio-image {
			width: 206px;
			height: 155px;
			a, img {
				width: 206px;
				height: 155px;
			}
		}
	}
	.portfolio-desc {
		h3 {
			font-size: 17px;
		}
		span {
			font-size: 12px;
		}
	}
	.portfolio-nomargin .portfolio-item {
		width: 214px;
		.portfolio-image {
			width: 214px;
			height: 161px;
			a, img {
				width: 214px;
				height: 161px;
			}
		}
	}
	.portfolio-notitle .portfolio-desc {
		height: 70px;
		bottom: -71px;
	}
	.portfolio-full.portfolio-notitle .portfolio-desc, .portfolio-nomargin.portfolio-notitle .portfolio-desc {
		bottom: -70px;
	}
	.portfolio-notitle .portfolio-item:hover .portfolio-overlay a {
		margin-top: -50px !important;
	}
}

/* Portfolio - Sidebar - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Sidebar - No Title
-----------------------------------------------------------------*/

/* Portfolio - Both Sidebars
-----------------------------------------------------------------*/

.bothsidebar {
	#portfolio {
		margin: 0;
	}
	.portfolio-item {
		width: 145px;
		margin: 0;
		.portfolio-image {
			width: 145px;
			height: 108px;
			a, img {
				width: 145px;
				height: 108px;
			}
		}
	}
	.portfolio-desc {
		display: none !important;
	}
}

/* Portfolio - Masonry
-----------------------------------------------------------------*/

.portfolio-masonry {
	.portfolio-item .portfolio-image {
		height: auto !important;
		> a, .slide a, img {
			height: auto !important;
		}
	}
	&.mixed-masonry .portfolio-item .portfolio-image {
		> a, .slide a, img {}
	}
}

/* Portfolio - Items - 3 Columns
-----------------------------------------------------------------*/

#portfolio.portfolio-3 {
	margin: 0 -15px -15px 0;
}

.portfolio-3 {
	.portfolio-item {
		width: 370px;
		margin: 0 15px 15px 0;
		.portfolio-image {
			width: 370px;
			height: 278px;
			a, img {
				width: 370px;
				height: 278px;
			}
		}
	}
	&.portfolio-nomargin .portfolio-item {
		width: 379px;
		.portfolio-image {
			width: 379px;
			height: 284px;
			a, img {
				width: 379px;
				height: 284px;
			}
		}
	}
}

/* Portfolio - Items - 3 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Sidebar - Items - 3 Columns
-----------------------------------------------------------------*/

.postcontent {
	#portfolio.portfolio-3 {
		margin: 0 -16px -16px 0;
	}
	.portfolio-3 {
		.portfolio-item {
			width: 276px;
			margin: 0 16px 16px 0;
			.portfolio-image {
				width: 276px;
				height: 207px;
				a, img {
					width: 276px;
					height: 207px;
				}
			}
		}
		.portfolio-desc {
			h3 {
				font-size: 18px;
			}
			span {
				font-size: 13px;
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 285px;
			.portfolio-image {
				width: 285px;
				height: 214px;
				a, img {
					width: 285px;
					height: 214px;
				}
			}
		}
		&.portfolio-notitle .portfolio-desc {
			height: 72px;
			bottom: -73px;
		}
		&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
			bottom: -72px;
		}
	}
}

/* Portfolio - Sidebar - Items - 3 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Sidebar - Items - 3 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Both Sidebars - Items - 3 Columns
-----------------------------------------------------------------*/

.bothsidebar {
	#portfolio.portfolio-3 {
		margin: 0 -11px -11px 0;
	}
	.portfolio-3 {
		.portfolio-item {
			width: 186px;
			margin: 0 11px 11px 0;
			.portfolio-image {
				width: 186px;
				height: 140px;
				a, img {
					width: 186px;
					height: 140px;
				}
			}
		}
		.portfolio-desc {
			display: block !important;
			h3 {
				font-size: 15px;
			}
			span {
				font-size: 12px;
			}
		}
		&.portfolio-nomargin .portfolio-item {
			width: 192px;
			.portfolio-image {
				width: 192px;
				height: 144px;
				a, img {
					width: 192px;
					height: 144px;
				}
			}
		}
		&.portfolio-notitle .portfolio-desc {
			height: 68px;
			bottom: -69px;
		}
		&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
			bottom: -68px;
		}
	}
}

/* Portfolio - Both Sidebars - Items - 3 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Both Sidebars - Items - 3 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Items - 2 Columns
-----------------------------------------------------------------*/

#portfolio.portfolio-2 {
	margin: 0 -20px -20px 0;
}

.portfolio-2 {
	.portfolio-item {
		width: 560px;
		margin: 0 20px 20px 0;
		.portfolio-image {
			width: 560px;
			height: 420px;
			a, img {
				width: 560px;
				height: 420px;
			}
		}
	}
	.portfolio-desc {
		padding: 20px 5px 10px;
		h3 {
			font-size: 21px;
		}
		span {
			margin-top: 4px;
			font-size: 14px;
		}
	}
	&.portfolio-nomargin {
		.portfolio-item {
			width: 569px;
			.portfolio-image {
				width: 569px;
				height: 427px;
				a, img {
					width: 569px;
					height: 427px;
				}
			}
		}
		.portfolio-desc {
			padding: 20px 15px 20px;
		}
	}
	&.portfolio-notitle .portfolio-desc {
		height: 90px;
		bottom: -91px;
	}
	&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
		bottom: -90px;
	}
}

/* Portfolio - Items - 2 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Items - 2 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Sidebar - Items - 2 Columns
-----------------------------------------------------------------*/

.postcontent {
	#portfolio.portfolio-2 {
		margin: 0 -20px -20px 0;
	}
	.portfolio-2 {
		.portfolio-item {
			width: 420px;
			margin: 0 20px 20px 0;
			.portfolio-image {
				width: 420px;
				height: 315px;
				a, img {
					width: 420px;
					height: 315px;
				}
			}
		}
		.portfolio-desc {
			h3 {
				font-size: 21px;
			}
			span {
				font-size: 14px;
			}
		}
		&.portfolio-nomargin {
			.portfolio-item {
				width: 429px;
				.portfolio-image {
					width: 429px;
					height: 322px;
					a, img {
						width: 429px;
						height: 322px;
					}
				}
			}
			.portfolio-desc {
				padding-bottom: 20px;
			}
		}
		&.portfolio-notitle .portfolio-desc {
			height: 90px;
			bottom: -91px;
		}
		&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
			bottom: -90px;
		}
	}
}

/* Portfolio - Sidebar - Items - 2 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Sidebar - Items - 2 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Both Sidebars - Items - 2 Columns
-----------------------------------------------------------------*/

.bothsidebar {
	#portfolio.portfolio-2 {
		margin: 0 -20px -20px 0;
	}
	.portfolio-2 {
		.portfolio-item {
			width: 280px;
			margin: 0 20px 20px 0;
			.portfolio-image {
				width: 280px;
				height: 210px;
				a, img {
					width: 280px;
					height: 210px;
				}
			}
		}
		.portfolio-desc {
			display: block !important;
			padding-top: 15px;
			padding-bottom: 0;
			h3 {
				font-size: 19px;
			}
			span {
				font-size: 13px;
			}
		}
		&.portfolio-nomargin {
			.portfolio-item {
				width: 289px;
				.portfolio-image {
					width: 289px;
					height: 217px;
					a, img {
						width: 289px;
						height: 217px;
					}
				}
			}
			.portfolio-desc {
				padding-bottom: 15px;
			}
		}
		&.portfolio-notitle .portfolio-desc {
			height: 75px;
			bottom: -76px;
		}
		&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
			bottom: -75px;
		}
	}
}

/* Portfolio - Both Sidebars - Items - 2 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Both Sidebars - Items - 2 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Items - 5 Columns
-----------------------------------------------------------------*/

#portfolio.portfolio-5 {
	margin: 0 -10px -10px 0;
}

.portfolio-5 {
	.portfolio-item {
		width: 220px;
		margin: 0 10px 10px 0;
		.portfolio-image {
			width: 220px;
			height: 165px;
			a, img {
				width: 220px;
				height: 165px;
			}
		}
	}
	.portfolio-desc h3 {
		font-size: 17px;
	}
	&.portfolio-nomargin .portfolio-item {
		width: 227px;
		.portfolio-image {
			width: 227px;
			height: 170px;
			a, img {
				width: 227px;
				height: 170px;
			}
		}
	}
	&.portfolio-notitle .portfolio-desc {
		height: 71px;
		bottom: -72px;
	}
	&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
		bottom: -71px;
	}
}

/* Portfolio - Items - 5 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Items - 5 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Sidebar - Items - 5 Columns
-----------------------------------------------------------------*/

.postcontent {
	#portfolio.portfolio-5 {
		margin: 0;
	}
	.portfolio-5 {
		.portfolio-item {
			width: 172px;
			margin: 0;
			.portfolio-image {
				width: 172px;
				height: 128px;
				a, img {
					width: 172px;
					height: 128px;
				}
			}
		}
		.portfolio-desc {
			h3 {
				font-size: 15px;
			}
			span {
				font-size: 12px;
			}
			padding: 15px 10px;
		}
		&.portfolio-notitle .portfolio-desc {
			height: 68px;
			bottom: -69px;
		}
		&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
			bottom: -68px;
		}
	}
}

/* Portfolio - Sidebar - Items - 5 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Items - 6 Columns
-----------------------------------------------------------------*/

#portfolio.portfolio-6 {
	margin: 0 -6px -6px 0;
}

.portfolio-6 {
	.portfolio-item {
		width: 185px;
		margin: 0 6px 6px 0;
		.portfolio-image {
			width: 185px;
			height: 139px;
			a, img {
				width: 185px;
				height: 139px;
			}
		}
	}
	.portfolio-desc {
		h3 {
			font-size: 15px;
		}
		span {
			font-size: 12px;
		}
	}
	&.portfolio-nomargin .portfolio-item {
		width: 189px;
		.portfolio-image {
			width: 189px;
			height: 142px;
			a, img {
				width: 189px;
				height: 142px;
			}
		}
	}
	&.portfolio-notitle .portfolio-desc {
		height: 68px;
		bottom: -69px;
	}
	&.portfolio-full.portfolio-notitle .portfolio-desc, &.portfolio-nomargin.portfolio-notitle .portfolio-desc {
		bottom: -68px;
	}
}

/* Portfolio - Items - 6 Columns - No Margin
-----------------------------------------------------------------*/

/* Portfolio - Items - 6 Columns - No Title
-----------------------------------------------------------------*/

/* Portfolio - Items - 1 Column
-----------------------------------------------------------------*/

#portfolio.portfolio-1 {
	margin: 0 0px -40px 0;
}

.portfolio-1 {
	.portfolio-item {
		float: none;
		width: 100%;
		margin: 0 0 40px 0;
		padding-bottom: 40px;
		border-bottom: 1px solid #EEE;
		.portfolio-image {
			float: left;
			margin-right: 40px;
		}
		&.alt .portfolio-image {
			float: right;
			margin: 0 0 0 40px;
		}
		.portfolio-image {
			display: block;
			width: 720px;
			height: 400px;
			a, img {
				display: block;
				width: 720px;
				height: 400px;
			}
		}
	}
	.portfolio-desc {
		float: left;
		width: 380px;
		padding: 10px 0;
	}
	.alt .portfolio-desc {
		float: right;
	}
	.portfolio-desc {
		h3 {
			font-size: 22px;
		}
		span {
			margin-top: 6px;
			font-size: 14px;
		}
		p {
			margin: 20px 0 25px;
			font-size: 14px;
		}
		li {
			margin: 3px 0;
		}
	}
}

/* Portfolio - Items - 1 Column - Sidebar
-----------------------------------------------------------------*/

.postcontent .portfolio-1 {
	.portfolio-item .portfolio-image {
		width: 540px;
		height: 300px;
		a, img {
			width: 540px;
			height: 300px;
		}
	}
	.portfolio-desc {
		width: 280px;
	}
}

/* Portfolio - Items - 1 Column - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar .portfolio-1 {
	.portfolio-item {
		.portfolio-image {
			margin-right: 20px;
		}
		&.alt .portfolio-image {
			margin: 0 0 0 20px;
		}
		.portfolio-image {
			width: 320px;
			height: 178px;
			a, img {
				width: 320px;
				height: 178px;
			}
		}
	}
	.portfolio-desc {
		display: block !important;
		width: 240px;
		h3 {
			font-size: 19px;
		}
		span {
			margin-top: 5px;
			font-size: 13px;
		}
		p {
			margin: 15px 0 0 0;
			font-size: 13px;
		}
		ul, .btn {
			display: none;
		}
	}
}

/* Portfolio - Items - 1 Column - Full Width
-----------------------------------------------------------------*/

#portfolio.portfolio-1.portfolio-fullwidth {
	margin: 0 0 -60px 0;
}

.portfolio-1.portfolio-fullwidth .portfolio-item {
	margin-bottom: 60px;
	padding-bottom: 0;
	border-bottom: 0;
	overflow: hidden;
	.portfolio-image {
		float: none;
		margin: 0 !important;
		width: 1140px;
		height: 500px;
		a, img {
			width: 1140px;
			height: 500px;
		}
	}
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.portfolio-1.portfolio-fullwidth .portfolio-desc {
	position: absolute;
	float: none;
	width: 380px;
	height: 100%;
	padding: 30px;
	background-color: #F9F9F9;
	top: 0;
	left: auto;
	right: -380px;
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-1.portfolio-fullwidth .portfolio-desc {
	-webkit-transition: right .3s ease-in-out;
	-o-transition: right .3s ease-in-out;
	transition: right .3s ease-in-out;
}

.portfolio-1.portfolio-fullwidth {
	.portfolio-item:hover .portfolio-desc {
		right: 0;
	}
	.alt .portfolio-desc {
		left: -380px;
		right: auto;
	}
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) .portfolio-1.portfolio-fullwidth .alt .portfolio-desc {
	-webkit-transition: left .3s ease-in-out;
	-o-transition: left .3s ease-in-out;
	transition: left .3s ease-in-out;
}

.portfolio-1.portfolio-fullwidth {
	.portfolio-item.alt:hover .portfolio-desc {
		left: 0;
	}
	.portfolio-overlay a {
		left: 33%;
	}
	.alt .portfolio-overlay a {
		left: auto;
		right: 33%;
	}
}

/* Portfolio - Items - 1 Column - Full Width - Sidebar
-----------------------------------------------------------------*/

.postcontent {
	#portfolio.portfolio-1.portfolio-fullwidth {
		margin: 0 0 -40px 0;
	}
	.portfolio-1.portfolio-fullwidth {
		.portfolio-item {
			margin-bottom: 40px;
			.portfolio-image {
				width: 860px;
				height: 377px;
				a, img {
					width: 860px;
					height: 377px;
				}
			}
		}
		.portfolio-desc {
			width: 320px;
			padding: 25px;
			right: -320px;
		}
		.alt .portfolio-desc {
			left: -320px;
			right: auto;
		}
		.portfolio-overlay a {
			left: 32%;
		}
		.alt .portfolio-overlay a {
			left: auto;
			right: 32%;
		}
	}
}

/* Portfolio - Items - 1 Column - Full Width - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar {
	#portfolio.portfolio-1.portfolio-fullwidth {
		margin: 0 0 -40px 0;
	}
	.portfolio-1.portfolio-fullwidth {
		.portfolio-item {
			margin-bottom: 40px;
			.portfolio-image {
				width: 580px;
				height: 254px;
				a, img {
					width: 580px;
					height: 254px;
				}
			}
		}
		.portfolio-desc {
			width: 240px;
			padding: 20px;
			right: -240px;
		}
		.alt .portfolio-desc {
			left: -240px;
			right: auto;
		}
		.portfolio-overlay a {
			left: 29%;
		}
		.alt .portfolio-overlay a {
			left: auto;
			right: 29%;
		}
	}
}

/* Portfolio - Parallax
-----------------------------------------------------------------*/

#portfolio.portfolio-parallax {
	margin: 0 !important;
	.portfolio-item {
		float: none;
		width: 100% !important;
		height: 500px !important;
		margin: 0 !important;
		.portfolio-image {
			width: 100% !important;
			height: 500px !important;
		}
	}
	.portfolio-desc {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -57px;
		width: 100%;
		text-align: center;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
		padding: 0;
		opacity: 0;
	}
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) #portfolio.portfolio-parallax .portfolio-desc {
	-webkit-transition: opacity .15s ease-in-out;
	-o-transition: opacity .15s ease-in-out;
	transition: opacity .15s ease-in-out;
}

#portfolio.portfolio-parallax {
	.portfolio-item:hover .portfolio-desc {
		opacity: 1;
	}
	.portfolio-desc {
		h3 {
			font-size: 44px;
			font-weight: 300;
			letter-spacing: -1px;
			a {
				color: #FFF;
				&:hover {
					color: #DDD;
				}
			}
		}
		span {
			margin-top: 12px;
			font-size: 16px;
			color: #CCC;
			a {
				color: #DDD;
				&:hover {
					color: #BBB;
				}
			}
		}
		.portfolio-divider {
			width: 100%;
			text-align: center;
			margin: 15px 0 0;
			div {
				display: inline-block;
				width: 50px;
				height: 0;
				border-bottom: 1px solid #FFF;
			}
		}
	}
}

body:not(.device-touch):not(.device-xs):not(.device-xxs) #portfolio.portfolio-parallax .portfolio-desc .portfolio-divider div {
	-webkit-transition: width .5s ease-in-out;
	-o-transition: width .5s ease-in-out;
	transition: width .5s ease-in-out;
}

#portfolio.portfolio-parallax {
	.portfolio-item:hover .portfolio-desc .portfolio-divider div {
		width: 120px;
	}
	.portfolio-overlay {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

/* Portfolio - Hover Effect
-----------------------------------------------------------------*/

/* Hello Apollo, I'm burning Star IV */

figure.effect-apollo {
	position: relative;
	float: left;
	// overflow: hidden;
	margin: 10px 1%;
	max-height: 400px;
	width: 48%;
	height: auto;
	background: $blackened_slate;
	text-align: center;
	cursor: pointer;

	@include media($desktop_xsm) {
		background: lighten($aqua_cream, 10%);
	}

	img {
		position: relative;
		opacity: 0.38 !important;

		@include media($desktop_xsm) {
			opacity: 1 !important;
			@include transform(scale3d(1.05, 1.05, 1));
			@include transition(all 0.7s ease);
		}
	}

	figcaption {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 2em;
		color: #222427;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;

		&::before, &::after {
			pointer-events: none;
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%);
			background: rgba(lighten($aqua_cream, 35%), 0.4);
			content: '';
			@include transition(transform 0.5s ease);
			@include transform(scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0));
		}

		> a, .portfolio-overlay > a, .thelightbox > a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		> a, .portfolio-overlay > a, .thelightbox > a {
			z-index: 20;
			text-indent: 200%;
			white-space: nowrap;
			height: 100% !important;
			width: 100% !important;
			font-size: 0;
			opacity: 0;
		}
	} // figcaption

	h2 {
		font-family: $manifold;
		color: $aqua_white;
		font-weight: 400;
		text-transform: uppercase;
		font-size: 24px;
		text-align: left;

		@include media($desktop_xsm) {
			color: $blackened_slate;
			opacity: 0;
			margin-top: 44px;
			@include transition(opacity 0.3s, margin 0.5s);
		}
	}

	.glitch {
		@include media($desktop_xsm) {
			opacity: 0;
			margin-top: 42px;
			@include transition(opacity 0.4s, margin 0.6s ease-out);
		}
	}

	p {
		position: absolute;
		right: 0;
		bottom: 0;
		margin: 0;
		padding: 4px 16px 4px 12px;
		max-width: 350px;
		background-color: $blackened_slate;
		text-align: right;
		opacity: 1;
		color: $aqua_cream;
		letter-spacing: 1px;

		@include media($desktop_xsm) {
			margin: 0 -230px 0 0;
			opacity: 0;
			background-color: $aqua_white;
			color: $blackened_slate;
			@include transition(all 0.5s $ease-in-out-quart);
		}

		span {
			color: $concrete_lt;

			@include media($desktop_xsm) {
				opacity: 0.2;
				color: $blackened_slate;
			}
		}
	}

	@include media($desktop_xsm) {
		&:hover {

			img {
				opacity: 0.15 !important;
				@include filter(grayscale(100%));
				@include transform(scale3d(1, 1, 1));
			}

			figcaption::before {
				@include transform(scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0));
			}

			h2 {
				opacity: 1;
				margin: 0 0 0 0 !important;
				@include transition-delay(0.1s);
			}

			.glitch {
				opacity: 1;
				margin: 8px 0 0 0 !important;
				@include transition-delay(0.3s);
			}

			p {
				opacity: 1;
				margin-right: 0;
				@include transition-delay(0.1s);
			}
		}
	}
}

/* Anchor will cover the whole item by default */

/* Portfolio - Overlay
-----------------------------------------------------------------*/

/* Portfolio Single
-----------------------------------------------------------------*/

.portfolio-single {}

/* Portfolio Single - Image
-----------------------------------------------------------------*/

.portfolio-single-image {
	> a, .slide a, img, iframe, video {
		display: block;
		width: 100%;
	}
}

.portfolio-single-image-full {
	position: relative;
	height: 600px;
	overflow: hidden;
	margin: -80px 0 80px !important;
}

.portfolio-single-video {
	height: auto !important;
}

/* Portfolio Single - Gallery Thumbs
-----------------------------------------------------------------*/

.portfolio-single-image-full {
	.swiper-container {
		width: 100%;
		height: 600px;
		background-color: #333;
	}
	.swiper-nested-1, .swiper-nested-2 {
		width: 100%;
	}
	.swiper-slide {
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
		}
	}
}

/* Portfolio & Blog Single - Masonry
-----------------------------------------------------------------*/

.masonry-thumbs {
	position: relative;
	a {
		position: relative;
		float: left;
		width: 25%;
		overflow: hidden;
	}
	&.col-2 a {
		width: 50%;
	}
	&.col-3 a {
		width: 33.30%;
	}
	&.col-4 a {
		width: 25%;
	}
	&.col-5 a {
		width: 20%;
	}
	&.col-6 a {
		width: 16.60%;
	}
	a {
		display: block;
		height: auto !important;
	}
	img {
		display: block;
		height: auto !important;
		width: 100%;
		border-radius: 0 !important;
		padding: 0 1px 1px 0;
	}
	.overlay {
		padding: 0 1px 1px 0;
	}
}

/* Portfolio Single - Content
-----------------------------------------------------------------*/

.portfolio-single-content {
	font-size: 14px;
	h2 {
		margin: 0 0 20px;
		padding: 0;
		font-size: 20px;
		font-weight: 600 !important;
	}
}

.portfolio-ajax-modal {
	width: 1000px !important;
}

.modal-padding {
	padding: 40px;
}

.ajax-modal-title {
	background-color: #F9F9F9;
	border-bottom: 1px solid #EEE;
	padding: 25px 40px;
	h2 {
		font-size: 26px;
		margin-bottom: 0;
	}
}

#portfolio-ajax-wrap {
	position: relative;
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height .4s ease;
	-o-transition: max-height .4s ease;
	transition: max-height .4s ease;
	&.portfolio-ajax-opened {
		max-height: 1200px;
	}
}

#portfolio-ajax-container {
	display: none;
	padding-bottom: 60px;
}

#portfolio-ajax-single h2 {
	font-size: 26px;
}

/* Portfolio Single - Meta
-----------------------------------------------------------------*/

.portfolio-meta, .portfolio-share {
	list-style: none;
	font-size: 14px;
}

.portfolio-meta li {
	margin: 10px 0;
	color: #666;
	&:first-child {
		margin-top: 0;
	}
	span {
		display: inline-block;
		width: 150px;
		font-weight: bold;
		color: #333;
		i {
			position: relative;
			top: 1px;
			width: 14px;
			text-align: center;
			margin-right: 7px;
		}
	}
}

.well .line {
	margin: 20px 0;
	border-color: #E5E5E5;
}

/* Portfolio Single Navigation
-----------------------------------------------------------------*/

#portfolio-navigation {
	position: absolute;
	top: 50%;
	left: auto;
	right: 10px;
	max-width: 96px;
	height: 24px;
	margin-top: -12px;
}

.page-title-right #portfolio-navigation {
	left: 10px;
	right: auto;
}

.page-title-center #portfolio-navigation {
	position: relative;
	top: 0;
	left: 0;
	margin: 20px auto 0;
}

#portfolio-navigation a {
	display: block;
	float: left;
	margin-left: 12px;
	width: 24px;
	height: 24px;
	text-align: center;
	color: #444;
	transition: color .3s linear;
	-webkit-transition: color .3s linear;
	-o-transition: color .3s linear;
}

.page-title-dark #portfolio-navigation a {
	color: #EEE;
}

.page-title-parallax #portfolio-navigation a {
	color: #FFF;
}

#portfolio-navigation a {
	i {
		position: relative;
		top: -1px;
		font-size: 24px;
		line-height: 1;
		&.icon-angle-left {
			font-size: 32px;
			top: -6px;
		}
		&.icon-angle-right {
			font-size: 32px;
			top: -6px;
			left: -1px;
		}
	}
	&:first-child {
		margin-left: 0;
	}
	&:hover {
		color: #00deff;
	}
}

#portfolio-ajax-show #portfolio-navigation {
	top: 0;
	margin-top: 0;
}

/* Individual Portfolio Item
-----------------------------------------------------------------*/

.iportfolio {
	position: relative;
	float: none;
	width: 100%;
	margin: 0;
	.portfolio-image {
		position: relative;
		overflow: hidden;
		display: block;
		width: 100%;
		height: auto;
		a, img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
